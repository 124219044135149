import React from "react"; //
import {
  //
  bearlog,
  BearDiv,
  BearList,
  BearTitle,
} from "@sillyscribe95/bear-ui-web";

export function BearHeroSplash({
  title,
  subtitle,
  right,
  leftConfig,
  mobileView,
  renderMobile,
  ...args
}) {
  const left = BearTitle({
    spaceBetween: "30px",
    title,
    subtitle,
    ...leftConfig,
  });

  const obRed = renderMobile && mobileView;
  let obj = obRed
    ? renderMobile()
    : BearList({
        horiz: !mobileView,
        bearName: "asdkoa",
        list: [left, right],
        spaceBetween: "30px",
        typeList: "return",
        ignoreEmpty: true,
      });

  const okfdsd = BearDiv({ obj, ...args });

  return okfdsd;
}
