import { BearSelect } from "@sillyscribe95/bear-ui-web";
import { useContext } from "react"; //
import { MainContext } from "../context/MainContext";

export function BearSpeechOptions({ lang, ...asdf }) {
  // 1const

  const { voiceCurr, setvoiceCurr } = useContext(MainContext);
  const voicesAll =
    //
    [];
  // voiceConst;

  const voicesOpt = voicesAll.filter(voiceEq);

  function voiceEq(cxv) {
    const jsdfwer =
      //
      cxv.lang.substring(0, 2);
    const equo = jsdfwer === lang;

    return equo;
  }

  function vbfasd(xcvbd) {
    setvoiceCurr(xcvbd);
  }

  const voiceArr = voicesOpt.map(dsfdfsg);

  function dsfdfsg(dsaf) {
    const { name } = dsaf;
    const baseLang = dsaf.lang.slice(-2).toUpperCase();

    const value =
      //
      dsaf;
    const label = `${name} (${baseLang})`;

    return {
      value,
      label,
    };
  }

  const aksfr = {
    voiceArr,
  };

  const ksdrew = voiceCurr && {
    value: dsfdfsg(voiceCurr),
  };

  const gdsdaeqw = {
    ...ksdrew,
    ...aksfr,
    options: voiceArr,
    onChangeValue: vbfasd,
    isSearchable: false,
    // potoins
    ...asdf,
  };
  const sdfweas = BearSelect(gdsdaeqw);
  return sdfweas;
}
