import {
  BearDiv,
  BearList,
  bearlog,
  BearTitle,
} from "@sillyscribe95/bear-ui-web";
import { includes } from "lodash";
import { useContext } from "react";
import { BearColors } from "../../components/bear/BearGlobals";
import { RomanConvert } from "../../components/RomanConvert";
import { mobView } from "../../const/mainConst";
import { TextWithSpeech } from "../TextWithSpeech";
import { PracticeContext } from "./PracticeContext";

export function PracticeSubmitMulti({ failTrue, ...config }) {
  const {
    currentPracticeItem,
    showTogether,
    answerTrue,
    practiceAnswer,
    setpracticeAnswer,
    onSubmitPractice,
  } = useContext(PracticeContext);

  //   1const
  const { chooseOptions, correctResult } = currentPracticeItem;

  bearlog.lug(`___ PracticeSubmtMulti aaa ___`, currentPracticeItem);

  function click(itemType) {
    //
    // const { itemType } = sdfg;

    onSubmitPractice(itemType);
  }

  const oasdwqe = mobView
    ? {
        margin: "0 0 30px",
      }
    : {
        // width: "30vw",
        margin: "0 0 20px",
      };

  // showTogether, setshowTogether
  function renderItem(aaaa) {
    //

    // const { transString, origString } = aaaa;

    // aaaa["transString"] = transString;
    // aaaa["origText"] = origString;

    bearlog.lug(`___ aaaa ___`, aaaa);

    // const bbb = `---zzzz-- `;
    // aaaa = bbb + aaaa + bbb;

    const kdfar =
      //
      baseRend(aaaa);
    // "asdwe";

    return kdfar;
  }

  //   { transText, origText, aaa }
  function baseRend({ transText, origText }) {
    //

    const item = transText;
    const arrShow = [...correctResult, practiceAnswer];
    const show = answerTrue && includes(arrShow, item);
    const itemIsCorrect = includes(correctResult, item);

    const sjrew = show && {
      background: itemIsCorrect ? "green" : "red",
      color: "white",
    };

    const fontSize =
      //
      "28px";
    // mobView ? "22px" : "30px"

    const itemConfig = {
      style: {
        ...oasdwqe,
        // padding: "20px",
        // width: mobView ? "60vw" : "210px",
        ...BearColors("black", "lightblue"),
        //   ...BearBorder("lightblue"),
        ...sjrew,
      },
      fontSize,
      className: "buttonHover",
    };

    const showBoth = answerTrue && !itemIsCorrect && showTogether;

    const finTExt =
      //
      // item
      // "Asdqweqw";
      showBoth ? `${transText} - ${origText}` : transText;

    const sizeo =
      showBoth &&
      {
        // fontSize: "0.6em",
      };

    const naewase = BearTitle({
      title: RomanConvert(finTExt),
      ...sizeo,
      //   subtitle: practiceAnswer && origText,
    });
    const obj =
      //
      naewase;

    const vndfe = BearDiv({
      onClick: () => click(item),
      obj,
      ...itemConfig,
    });

    const asdew = TextWithSpeech(vndfe, {
      text: item,
      swapItems: true,
    });

    const baseitme =
      //
      vndfe;
    //   asdew;

    return baseitme;
  }

  function submitBase() {
    const horzConfig = {
      horiz: true,
      displayNumber: 2,
    };

    const confo =
      //
      "";
    // belowConfig
    // horzConfig;

    const list =
      //
      chooseOptions;

    return BearList({
      bearName: "asokwqe",
      renderItem,
      // ignoreEmpty: true,
      list,
      ...confo,
    });
  }

  const jdsfwas = !chooseOptions ? "" : submitBase();

  return jdsfwas;
}
