import {
  BearDiv,
  BearFloat,
  BearLine,
  BearList,
} from "@sillyscribe95/bear-ui-web";
import React, { useContext, useState } from "react"; //
import { MdLoop, MdRecordVoiceOver, MdStopCircle } from "react-icons/md";
import { BearSpeechOptions } from "../components/BearSpeechOptions";
import { PracticeButton } from "../components/GlobalComps";
import { RomanButton } from "../components/RomanButton";
import { SpeedButton } from "../components/SpeedButton";
import { MainContext } from "../context/MainContext";
import { checkRoman } from "../functions/checkRoman";
import { genderTrue } from "../functions/genderFuncsFront";
import { searchAll } from "../search/searchConst";
import { SpeechContainer } from "./SpeechContainer";
import { getLangBase } from "./translate/getLangFuncs";

export function DashboardButton({
  //
  pageType = "study",
  // playList,
  playAll,
  list = ["Speed", "Space", "Convert"],
  dictvar,
  transid,
  practiceTrue,
  buttonConfig,
  ...xcvbd
}) {
  const {
    //
    fromLang,
    toLang,
    loopTrue,
    setloopTrue,
  } = useContext(MainContext);

  const basesa = searchAll[transid];
  const { noPractice } = basesa ? basesa : "";

  const baseargs = { transid, fromLang, toLang, ...xcvbd, basesa };
  // const

  const nsif = {
    fontSize: "24px",
    swapItems: true,
    // noText: true,
    // style: { minWidth: "100px" },
    // className: "buttonHover",
  };
  function Rnasdw(ttle, objo) {
    const rwezs = objo({
      //   textvar: ttle,
      ...nsif,
    });

    const nasfwro = BearFloat({
      center: ttle,
      right: rwezs,
    });

    return nasfwro;
  }
  const adqmwe = {
    fontSize: "24px",
  };

  const fewrw = {
    //
    className: "buttonHover",
    style: {
      width: "40px",
      //   background: "red",
    },
    textConfig: { align: "left" },
  };

  // 1dfsg
  function dfsg(dsfsd, dfwew) {
    // Playback
    return BearFloat({
      //   fontSize: "34px",
      left: getLangBase(dsfsd),
      right: BearDiv({ obj: dfwew, align: "center" }),
    });
  }

  const nasdre = checkRoman({ toLang, fromLang });

  // 1gender
  const genderBase = ["Verb"];
  const langHas = genderTrue({ toLang, fromLang });
  const sdfger = langHas && genderBase.includes(transid);
  const showGen =
    //
    "";
  // sdfger

  // 1pronoun
  const showPron = langHas && false;

  const [plaTrue, setplaTrue] = useState();

  // 1loop
  function lloppo() {
    return SpeechContainer(playAll, {
      // ...butActive(loopTrue),
      displayText: plaTrue ? <MdStopCircle /> : <MdLoop />,
      onPlay: () => setplaTrue(true),
      onStopPlay: () => setplaTrue(false),
      // text: playAll,
      className: "buttonHover expandHover",
      // onClick: () => setloopTrue(!loopTrue),
    });
  }

  // 1play

  // 1verb

  // 1text

  const voiceMany = BearSpeechOptions({
    lang: toLang,
    width: "20vw",
    menuHeight: "100px",
    // onChoose:
  });

  const voicComp = BearDiv({
    style: { marginBottom: "20px" },
    obj: dfsg(MdRecordVoiceOver(), voiceMany),
  });

  // 1bottom
  function Btotm() {
    const showPractice = !noPractice;

    const dewaeas =
      showPractice &&
      PracticeButton({
        pageType,
        ...baseargs,
      });

    const kadsf = BearFloat({
      bottom: dewaeas,
    });

    return kadsf;
  }

  const nsjrase = BearLine({
    margin: "20px 0",
  });

  dictvar = {
    Speed: SpeedButton(fewrw),
    Space: nsjrase,
    Loop: playAll && lloppo(),
    Convert: nasdre && RomanButton(fewrw),
    ...dictvar,
  };

  // dfsg("From", CountryShow({ lang: fromLang, type: "fro4m" })),
  // dfsg("To", CountryShow({ lang: toLang, type: "to" })),
  //

  // nsjrase,
  // //
  // showGen && dfsg("Gender", GenderChange(fewrw)),
  // showPron && dfsg("Pronoun", RomanButton(fewrw)),
  // kadsf,
  // 1practice

  const nsdrwe = list.map(kerwa);
  function kerwa(item) {
    const existo = dictvar[item];
    const sapceo = item === "Space";
    const trueo = sapceo ? existo : existo && dfsg(item, existo);
    return trueo;
  }

  const style = {
    width: "240px",
    // ...BearBorder("red"),
  };

  // 1right
  const xvcbier = BearList({
    // title: "Options",
    bearName: "asdew",
    noShowItems: true,
    // style,
    // 1width
    list: nsdrwe,
    // itemConfig: fewrw,
    typeList: "return",
    titleConfig: { style: { fontSize: "1em", marginBottom: "20px" } },
    // ...xcvbd,
  });

  const odaswe =
    //
    // Btotm();
    // "ASdfeo erw";
    Btotm();
  // practiceTrue && Btotm();

  const sfjre = (
    <>
      {/*  */}
      {xvcbier}
      {odaswe}
    </>
  );

  const wertwe =
    //
    sfjre;
  // DashWithSettings(sfjre);

  const okfdsd = BearDiv({
    align: "center",
    fontSize: "26px",
    obj: wertwe,
  });

  const ksfre =
    //
    okfdsd;
  // xvcbier;
  // "okfdsd";

  return ksfre;
}
//
