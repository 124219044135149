import {
  BearBackBorder,
  BearDiv,
  BearFloat,
  bearlog,
} from "@sillyscribe95/bear-ui-web";
import { compact } from "lodash";
import filter from "lodash.filter";
import { useState } from "react";

export function bearlogleg(base, ...asd) {
  if (base) {
    console.log("---loggo- ", ...asd);
  }
}

export function BearListRemove(list, previous) {
  previous = previous ? (Array.isArray ? previous : [previous]) : "";

  const final = !previous ? list : filter(list, asdfew);

  function asdfew(item) {
    const jiwer = previous.includes(item);

    return !jiwer;
  }

  return final;
}

export function BearSortAlpha(list) {
  // list = orderBy(sample, [user => user.name.toLowerCase()], ['asc']);
  // return;
}

export function getListDictArgs(dictvar) {
  const list = Object.keys(dictvar);
  return { list, dictvar };
}

export function BearTypes(item, { string, array }) {
  let adsj;

  switch (typeof item) {
    case "string":
      adsj = string && string(item);
      break;
    case "array":
      adsj = array && array(item);
      break;
    default:
  }

  return adsj;
}

export function BearSwitch(item, dddd, dict) {
  const funco = dict[item] ? dict[item] : dict["default"];
  return funco && funco(dddd);
}

export function BearEquals(item, other) {
  return item === other;
}

export function BearURLParams(param) {
  const url_string =
    // "http://www.example.com/t.html?a=1&b=3&c=m2-m3-m4-m5";
    window.location.href;

  const url = new URL(url_string);
  const { searchParams } = url;
  const res = param ? searchParams.get(param) : searchParams;

  return res;
}

export function BearColors(color, background, q32132 = "5px") {
  return {
    color,
    ...BearBackBorder(background, q32132),
  };
}

export function BearLocalStorage(id, none) {
  const storo = localStorage.getItem(id);
  const getInclude = ["false", "true"].includes(storo);
  const res = storo ? (getInclude ? JSON.parse(storo) : storo) : none;

  bearlog.lug(`___ BearLolStorage bbb ___`, { id, storo, res });

  return res;
}

// 1true 1pass
export function BearPass(item, none = "") {
  return item ? item : none;
}

// 1bool
export function BearBool(item, none) {
  return item ? item : none;
}

export function BearArrayRemoveEmpty(array) {
  const base = compact(array);
  return base;
}

export function BearObjectLength(dict) {
  bearlog.lug(`___ BearObtLength aaa ___`, dict);

  return !dict ? "" : Object.keys(dict).length;
}

export function BearCloseable(
  children,
  { orientation = "topRight", closeIcon } = ""
) {
  const [sethow, setsethow] = useState(true);

  const closeo = BearDiv({
    obj: closeIcon,
    onClick: () => setsethow(false),
  });

  const showit = !sethow
    ? ""
    : BearFloat({
        center: children,
        [orientation]: closeo,
      });

  return showit;
}
