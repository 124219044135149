export const voiceConst = [
  {
    voiceURI: "Daniel",
    name: "Daniel",
    lang: "en-GB",
    googlang: "en",
    localService: true,
    default: true,
  },
  {
    voiceURI: "Alex",
    name: "Alex",
    lang: "en-US",
    localService: true,
    default: false,
  },
  {
    voiceURI: "Alice",
    name: "Alice",
    lang: "it-IT",
    googlang: "it",
    localService: true,
    default: false,
  },
  {
    voiceURI: "Alva",
    name: "Alva",
    lang: "sv-SE",
    googlang: "sv",
    localService: true,
    default: false,
  },
  {
    voiceURI: "Amelie",
    name: "Amelie",
    lang: "fr-CA",
    localService: true,
    default: false,
  },
  {
    voiceURI: "Anna",
    name: "Anna",
    lang: "de-DE",
    googlang: "de",
    localService: true,
    default: false,
  },
  {
    voiceURI: "Carmit",
    name: "Carmit",
    lang: "he-IL",
    localService: true,
    default: false,
  },
  {
    voiceURI: "Damayanti",
    name: "Damayanti",
    lang: "id-ID",
    localService: true,
    default: false,
  },
  {
    voiceURI: "Diego",
    name: "Diego",
    lang: "es-AR",
    localService: true,
    default: false,
  },
  {
    voiceURI: "Ellen",
    name: "Ellen",
    lang: "nl-BE",
    localService: true,
    default: false,
  },
  {
    voiceURI: "Fiona",
    name: "Fiona",
    lang: "en",
    localService: true,
    default: false,
  },
  {
    voiceURI: "Fred",
    name: "Fred",
    lang: "en-US",
    localService: true,
    default: false,
  },
  {
    voiceURI: "Ioana",
    name: "Ioana",
    lang: "ro-RO",
    localService: true,
    default: false,
  },
  {
    voiceURI: "Joana",
    name: "Joana",
    lang: "pt-PT",
    localService: true,
    default: false,
  },
  {
    voiceURI: "Jorge",
    name: "Jorge",
    lang: "es-ES",
    localService: true,
    default: false,
  },
  {
    voiceURI: "Juan",
    name: "Juan",
    lang: "es-MX",
    localService: true,
    default: false,
  },
  {
    voiceURI: "Kanya",
    name: "Kanya",
    lang: "th-TH",
    localService: true,
    default: false,
  },
  {
    voiceURI: "Karen",
    name: "Karen",
    lang: "en-AU",
    localService: true,
    default: false,
  },
  {
    voiceURI: "Kyoko",
    name: "Kyoko",
    lang: "ja-JP",
    localService: true,
    default: false,
  },
  {
    voiceURI: "Laura",
    name: "Laura",
    lang: "sk-SK",
    localService: true,
    default: false,
  },
  {
    voiceURI: "Lekha",
    name: "Lekha",
    lang: "hi-IN",
    localService: true,
    default: false,
  },
  {
    voiceURI: "Luca",
    name: "Luca",
    lang: "it-IT",
    localService: true,
    default: false,
  },
  {
    voiceURI: "Luciana",
    name: "Luciana",
    lang: "pt-BR",
    localService: true,
    default: false,
  },
  {
    voiceURI: "Maged",
    name: "Maged",
    lang: "ar-SA",
    localService: true,
    default: false,
  },
  {
    voiceURI: "Mariska",
    name: "Mariska",
    lang: "hu-HU",
    localService: true,
    default: false,
  },
  {
    voiceURI: "Mei-Jia",
    name: "Mei-Jia",
    lang: "zh-TW",
    localService: true,
    default: false,
  },
  {
    voiceURI: "Melina",
    name: "Melina",
    lang: "el-GR",
    googlang: "el",
    localService: true,
    default: false,
  },
  {
    voiceURI: "Milena",
    name: "Milena",
    lang: "ru-RU",
    googlang: "ru",
    localService: true,
    default: false,
  },
  {
    voiceURI: "Moira",
    name: "Moira",
    lang: "en-IE",
    localService: true,
    default: false,
  },
  {
    voiceURI: "Monica",
    name: "Monica",
    lang: "es-ES",
    googlang: "es",
    localService: true,
    default: false,
  },
  {
    voiceURI: "Nora",
    name: "Nora",
    lang: "nb-NO",
    googlang: "no",
    localService: true,
    default: false,
  },
  {
    voiceURI: "Paulina",
    name: "Paulina",
    lang: "es-MX",
    localService: true,
    default: false,
  },
  {
    voiceURI: "Rishi",
    name: "Rishi",
    lang: "en-IN",
    localService: true,
    default: false,
  },
  {
    voiceURI: "Samantha",
    name: "Samantha",
    lang: "en-US",
    localService: true,
    default: false,
  },
  {
    voiceURI: "Sara",
    name: "Sara",
    lang: "da-DK",
    localService: true,
    default: false,
  },
  {
    voiceURI: "Satu",
    name: "Satu",
    lang: "fi-FI",
    localService: true,
    default: false,
  },
  {
    voiceURI: "Sin-ji",
    name: "Sin-ji",
    lang: "zh-HK",
    localService: true,
    default: false,
  },
  {
    voiceURI: "Tessa",
    name: "Tessa",
    lang: "en-ZA",
    localService: true,
    default: false,
  },
  {
    voiceURI: "Thomas",
    name: "Thomas",
    lang: "fr-FR",
    localService: true,
    default: false,
  },
  {
    voiceURI: "Ting-Ting",
    name: "Ting-Ting",
    lang: "zh-CN",
    localService: true,
    default: false,
  },
  {
    voiceURI: "Veena",
    name: "Veena",
    lang: "en-IN",
    localService: true,
    default: false,
  },
  {
    voiceURI: "Victoria",
    name: "Victoria",
    lang: "en-US",
    localService: true,
    default: false,
  },
  {
    voiceURI: "Xander",
    name: "Xander",
    lang: "nl-NL",
    localService: true,
    default: false,
  },
  {
    voiceURI: "Yelda",
    name: "Yelda",
    lang: "tr-TR",
    localService: true,
    default: false,
  },
  {
    voiceURI: "Yuna",
    name: "Yuna",
    lang: "ko-KR",
    localService: true,
    default: false,
  },
  {
    voiceURI: "Yuri",
    name: "Yuri",
    lang: "ru-RU",
    localService: true,
    default: false,
  },
  {
    voiceURI: "Zosia",
    name: "Zosia",
    lang: "pl-PL",
    localService: true,
    default: false,
  },
  {
    voiceURI: "Zuzana",
    name: "Zuzana",
    lang: "cs-CZ",
    localService: true,
    default: false,
  },
];

const baseVoice = [
  //
  "he",
];

export const allWebVoice = [
  "en",
  "it",
  "sv",
  "fr",
  "de",
  "id",
  "es",
  "nl",
  "ro",
  "pt",
  "th",
  "ja",
  "sk",
  "hi",
  "ar",
  "hu",
  "zh",
  "el",
  "ru",
  // "nb",
  "da",
  "fi",
  "tr",
  "ko",
  "pl",
  "cs",
];
