import { BearList, bearlog } from "@sillyscribe95/bear-ui-web";
import React from "react";
import { getMetaFront } from "../../functions/getMetaFront";
import { buildLink, buildLinkFull } from "../../functions/linkFuncs";
import { getTypesFront } from "../../functions/mainFuncs";
import { BearNetlifyFetch } from "../../components/bear/BearNetlifyFetch";

export function TestMetaList(aaaaa) {
  // 1const
  const {
    //
    match: { params },
    ...args
  } = aaaaa;

  const { level, toLang, fromLang } = params;

  function rendBase(transid) {
    // const { transid, level } = getTypesFront(params);
    const { lists } = getMetaFront(transid);

    const nameListo =
      //
      "translateapi";
    // "metalist";

    function renderItem(level) {
      //
      // /nouns/kitchen/en/zh-CN
      const baseo = buildLinkFull({ ...params, transid, level });
      return `http://localhost:8888/${nameListo}/${baseo}`;
    }

    const list = [
      //
      // "full",
      "100-most-common",
      ...lists,
    ];

    return BearList({
      list,
      bearName: "asdwqe",
      renderItem,
    });
  }

  const { data } = !level
    ? ""
    : BearNetlifyFetch("get-meta-list-api", { params });

  bearlog.lug(`___ data ___`, data);

  function verbMap(verb) {
    let iems =
      //
      `Verbs/${verb}/`;
    // `VERBAPI/${verb}/`

    iems = `http://localhost:8888/` + iems + buildLink(params);

    return <div>{iems}</div>;
  }

  const baseList = data
    ? data.map(verbMap)
    : [
        //
        "words",
        "verbs",
        "nouns",
        "adjectives",
      ].map(rendBase);

  const listo = baseList;

  const okfdsd = (
    <>
      {/* meta list */}
      {listo}
      {/*  */}
    </>
  );

  return okfdsd;
}
