import writtenNumber from "written-number";
import converter from "number-to-chinese-words";
import { range } from "lodash";
import { bearlog } from "@sillyscribe95/bear-ui-web";
export function numToWords(number, lang) {
  const origNum = number;
  switch (lang) {
    case "zh-CN":
      number = converter.toWords(number);
      break;
    default:
      number = writtenNumber(number, { lang });
  }

  bearlog.lug(`___ numToWords bbb ___`, { origNum, number, lang });

  return number;
}

export function getNumbAll(from, to, icr) {
  const toBase =
    //
    // to
    to + 1;

  return range(from, toBase, icr);
}
