import {
  BearDiv,
  BearFloat,
  bearlog,
  BearURLParam,
} from "@sillyscribe95/bear-ui-web";
import {
  useContext,
  //
  useState,
} from "react";
import { VerbConjugateList } from "../../components/VerbConjugateList";
import { PageTranslate } from "../PageTranslate";
import { VerbPageData } from "./verb-page-data";
import { VerbLists } from "../VerbList";
import { VerbTitle } from "../VerbTitle";
import { MainContext } from "../../context/MainContext";
import { useHistory } from "react-router-dom";
import { VerbPageInfo } from "./verb-page-info";

export function VerbPage(aaaaa) {
  // 1const
  const {
    //
    url,
    match: { params },
    ...args
  } = aaaaa;

  const { currConj, setcurrConj, currVerbArr } = useContext(MainContext);
  const conj = currConj;
  // = mobView && { marginBottom: "30px" };

  const paeasod =
    //
    // VerbLists({ conj, ...aaaa }),
    VerbPageData(params);

  const children =
    //
    BearDiv({
      obj: paeasod,
      style: {
        //
        //   ...BearBorder("red"),
        margin: "0 4%",
      },
    });
  // trueBase ? VerbLists(ijsfd) : ShowErba();

  // 1right 1verbs
  function convVERBS() {
    function awe({ itemName }) {
      //
      afwerwer(itemName);
    }

    function afwerwer(itemName) {
      setcurrConj(itemName);
    }

    return VerbConjugateList({
      fontSize: "26px",
      style: {
        margin: "0 10px",
      },
      chosenItem: conj,
      onArrowClick: afwerwer,
      click: awe,
    });
  }

  const { verb } = params;

  let titConfig = VerbTitle({
    genderTrue: true,
    verb,
  });

  // 1title
  const sdfoe =
    //
    "";
  // `${verb}: `
  const metaConfig = {
    // title: "dse",
  };

  const { list } = currVerbArr;
  const playAll =
    //
    list && list.map(({ transText }) => transText).join(", ");

  const infoBase = VerbPageInfo();

  let sdferw =
    //
    PageTranslate({
      playAll,
      hasData: true,
      metaConfig,
      url,
      titConfig,
      ...params,
      infoBase,
      children,
      topRightButtons: true,
      // practiceTrue: false,
      transid: "Verbs",
      titRight: convVERBS(),
    });

  return sdferw;
}
