import React, { useContext, useState } from "react"; //
import {
  //
  bearlog,
  BearDiv,
  BearFloat,
} from "@sillyscribe95/bear-ui-web";
import { MainContext } from "../../context/MainContext";
import { BearSpeechRecognition } from "../../components/bear/BearSpeechRecognition";
import { BearColors } from "../../components/bear/BearGlobals";
import { MdSettingsVoice } from "react-icons/md";
import { PracticeContext } from "./PracticeContext";

export function PracticeSubmitVoice() {
  const { toLang } = useContext(MainContext);
  const { practiceAnswer, onSubmitPractice } = useContext(PracticeContext);

  const [recordTrue, setrecordTrue] = useState();
  const language = toLang;

  function renderItem({ stop, interimTranscript, start }) {
    const transcript =
      //
      interimTranscript;

    function onClick() {
      if (recordTrue) {
        stop();
      } else {
        setrecordTrue(true);
        start({ language });
      }
    }

    const style = recordTrue && BearColors("white", "red");

    const fontSize = "200px";

    const center = MdSettingsVoice({ style });

    const button = BearFloat({
      fontSize,
      center,
    });

    const transBase = transcript;

    let res = (
      <>
        {button}
        {transBase}
      </>
    );
    res = BearDiv({
      obj: res,
      className: "pointer",
      onClick,
    });

    return res;
  }

  function onStop(text) {
    setrecordTrue();
    onSubmitPractice(text);
  }

  return BearSpeechRecognition({ onStop, renderItem });
}
