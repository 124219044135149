import { BearBorder, BearTitle } from "@sillyscribe95/bear-ui-web";
import React from "react"; //
import { getLangBase } from "../translate/getLangFuncs";
import { AuthBase } from "./AuthBase";

export function AuthWrapWithLower({
  onChangeCurrentType,
  currentType,
  ...ASDQ
}) {
  currentType = currentType ? currentType : "signup";

  let feasdfgre;
  switch (currentType) {
    case "signin":
      feasdfgre = {
        textvar: "Sign in",
        descr: "authRegDes",
        type: "signup",
        opp: "Sign up",
      };
      break;
    case "signup":
      feasdfgre = {
        textvar: "Sign Up",
        descr: "authSignDes",
        type: "signin",
        opp: "Sign in",
      };
      break;
    default:
  }

  let nasdfr = feasdfgre.title;
  // nasdfr = (
  //   <>
  //     {nasdfr}
  //     <hr />
  //   </>
  // );

  let xcvrwe = AuthBase({
    title: nasdfr,
    titleConfig: { style: { marginBottom: "50px" } },
    style: {
      minHeight: "30vh",
      minWidth: "35vw",
    },
    renderText: (dsf) => `${getLangBase(feasdfgre.textvar)} with ${dsf}`,
    ...ASDQ,
  });

  const dsfg = {
    //
    ...BearBorder("black", "5px"),
    // color: "white",
  };

  const adfer = BearTitle({
    title: getLangBase(feasdfgre.descr),
    subtitle: getLangBase(feasdfgre.opp),
    subtitleConfig: {
      style: {
        //
        fontSize: "30px",
      },
    },
    onClick: () => onChangeCurrentType(feasdfgre.type),
    // link: feasdfgre.link,
    fontSize: "20px",
    style: {
      padding: "20px",
      margin: "20px",
      // ...dsfg,
    },
    className: "shadowMain buttonHover",
  });

  xcvrwe = (
    <>
      {xcvrwe}
      {adfer}
    </>
  );

  return xcvrwe;
}
