import { bearlog } from "@sillyscribe95/bear-ui-web";
import { useContext } from "react"; //
import { RiGenderlessFill, RiGenderlessLine } from "react-icons/ri";
import { MainContext } from "../context/MainContext";
import { buildLinkVerb, verbLink } from "../functions/linkFuncs";
import { SpeechContainer } from "./SpeechContainer";

export function VerbTitle({ buttonConfig, linkTrue, verb, ...fdddd }) {
  const { showGender, setshowGender, currVerbArr, fromLang, toLang } =
    useContext(MainContext);

  bearlog.lug(`___ currVerbArr ___`, currVerbArr);

  const mainDato =
    //
    currVerbArr;
  // testVerbData;

  const { transTitle, origTitle } =
    //
    // "";
    mainDato;

  const allSpeechList =
    //
    // "";
    transTitle;
  // mainDato.list
  //   .map(({ transPlusGender }) => transPlusGender)
  //   .join(", ");

  const playText =
    //
    transTitle;
  // mainDato &&

  const practiceLink = buildLinkVerb({ fromLang, toLang, verb });

  const genderInclude =
    //
    "";
  const genderTrue =
    //
    genderInclude;

  // 1list
  const skmfr = genderTrue
    ? ["gender", "play"]
    : [
        //
        // "back",
        // "practice",
        // "info",
        // "play",
      ];

  const list =
    //
    skmfr;
  // ["play"];

  const dictvar = {
    gender: {
      onClick: () => setshowGender(!showGender),
      iconvar: showGender ? RiGenderlessFill() : RiGenderlessLine(),
    },
  };

  const speehBase = SpeechContainer({
    text: transTitle,
  });

  const displayConfig = {
    // textvar: "All",
  };

  const jsfdger = {
    actionConfig: {
      speechConfig: { displayConfig, text: allSpeechList },
      verb,
      playText,
      practiceLink,
      dictvar,
      list,
    },
    transText: transTitle,
    origText: origTitle,
    titleLink: linkTrue && verbLink({ fromLang, toLang, verb }),
    titleConfig: {
      // left: speehBase,
    },
    ...fdddd,
  };

  return jsfdger;
}
