import { bearlog } from "@sillyscribe95/bear-ui-web";
import { without } from "lodash";
import { range, spread, union } from "lodash";
import { bearlogleg } from "../../components/bear/BearGlobals";

export function BearMergeArrays(array) {
  const fn = spread(union);

  return fn(array);
}

export function getRangeMap(number, func, aaa = {}) {
  let { logTrue: here, existingArray = [] } = aaa;

  const logTrue =
    //
    // "asda";
    here;

  const array = range(number);
  const res = [];

  bearlogleg(logTrue, `___ getRangeMap ___`, { existingArray });

  while (res.length < number) {
    const exampl = func();
    // const baseo = existingArray;
    const there = existingArray.includes(exampl);
    const passtrhu =
      //
      !there;

    bearlogleg(logTrue, `___ getRangeMa ___`, {
      //
      exampl,
      existingArray,
      there,
      passtrhu,
    });

    if (passtrhu) {
      res.push(exampl);
      existingArray = [
        //
        exampl,
        ...existingArray,
      ];
    }

    if (res.length == number) {
      return res;
    }
  }

  return res;
}

// export function getArray({ existingArray }) {
//   while (result.length < 10) {
//     var idx = parseInt(Math.random() * 1000) % len;
//     if (arr[idx]) {
//       result.push(arr[idx]);
//       arr[idx] = undefined;
//     }
//   }
// }

export function BearArrayAddRemove(array, item) {
  const there = array.includes(item);
  const newArr = there ? without(array, item) : [item, ...array];

  return newArr;
}
