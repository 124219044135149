import React, { useContext } from "react"; //
import {
  //
  bearlog,
  BearDiv,
  BearBackBorder,
  BearTextMedia,
} from "@sillyscribe95/bear-ui-web";
import { MdTranslate } from "react-icons/md";
import { MainContext } from "../context/MainContext";
import { butActive, setStorage } from "../functions/mainFuncs";
import { checkRoman } from "../functions/checkRoman";

export function RomanButton({ textShow, style, ...args }) {
  // 1const
  const {
    fromLang,
    toLang,
    settoLang,
    romTrue,
    setromTrue,
    romLanguage,
    slowTrue,
    setslowTrue,
    showGender,
    setshowGender,
  } = useContext(MainContext);

  const sjidfwr = {
    // textvar: "Convert",
  };
  // setromTrue

  const asdfr = {
    ...butActive(romTrue),
    onClick: () => setStorage("romTrue", !romTrue, setromTrue),
  };

  const okfdsd = !checkRoman({ toLang, fromLang })
    ? ""
    : BearDiv({
        ...sjidfwr,
        obj: <MdTranslate />,
        ...args,
        // iconConfig:
        style,
        ...asdfr,
      });

  return okfdsd;
}
