import { BearTextMedia } from "@sillyscribe95/bear-ui-web";
import { useContext } from "react";
import { RiDeleteBinLine } from "react-icons/ri";
import { BearColors } from "../../components/bear/BearGlobals";
import { PracticeContext } from "./PracticeContext";

export function PracticeDeleteButton(string, configMain) {
  const { onDeletePractice } = useContext(PracticeContext);

  const okfdsd = BearTextMedia({
    iconvar: RiDeleteBinLine(),
    onClick: () => onDeletePractice(string),
    style: BearColors("white", "red"),
    className: "buttonHover expandHover",
    ...configMain,
  });

  return okfdsd;
}
