import { FixDash } from "../../components/GlobalComps";
import { MainSiteButtons } from "../../components/MainSiteButtons";

export function PracticeSideButtons(asdfew) {
  const dictvar = MainSiteButtons("", asdfew);
  const dashButConfig = {
    dictvar,
    list: [
      "Speed",
      "Speaker",
      // "playTrans",
      "Space",
      "Settings",
      "Space",
      "Convert",
    ],
  };

  const okfdsd = FixDash({
    ...dashButConfig,
    ...asdfew,
  });

  return okfdsd;
}
