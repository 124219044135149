import React, { useContext } from "react"; //
import {
  //
  bearlog,
  BearDiv,
} from "@sillyscribe95/bear-ui-web";
import { MainContext } from "../context/MainContext";

export function CurrencyBase(adfw) {
  const { fromLang } = useContext(MainContext);
  const divideBase = adfw / 100;
  const okfdsd =
    //
    // "";
    `$${divideBase}`;

  return okfdsd;
}
