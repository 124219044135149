import { BearFloat, bearlog, bearMobileView } from "@sillyscribe95/bear-ui-web";
import { OrigBlock } from "./GlobalComps";
import { RomanConvert } from "./RomanConvert";
import { VerbActions } from "./VerbActions";

export function TransBlock({
  transConfig = {},
  origConfig = {},
  speechConfig = {},
  showTranslateLink,
  showWiki,
  // leftWidth="",
  // rightWidth="",
  style,
  ...dfge
}) {
  bearlog.lug(`___ TransBlock ___`, dfge);

  const { transPlusGender, origText, origTrans, transText, ...asdf } = dfge;

  // 1const
  let ijweq =
    //
    "1px solid grey";

  const sfwas =
    //

    transText;

  // 1wiki

  // 1speech
  const sifwew = VerbActions({
    fontSize: "24px",
    playText: sfwas,
    showWiki,
    textBase: sfwas,
    speechConfig: { playAfterStop: true },
  });

  // 1right

  // 1orig

  // 1english 1original 1tense
  function EngRend(sdssss) {
    //

    return OrigBlock({
      fontSize: "0.8em",
      align: "center",
      // showTranslateLink,
      origTrans,
      origText,
      ...sdssss,
    });
  }

  // 1greek 1translate
  function GreekSide(vvvv) {
    // 1gender

    // 1roman
    let kaaew = RomanConvert(sfwas);

    return BearFloat({
      align: "center",
      center: kaaew,

      // right: sifwew,
      ...vvvv,
    });
  }

  // 1style
  const dwqjee = {
    padding: "10px",
    fontSize: "24px",
    marginTop: "10px 0",
    // borderBottom: "0.1px solid black",
    ...style,
  };

  // 1width
  const leftWid = bearMobileView ? "20vw" : "120px";
  const rightWid = bearMobileView ? "45vw" : "200px";

  // 1left
  const frqwerd = {
    style: {
      // ...BearBorder("blue", "5px"),
      width: leftWid,
    },
  };

  // 1right
  const fdgree = {
    style: {
      // padding: "0 50px",
      // borderLeft: ijweq,
      // ...BearBorder("red", "5px"),
      width: rightWid,
    },
  };

  const asdfer =
    //
    // origText;
    EngRend(origConfig);

  const ndsfer = origText
    ? {
        left: asdfer,
        center: GreekSide(transConfig),
      }
    : {
        center: GreekSide(transConfig),
      };

  // 1item
  const kvoe = {
    style: dwqjee,
    bearName: "aqwq",
    ...ndsfer,
    leftConfig: frqwerd,
    centerConfig: fdgree,
    right: sifwew,
    ...asdf,
  };

  const okdsa =
    //
    BearFloat(kvoe);

  return okdsa;
}
