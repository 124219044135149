import React, { useContext, useState } from "react"; //
import {
  //
  bearlog,
  BearDiv,
  BearSelect,
} from "@sillyscribe95/bear-ui-web";
import { LanguageName } from "./LanguageName";
import { getLangChoose } from "../functions/languageFuncs";
import { MainContext } from "../context/MainContext";
import { setStorage } from "../functions/mainFuncs";

export function CustomLanguageSelect(args) {
  const { onChange } = args;
  const { settoLang, toLang, fromLang, toName } = useContext(MainContext);
  const [vallos, setvallos] = useState(toName);

  function doneoo(value) {
    return BearDiv({
      obj: LanguageName(value, { showEmoji: true }),
      // align: "center",
      className: "pointer",
    });
  }

  const list = getLangChoose({ toLang, fromLang });
  const options =
    //
    // list
    list.map(doneas);

  const ondONDE = "***";

  function changeo(valeo) {
    const reso = valeo.split(ondONDE)[0];
    // const extra = [1];

    bearlog.lug(`___ reso ___`, { valeo, reso });
    onChange(reso);
  }

  function doneas(optios) {
    bearlog.lug(`___ optios ___`, optios);

    const ondONDE = "***";
    const nameos = LanguageName(optios);
    const value =
      //
      // nameos
      // optios;
      `${optios}${ondONDE}${nameos}`;

    const label =
      //
      doneoo(optios);
    // value;

    return {
      value,
      label,
    };
  }
  // function renderItem(code) {
  //   return LanguageName(code);
  // }

  const initialValue =
    //
    // toName;
    doneas(toLang);

  // setvallos;

  const opts = {
    options,
    // optionsArray,
    // renderItem,
    // initialValue,
    value: initialValue,
    ...args,
    onChange: changeo,
  };

  return BearSelect(opts);
}
