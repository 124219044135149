import { BearBackBorder, BearDiv, BearList } from "@sillyscribe95/bear-ui-web";
import axios from "axios";
import { compact, uniq, without } from "lodash";
import React, {
  //
  useState,
} from "react";
import { removeString } from "../../components/bear/BearGlobals";
import { BearListChunk } from "../../components/bear/BearListChunk";
import { BearSpeechAPI } from "../../components/bear/BearSpeechApi";
import { BearTextToSpeech } from "../../components/bear/BearTextToSpeech";
import { LanguageName } from "../../components/LanguageName";
import { testGreeKpresent } from "../../const/testConst";
import { voiceConst } from "../../const/voiceConst";
import { getLangChoose } from "../../functions/languageFuncs";
import { getAdjective } from "../adjectives/adjectiveFuncs";
import { PageContainer } from "../PageContainer";
import { Testdata } from "./Testdata";

export function TestPage(aaaaa) {
  // 1const

  const [stateSt, setstateSt] = useState();

  // stateSt,
  //   us;
  async function asqwE() {
    async function fas(zxcv) {
      // return await translateFull(zxcv, "en", "es");
    }

    const ewqae = ["here", "asde"];
    const okads =
      //
      // await translateFull("end", "en", "es");
      // await fas(ewqae[0]);
      await Promise.all(ewqae.map(async (bbb) => await fas(bbb)));

    // setstateSt(okads);
  }

  // 1basic
  async function asdfwe(asdfa) {
    const dsas =
      //
      "";
    // await translateBase("hello", "en", "gr");
  }

  // 1axios
  async function getto() {
    const results =
      //
      // {};
      // await fetchParams(, sidjfer)
      await axios.get("/.netlify/functions/stripeSignUp", {
        // params: asease,
      });

    const {
      data,
      // data: { client_secret },
      ...aewq
    } = results;
  }

  async function ssdfew(asdfa) {
    const oakdwq = {
      baseLanguage: "en",
      bearName: "Asqw",
      conj: ["Present", "Past", "Past participle", "Future"],
      fromLang: "en",
      toLang:
        //
        // "ch",
        // "ar",
        "el",
      // "es",
      // "gr",
      verb: "sleep",
    };

    const results =
      //
      "";
    // await subForm(oakdwq);

    setstateSt(results);
  }

  const ija = BearDiv({
    obj: "123qwqw",
  });

  function SAD() {}

  // 1chunk
  const iasjdqw = BearListChunk({
    bearName: "asdqwe",
    list: [1, 2, 3, 4, 5, 6, 7, 8],
    displayNumber: 3,
    horiz: true,
    typeList: "return",
    arrowCopyItemConfig: true,
    itemConfig: {
      className: "buttonHover",
      fontSize: "30px",
      align: "center",
      style: {
        minWidth: "10vw",
        color: "white",
        ...BearBackBorder("blue", "5px"),
      },
    },
    arrowOuterConfig: {
      // fontSize:
      // style: {
      //   width: "10vw",
      // },
    },
  });
  const jiqwes = testGreeKpresent["list"]
    .map((vvv) => vvv.transText)
    .join(
      //
      ", "
    );

  const speechSynthesis = window.speechSynthesis;
  // 1speech API
  const derwase = BearDiv({
    obj: "clickme please",
    onClick: function () {
      BearSpeechAPI({
        speechSynthesis,
        // voiceName: "Melina",
        langBase: "el",
        text: "γεια σας",
        // lang: "el",
      });
    },
  });

  // 1speech 1texttospeech

  const jiqwaw = BearTextToSpeech({
    text: jiqwes,
    displayText: "asdkqeq",
    lang: "el",
  });

  const asferw =
    "ένας , δύο , τρεις, τέσσερα, πέντε, έξι, επτά, οκτώ, εννέα, δέκα";

  // 1adjective
  const adjo = getAdjective({ lang: "el", text: "παλιός" });

  // 1remove
  const remoList = [
    //
    "hero ",
    // "hereo"
    "al",
  ];
  const text =
    //
    "";
  // removeString("hero along", remoList);

  // 1console

  const okfdsd = (
    <>
      {/*  */}
      {/* {ija} */}
      {/* {jiqwaw} */}
      {/* {iasjdqw} */}
      {/* {derwase} */}
      {/* {adjo} */}
      {/* {text} */}
      {/* {Testdata()} */}
      {/* {stateSt && VerbChunks(stateSt)} */}
    </>
  );

  return PageContainer({
    // style: BearBorder("red")
    children: okfdsd,
  });
}
