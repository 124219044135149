export function BearSpeechAPI({
  voice,
  voices,
  voiceName,
  //
  lang,
  langBase,
  //
  pitch = 1,
  rate = 1,
  volume = 1,
  speechSynthesis,
  onPlay,
  onStopPlay,
  onStop,
  onEnd,
  playAfterStop,
  loopTrue,
  text,
  ...sfwer
}) {
  // 1const
  var sayTimeout = null;
  voices = voices
    ? voices
    : //
      // voiceConst;
      speechSynthesis.getVoices();

  // 1voice
  function findVoice() {
    function asdfewr(sdfgs) {
      //////
      const { name, lang: langFull } = sdfgs;
      const sdifwr = voiceName
        ? (voiceName = name)
        : langBase
        ? baseLang()
        : sdfgs.lang === lang;

      function baseLang() {
        const baseo = langFull.substring(0, 2);
        const langFill = langBase.substring(0, 2);
        const eqo = baseo == langFill;

        return eqo;
      }

      // 1console VOICE

      return sdifwr;
    }

    return voices.find(asdfewr);
  }

  // 1end 1onend
  function endo() {
    const sdfwase =
      //
      loopTrue;
    // true;

    if (onEnd) {
      onEnd();
    }
    if (onStopPlay) {
      onStopPlay();
    }

    if (sdfwase) {
      setTimeout(function () {
        doSay();
      }, 2150);
    }
  }

  // 1stop
  function stopSay() {
    // SpeechSyn is currently speaking, cancel the current utterance(s)
    speechSynthesis.cancel();

    if (onStopPlay) {
      onStopPlay();
    }
    if (onStop) {
      onStop();
    }
  }

  function doSay() {
    // 1voice
    voice =
      //
      findVoice();
    // voice ? voice : findVoice();

    // lang = lang ? lang : voice.lang;

    const spechBase = {
      ...sfwer,
      voice,
      lang,
      pitch,
      rate,
      volume,
      onend: endo,
    };

    let utterThis = new SpeechSynthesisUtterance(text);
    utterThis = Object.assign(utterThis, spechBase);

    // 1console

    speechSynthesis.speak(utterThis);

    if (onPlay) {
      onPlay();
    }
  }

  if (speechSynthesis.speaking) {
    stopSay();

    if (playAfterStop) {
      if (sayTimeout !== null) clearTimeout(sayTimeout);

      sayTimeout = setTimeout(function () {
        doSay();
      }, 250);
    }
  } else {
    doSay();
  }
}
