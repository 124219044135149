import {
  BearDiv,
  BearFloat,
  BearList,
  bearlog,
  BearTitle,
} from "@sillyscribe95/bear-ui-web";
import React, {
  useContext,
  //
  useState,
} from "react";
import { BearColors, getListDictArgs } from "../../components/bear/BearGlobals";
import { DashMobButtons } from "../../components/dash-mob-buttons";
import { mobView } from "../../const/mainConst";
import { MainContext } from "../../context/MainContext";
import {
  chooseLangLink,
  linkPractice,
  linkPracticeChoose,
} from "../../functions/linkFuncs";
import { getSearchConst } from "../../search/searchConst";
import { PageContainer } from "../PageContainer";
import { getLangBase } from "../translate/getLangFuncs";
import { UserSignOut, userSignOut } from "../user/UserComponents";
import { DashLanguages } from "./DashLanguages";

export function DashboardPracticeLinks(params) {
  const { fontSize = "30px" } = params;
  const { userObj, toName, fromLang, toLang } = useContext(MainContext);

  const itemConfig = {
    style: { padding: "10px" },
  };

  function renderItem(basoe) {
    bearlog.lug(`___ basoe ___`, basoe);

    const { itemName, ...asd } = basoe;
    const transid = itemName;
    const link = linkPracticeChoose({ fromLang, toLang, transid });

    return BearDiv({
      link,
      fontSize,
      style: {
        padding: "0 20px",
        // width: "80vw",
      },
      className: "shadowMain buttonHover",
      obj: itemName,
      ...basoe,
    });
  }

  const base = getSearchConst(userObj);
  // const {list, dictvar } = base

  const pasmw = {
    transid: "words",
    level: "100-most-common",
    fromLang,
    toLang,
  };

  const link =
    //
    linkPractice(pasmw);

  const obj = "100 Basic Words";
  const subtitle = renderItem({
    link,
    obj,
    // style: BearColors("white", "darkblue"),
  });

  const titleConfig = {
    spaceBetween: 20,
    titleConfig: { bold: true },
    subtitle,
  };

  return DashMobButtons({
    titleConfig,
    // itemConfig,
    renderItem,
    fontSize,
    // displayNumber: 2,
    // fromName,
    toName,
    ...base,
    ...params,
  });
}
