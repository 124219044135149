import { bearlog } from "@sillyscribe95/bear-ui-web";
import CyrillicToTranslit from "cyrillic-to-translit-js";
// import greekUtils from "greek-utils";
import { toGreek, toPhoneticLatin } from "greek-utils";
import { transliterate } from "transliteration";

export function RomanBase(text, { fromLang, toLang, romTrue } = {}) {
  let romIt =
    //
    romTrue;
  // true;

  let textTrans;

  const cyrillicToTranslit = new CyrillicToTranslit();

  switch (fromLang) {
    case "el":
      textTrans = toGreek(text);
      break;
    default:
      switch (toLang) {
        // case "ar":

        case "el":
          textTrans = toPhoneticLatin(text);
          break;
        case "ru":
          textTrans = cyrillicToTranslit.transform(text);
          break;
        case "zh-CN":
        case "zh":
        case "ja":
        case "hi":
          // case "ar":
          textTrans = transliterate(text);
          break;
        default:
      }
  }

  const osjfdr = romIt && textTrans && !["undefined"].includes(textTrans);
  const fswrw = osjfdr ? textTrans : text;
  const naieqw = fswrw;

  bearlog.lug(`___ RomanBase ___`, naieqw);

  return naieqw;
}
