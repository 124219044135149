const lists = {
  //
};

const Nouns = {
  lists,
};

const el = {
  el: {
    Phrases: {
      lists: [
        //
        "Family",
        "Kids",
        "Learn",
      ],
    },
    Nouns: {
      "100-most-common": {
        streak: 20,
        removeList: ["cut"],
      },
    },
    Countries: {
      level: 3,
      streak: 10,
    },
    Verbs: {
      level: 3,
      streak: 10,
    },
    Numbers: {
      level: 3,
      streak: 10,
    },
    Names: {
      level: 3,
      streak: 10,
    },
    // Alphabet: {
    //   level: 3,
    //   streak: 10,
    // },
  },
};

export const testUse = {
  hasPlus: true,
  name: "Andrew Maguire",
  toLang: "el",
  fromLang: "en",
  langLearn: [
    //
    "es",
    "el",
    "zh-CN",
  ],
  languages: {
    el,
  },
};
