import { useContext } from "react";
import { MainContext } from "../context/MainContext";
import { RomanBase } from "./RomanConvertBase";

export function RomanConvert(text) {
  const { showGender, setshowGender, fromLang, toLang, romTrue } =
    useContext(MainContext);

  function genSplit(item) {
    const lengo = item.split(" ");
    const lengOver = lengo > 1;
    const inTrue =
      //
      // !showGender
      true;

    const genTrue = lengOver && inTrue;

    const basITEM = genTrue ? lengo.shift().join(" ") : item;

    const basei =
      //
      basITEM;

    return basei;
  }

  let naieqw = RomanBase(text, { fromLang, toLang, romTrue });

  // naieqw = naieqw ? genSplit(naieqw) : naieqw;

  return naieqw;
}
