export const greekTransConst = {
  Countries: "Οι Χώρες",
  Names: "Ονόματα",
  Numbers: "Αριθμοί",
  Alphabet: "Το Αλφάβητο",
  // 1dashboard
  Transliterate: "μεταγράφω",
  Gender: "Γένος",
  Pronoun: "Αντωνυμία",
  Speed: "Ταχύτητα",
  Loop: "Επαναλαμβάνω",
  Present: "Παρόν",
  Past: "Παρελθόν",
  Future: "Μέλλων",
};

export const greekVerbs = {
  //
};
