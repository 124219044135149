import { BearTextMedia } from "@sillyscribe95/bear-ui-web";
import { useContext } from "react";
import { MainContext } from "../context/MainContext";
import { SpeechContainer } from "./SpeechContainer";

export function TextWithSpeech(obj, { text, ...sdfds } = "") {
  text = text ? text : obj;
  const { toLang } = useContext(MainContext);

  const baseItem = BearTextMedia({
    textvar: text,
    iconvar: obj,
    swapItems: true,
    ...sdfds,
  });

  const okfdsd = SpeechContainer(text, {
    fontSize: "1.5em",
    displayConfig: { textvar: obj, ...sdfds },
  });

  return okfdsd;
}
