import React, { useContext } from "react"; //
import {
  //
  bearlog,
  BearDiv,
} from "@sillyscribe95/bear-ui-web";
import { MainContext } from "../../context/MainContext";
import { BearNetlifyFetch } from "../../components/bear/BearNetlifyFetch";
import { VerbLists } from "../VerbList";
import { VerbChunk } from "../../components/VerbChunk";
import { VerbPageMain } from "./verb-page-main";
import { useQuery } from "react-query";
import { axiosGet } from "../../functions/mainFuncs";

export function VerbPageData(args) {
  const { currConj, currVerbArr, setcurrVerbArr } = useContext(MainContext);

  const { verb } = args;
  const params = {
    ...args,
    transid:
      //
      "conjugations",
    // "conjugations-base",
    level: verb,
    // redoTrue: true,
  };
  bearlog.lug(`___ currVerbArr ___`, currVerbArr);

  function onFetch(data) {
    bearlog.lug(`___ onFetch Data ___`, data);
    setcurrVerbArr(data);
  }

  // useEffect(() => {
  //   const data= axiosGet("get-meta-dict-api", {params, onFetch});
  //   return () => {
  //     cleanup
  //   }
  // }, [])

  const link =
    //
    // "get-meta-dict-api";
    "get-conjugations-api";
  // "createConjugatedVerb"

  const bbbbb = BearNetlifyFetch(link, { params, onFetch });

  const { data: list, isLoading: loading } = bbbbb;

  if (loading) {
    return "";
  }

  const res =
    //
    // "asdq";
    VerbPageMain();
  // VerbChunk();
  // VerbLists();

  return res;
}
