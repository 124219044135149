import {
  BearDiv,
  BearFloat,
  BearList,
  bearlog,
} from "@sillyscribe95/bear-ui-web";
import { includes } from "lodash";
import React, {
  useContext,
  //
  useState,
} from "react";
import { BearURLParams } from "../../components/bear/BearGlobals";
import { OrigBlock } from "../../components/GlobalComps";
import { RomanConvert } from "../../components/RomanConvert";
import { mobView } from "../../const/mainConst";
import { MainContext } from "../../context/MainContext";
import { PracticeSubmitInput } from "./PracSubmitInput";
import { PracticeSubmitTop } from "./practice-submit-top";
import { PracticeSubmitVoice } from "./practice-submit-voice";
import { PracticeContext } from "./PracticeContext";
import { PracticeSubmitButtons } from "./PracticeSubmitButtons";
import { PracticeSubmitMulti } from "./PracticeSubmitMulti";
export function PracticeSubmit({ ...asd }) {
  let answerType =
    //
    // "voice";
    // "nothing";
    // "multi";
    BearURLParams("answerType");
  answerType = answerType ? answerType : "multi";

  // 1height
  const heghbase = {
    style: { minHeight: "40vh" },
  };

  // 1form
  const { correctResult, origText, resultString, origString } = asd;
  const { onProgressPractice, onSubmitPractice, practiceAnswer, answerTrue } =
    useContext(PracticeContext);

  // const { answerType } = useContext(PracticeContext);
  const [butChoose, setbutChoose] = useState();

  function formos() {
    // input
  }

  //   inputConfig

  // 1button
  //   1reveal
  function RevelBosa() {
    const rightTreu = includes(correctResult, practiceAnswer);
    const safij =
      //
      // "";
      answerTrue && practiceAnswer ? (rightTreu ? "✅" : " ❌") : "";

    let origText =
      //
      resultString ? resultString : origString;
    // origArray.join(" ");

    origText = RomanConvert(origText);
    const sadsfewr = OrigBlock({
      // showTranslateLink: true,
      origText,
    });

    const centbASE =
      //
      // "";
      RomanConvert(practiceAnswer);
    // "ASDFEWR";
    // userSubbo ? userSubbo : "N/A";

    const toppo = BearFloat({
      // left: safij,
      center: centbASE,
    });

    const aisdrwe =
      !rightTreu &&
      BearFloat({
        // fontSize: "0.8em",
        // left: safij,
        center: sadsfewr,
        // right: kqwewq,
      });

    let buttDo = PracticeSubmitButtons({
      style: {
        marginTop: "100px",
        // position: "absolute", bottom: "0"
      },
      list: ["Retry", "Continue"],
    });
    // buttDo = BearFloat({
    //   bottom: buttDo,
    // });

    // 1console

    let res = (
      //
      <>
        {toppo}
        {safij}
        {aisdrwe}
        {buttDo}
      </>
    );

    res = BearDiv({
      obj: res,
      // className: "buttonHover pointer",
      ...heghbase,
    });

    return res;
  }

  function rendFunc(obj) {
    let revealBase = RevelBosa();

    // revealBase = BearFloat({
    //   style: { height: "20vh" },
    //   bottom: revealBase,
    // });

    const rendIn = obj ? obj(asd) : "";

    const changeShow =
      //
      // practiceAnswer
      answerTrue;

    let res = changeShow ? revealBase : rendIn;

    const fontSize = "40px";

    res = BearDiv({
      obj: res,

      align: "center",
      ...heghbase,
      fontSize,
    });

    return res;
  }

  function clicko(xcvwe) {
    bearlog.lug(`___ xcvwe ___`, xcvwe);

    const obj = "";
    const onClick = onSubmitPractice;

    return BearDiv({
      ...heghbase,
      onClick,

      obj,
    });
  }

  const item = {
    voice: rendFunc(PracticeSubmitVoice),
    multi: PracticeSubmitMulti(asd),
    input: rendFunc(PracticeSubmitInput),
    nothing: rendFunc(clicko),
  }[answerType];

  let dasds = mobView && PracticeSubmitTop();
  dasds = BearDiv({ obj: dasds, style: { marginTop: "20px" } });

  return (
    <>
      {item}
      <hr />
      {dasds}
    </>
  );
}
