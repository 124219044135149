import { BearTextMedia } from "@sillyscribe95/bear-ui-web";
import EasySpeech from "easy-speech";
import { useContext } from "react";
import { FaRegPlayCircle, FaRegStopCircle } from "react-icons/fa";
import { BearTextToSpeech } from "../components/bear/BearTextToSpeech";
import { MainContext } from "../context/MainContext";

export function SpeechContainer(
  text,
  {
    //
    speed = 1,
    playTrue,
    voice,
    speechChange,
    displayConfig = {},
    ...sssss
  } = {}
) {
  const {
    //
    loopTrue,
    playSpeed,
    voiceCurr,
    toLang,
    setplayTrue,
  } = useContext(MainContext);

  // EasySpeech.detect();
  // EasySpeech.init();

  sssss = {
    voice,
    ...sssss,
  };

  function sgrrew() {
    // 1onclick
    async function onClick() {
      await EasySpeech.speak({
        text: "Hello, world!",
        // voice: "",
        lang: "el-GR",
        pitch: 1,
        rate: 1,
        volume: 1,
        // there are more events, see the API for supported events
        boundary: (e) => console.debug("boundary reached"),
      });
    }

    const isjfwe =
      //
      playTrue ? FaRegStopCircle() : FaRegPlayCircle();

    const sfwer = {
      rate: 1,
      // pitch: 1.2,
      // ...speedConfig,
    };

    // 1icon
    const ijfwe = {
      ...sfwer,
      displayText: BearTextMedia({
        iconvar: isjfwe,
        className: "pointer",
        // textvar: "asdew",
        // onClick,
        ...displayConfig,
      }),
      onPlay: () => setplayTrue(true),
      onStopPlay: () => setplayTrue(false),
      text,
      loopTrue,
      rate: playSpeed,
      langBase: toLang,
      ...sssss,
    };

    const okfdsd =
      //
      // "";
      BearTextToSpeech(ijfwe);

    return okfdsd;
  }

  return text ? sgrrew() : "";
}
