import { BearDiv, BearList } from "@sillyscribe95/bear-ui-web";
import React, { useContext } from "react";
import { RomanConvert } from "../../components/RomanConvert";
import { TransBlock } from "../../components/TransBlock";
import { MainContext } from "../../context/MainContext";
import { PageTranslate } from "../PageTranslate";
import { mainAlph } from "./alphaConst";

export function AlphaPage(aaaaa) {
  // 1const
  const {
    //
    match: { url, params },
    ...args
  } = aaaaa;

  const { fromLang, toLang } = useContext(MainContext);

  const adsfrewe =
    //
    mainAlph[toLang] ? mainAlph[toLang] : mainAlph["base"];

  // switch (typeof adsfrewe){
  //   case
  // }

  const dsfgrwe = Object.entries(adsfrewe).map(sdfewr);

  function sdfewr([key, adsfew]) {
    const sfgfds = {
      transText: adsfew,
      origText: key,
    };
    return sfgfds;
  }

  // 1sound 1play
  let fkerew =
    //
    "";
  // Object.values(adsfrewe).join(", ");

  //   1title
  const titConfig = {
    actionConfig: {
      // itemConfig
      // playText: fkerew,
      practiceLink: "alphabet",
    },
  };

  const listo = BearList({
    list: dsfgrwe,
    bearName: "ASdqe",
    lineBetween: true,
    renderItem: werts,
  });

  function werts({ origText, ...asdf }) {
    // const { origText } = asdf;

    const asodk =
      //
      "";
    // isNumber();

    asdf["origText"] = RomanConvert(origText);

    return BearDiv({
      //
      obj: TransBlock(asdf),
      // style: { margin: "5px 0" },
    });
  }

  const okfdsd = (
    <>
      {/*  */}
      {listo}
    </>
  );

  const sdif = {
    hasData: adsfrewe,
    url,
    titConfig,
    ...params,
    children: okfdsd,
    transid: "Alphabet",
    // titRight,
  };

  return PageTranslate(sdif);
}
