import { get } from "lodash";
import { useContext } from "react";
import { MainContext } from "../../context/MainContext";
import { engTransConst } from "./trans-english";
import { frenchTransConst } from "./trans-french";
import { greekTransConst } from "./trans-greek";
import { spanishTransConst } from "./trans-spanish";

export function StringLango(key, { logTrue, ...args } = "") {
  const { fromLang, toLang } = useContext(MainContext);

  const difwqq = {
    fr: frenchTransConst,
    el: greekTransConst,
    en: engTransConst,
    es: spanishTransConst,
  };

  let engText = get(difwqq, `en[${key}]`);

  let transText = get(difwqq, `${toLang}[${key}]`);
  transText = transText ? transText : toLang == "en" ? key : "";

  let origText = get(difwqq, `${fromLang}[${key}]`);
  origText = origText ? origText : engText ? engText : key;

  const typeBase = typeof origText;
  switch (typeBase) {
    case "function":
      origText = origText(args);
      break;
    default:
  }

  const nasdw = { origText, transText };

  if (logTrue) {
  }

  return nasdw;
}

export function getLangBase(...adasd) {
  const { origText, transText } = StringLango(...adasd);
  return origText;
}
