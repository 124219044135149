import { BearSelect } from "@sillyscribe95/bear-ui-web";

export function BearGoogleTranslateLanguageCodesSelect(aaaa) {
  const isto =
    //
    "";
  // Object.entries(googLangCodes).map(dfdzf);

  function dfdzf([key, val]) {
    return { value: key, label: val };
  }

  function sfawr9(csdf) {
    const { label } = csdf;

    return label;
  }

  const okfdsd =
    //
    // BearSearchList(
    BearSelect(
      // BearList(
      //
      {
        bearName: "nasd",
        // optionsDict: googLangCodes,
        // searchAttrs: ["value", "label"],
        // list: isto,
        renderItem: sfawr9,
        // typeList: "return",
        ...aaaa,
      }
    );

  return okfdsd;
}
