import React, { useContext } from "react"; //
import {
  //
  bearlog,
  BearDiv,
  BearUpper,
  BearTextMedia,
  BearSearchList,
} from "@sillyscribe95/bear-ui-web";
import { MainContext } from "../context/MainContext";
import { linkPractice } from "../functions/linkFuncs";
import { DashMobButtons } from "./dash-mob-buttons";
import { getLevelIcon } from "../functions/levelFuncs";

export function PracticeLevelButtons({
  transid,
  plusTrue,
  renderConfig,
  dictvar = {},
  ...params
}) {
  bearlog.lug(`___ PracticeLevButtons ___`, params);

  const { toName, bothLang } = useContext(MainContext);

  function renderItem(level) {
    bearlog.lug("___ asdfas ___", level);
    return renaoks(level);
  }

  function renaoks(level) {
    const extr = dictvar[level];
    const linkPar = { ...bothLang, transid, level, ...extr };
    const link = linkPractice(linkPar);

    bearlog.lug("___ rndLink ___", link);

    let iconvar = getLevelIcon(level);
    // iconvar = BearCheck(getLevelIcon(level), AiOutlinePl());

    const checkBase =
      //
      renderConfig && renderConfig(level);

    const textvar = BearUpper(level);

    return BearTextMedia({
      //   itemConfig,
      link,
      iconvar,
      className: "shadowMain buttonHover",
      textvar,
      ...checkBase,
      ...extr,
    });
  }

  function mobob() {
    return DashMobButtons({
      renderItem,
      toName,
      ...params,
    });
  }

  function searhco() {
    return BearSearchList({
      renderItem,
      toName,
      ...params,
    });
  }

  const asdfdssf =
    //
    mobob();
  // searhco();

  return asdfdssf;
}
