import {
  BearDiv,
  BearFloat,
  BearList,
  bearlog,
  BearTitle,
  BearUpper,
} from "@sillyscribe95/bear-ui-web";
import { get } from "lodash";
import { useContext } from "react";
import { mobView } from "../../const/mainConst";
import { BsShuffle } from "react-icons/bs";
import { BearColors } from "../../components/bear/BearGlobals";
import { DashMobButtons } from "../../components/dash-mob-buttons";
import { PlusMessage } from "../../components/PlusMessage";
import { MainContext } from "../../context/MainContext";
import { getMetaFront } from "../../functions/getMetaFront";
import { linkPractice } from "../../functions/linkFuncs";
import { butActive, getTypesFront } from "../../functions/mainFuncs";
import { PageContainer } from "../PageContainer";
import { getLangBase } from "../translate/getLangFuncs";
import { DashboardPracticeLevels } from "./dashboard-practice-levels";
import { useQueryParam, NumberParam, StringParam } from "use-query-params";
import { BaseTitle } from "../../components/BaseTitle";

export function DashboardPracticeChoosePage(aaaaa) {
  // 1const
  const {
    //
    match: { params, url },
    ...args
  } = aaaaa;

  const { userObj, userLoad, fromLang, toLang, fromName, toName } =
    useContext(MainContext);

  const [transid = "Words", settransid] = useQueryParam(
    "tDashMobButtonsype",
    StringParam
  );

  const chanoes = BearList({
    list: ["Words", "Verbs", "Adjectives"],
    chosenItem: transid,
    bearName: "Asdqwe",
    fontSize: "30px",
    itemConfig: { className: "buttonHover" },
    chosenConfig: { style: BearColors("black", "lightblue") },
    horiz: true,
    typeList: "return",
    // renderItem,
  });

  let children = DashboardPracticeLevels(params);
  children = BearDiv({
    children,
    style: !mobView && { padding: "0 10vw" },
  });

  const baseo = {
    transid,
    beforeText: "Practice",
    ...params,
    fromName,
    toName,
  };

  const metaTit =
    //
    // "Asdlqwe";
    BaseTitle(baseo);

  return PageContainer({
    align: "center",
    url,
    metaTit,
    children,
    waitUser: true,
    // closeRight: true,
    ...params,
  });
}
