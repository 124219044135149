import { bearlog, BearUpper } from "@sillyscribe95/bear-ui-web";
import { get } from "lodash";
import { useContext } from "react";
import { flagConst } from "../const/countryConst";
import { MainContext } from "../context/MainContext";
import { getLangOpp } from "../functions/languageFuncs";

export function LanguageName(code, { showEmoji } = "") {
  const { fromLang, langCodeDict } = useContext(MainContext);

  const base =
    //
    flagConst[code && getLangOpp(code).toUpperCase()];

  bearlog.lug(`___ base ___`, { code, base, flagConst, langCodeDict });

  const aadfewr = langCodeDict[code];
  const name = aadfewr ? aadfewr : "";
  const { emoji } = base ? base : "";

  const okfdsd =
    //
    // "";`
    // name;
    // `${name}`;
    showEmoji ? `${emoji} ${name}` : name;
  // get(langCodeDict, code);
  // getLangLanguage(fromLang)[code];

  return okfdsd;
}
