function plusBanner({ lang }) {
  const res =
    //
    `Hundreds of ${lang} lessons for the price of two.`;

  return res;
}

function plusSubtitle({ lang = "", cost }) {
  const res = `It takes about 20 hours of learning to learn the basics of a language ${
    lang ? `like ${lang}` : ""
  }. Verbalize helps you get there${cost ? ` faster for only ${cost}` : ""}.`;

  return res;
}

const plusLearn =
  //
  // "Learn Faster with Plus"
  "Get Plus";

const dashPlusUpgrade =
  "Upgrade to Plus to practice more lists and save your progress.";

export const engTransConst = {
  // Numbers:
  // Alphabet:
  //
  plusLearn,
  plusButton: "Get Plus",
  plusBanner,
  plusSubtitle,
  plusTranslit:
    "For languages with a different alphabet, you can change the text into Latin characters, with markings for emphasis.",
  plusTranslitTit: "Transliteration",
  plusPracticeTit: "Daily Practice",
  plusPractice:
    "Customised excercises that help build your memory. Choose from different skill level and earn a high score for the longest streak without an error.",
  plusUpgradeTitle: "What you get with Plus",
  plusWhyMake: "Why we're making Plus",
  plusExtTit: "Chrome Extension",
  plusExt:
    "Want to read foreign webpage in your in  in a different language? We",
  plusMoreCont: "Get access to more content with Plus.",
  plusPayTit: "Verbalize Plus",
  cardPay: "Lifetime Access",
  plusSpeechTit: "Speech Controls",
  plusSpeech:
    "Slow down or loop sections to have total control over how you listen. Verablise offers 48 different male & female voices across 30+ countries.",
  // plusPaySub:
  plusFeatureComing: "Coming Soon",
  plusFeatureLive: "Live",
  plusSaveTit: "Save",
  dashAccount: "Your Account",
  dashPlusUpgrade,
  plusSave:
    "If you're stuck with a section or phrase, save it for later and we'll send reminders for you to practice it.",
  plusPlaylist: "Create your own lists of words or phrases you need to learn.",
  joinLanguage: "Want your language to be added? Join us",
  plusAuthJoin: "Create an account to join Plus.",
  authRegDes: "Don't have an account?",
  //
  authSignDes: "Already have an account?",
  // upgra,
  chooseLangFromTit: "I speak...",
  wantLearn: "I want to learn...",
  // 1dashboard
  // 1translate 1roman
  Transliterate: "Convert",
  verboSearch: "Search 1000+ verbs...",
};
