import { removeEmptyDict } from "../functions/mainFuncs";
import { PageContainerMain } from "./page-container-main";
import { PageContainerData } from "./page-container-data";
import useKeypress from "react-use-keypress";
import { bearlog } from "@sillyscribe95/bear-ui-web";
import { useEffect } from "react";

export function PageContainer(asdfew) {
  asdfew = removeEmptyDict(asdfew);

  const adsfwe =
    //
    // "asdae";
    // PageContainerMain(asdfew);
    PageContainerData(asdfew);

  return adsfwe;
}
