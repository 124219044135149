import { BearDiv, BearList } from "@sillyscribe95/bear-ui-web";
import React from "react";
import { AiOutlinePlayCircle } from "react-icons/ai";
import { MdOutlineSpeed } from "react-icons/md";
import { BearSpeechAPI } from "./BearSpeechApi";

export function BearTextToSpeech({
  onChangeSpeed,
  displayText,
  iconConfig,
  speed = 1,
  speedConfig,
  ...aaaa
}) {
  const speechSynthesis = window.speechSynthesis;
  const voices = speechSynthesis.getVoices();

  //

  function clickSay() {
    const adfew = {
      ...aaaa,
      speed,
      speechSynthesis,
      voices,
    };

    BearSpeechAPI(adfew);
  }

  // 1speed
  const speedText =
    //
    speed;
  // speed.toString()

  const jarqwer = displayText
    ? BearDiv({
        obj: displayText,
        onClick: clickSay,
        ...aaaa,
      })
    : BearList({
        list: ["play"],
        dictvar: {
          play: {
            iconvar: <AiOutlinePlayCircle />,
            // textvar: "Play",
            noText: true,
            click: clickSay,
          },
          speed: {
            onClick: onChangeSpeed,
            iconvar: <MdOutlineSpeed />,
            textvar: speedText,
          },
        },
        horiz: true,
        typeList: "button",
        bearName: "asdoq",
        ...iconConfig,
      });

  return jarqwer;
}
