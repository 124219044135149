import { BearInputSubmit } from "@sillyscribe95/bear-ui-web";
import { useContext } from "react"; //
import { useHistory } from "react-router";
import { getLangBase } from "../containers/translate/getLangFuncs";
import { MainContext } from "../context/MainContext";
import { addHome, verbLink } from "../functions/linkFuncs";

export function VerbSearch({ ...bbbb }) {
  const { fromLang, toLang } = useContext(MainContext);
  let history = useHistory();

  function rsadda(adsfas) {
    return `to ${adsfas}`;
  }

  //   1submit 1function

  function sdfwe9(verb) {
    const moreDivs = verbLink({
      fromLang,
      toLang,
      verb,
    });

    window.location.href = addHome(moreDivs);
    // history.replace(moreDivs);
    // history.push(moreDivs);
  }

  //   1select

  // 1place
  let jsdewq =
    //
    getLangBase("verboSearch");

  const sadfer = {
    placeholder: jsdewq,
    ...bbbb,
  };

  //   1input
  const sjfrwqwe = BearInputSubmit({
    onSubmit: sdfwe9,
    autoComplete: "off",
    // buttonText: "Translate",
    // inputLeft: "aseqw",
    // style: { maxWidth: "400px" },
    ...sadfer,
    // fontSize: "40px",
  });

  const asdfjr =
    //
    sjfrwqwe;
  // SearchBar(sadfer);

  let iajsdqwe =
    //
    asdfjr;
  // sjfrwqwe;
  // s2r3ase

  return iajsdqwe;
}
