export const frenchTransConst = {
  Countries: "Pays",
  Names: "Noms",
  Numbers: "Nombres",
  Alphabet: "L'alphabet",
  Transliterate: "Translitérer",
  Phrases: "Phrases",
  Gender: "Sexe",
  Pronoun: "Pronom",
  Speed: "Vitesse",
  Loop: "Répéte",
  Present: "Présente",
  Past: "Passé",
  Future: "Future",
};

export const greekVerbs = {
  //
};
