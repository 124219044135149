import React, { useContext } from "react"; //
import {
  //
  bearlog,
  BearDiv,
} from "@sillyscribe95/bear-ui-web";
import { MainContext } from "../../context/MainContext";

export function VerbPageInfo() {
  const { currConj, toLang, currVerbArr } = useContext(MainContext);

  const okfdsd = (
    <>
      {/*  */}
      sssss
    </>
  );

  return okfdsd;
}
