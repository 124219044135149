import {
  BearDiv,
  BearFloat,
  bearlog,
  BearTextMedia,
} from "@sillyscribe95/bear-ui-web";
import React, { useContext, useEffect, useState } from "react";
import { MainContext } from "../../context/MainContext";
import { PlusMessage } from "../../components/PlusMessage";
import { PracticeItemTitle } from "./practice-item-title";
import { PracticeContext } from "./PracticeContext";
import { PracticeItem } from "./PracticeItem";
import { PracticeSideButtons } from "./PracticeSideButtons";
import { mobView } from "../../const/mainConst";
import { BearCloseable, BearColors } from "../../components/bear/BearGlobals";
import { BearAppStoreLink } from "../../components/bear/BearAppStoreLink";
import { AppStoreButt } from "../../components/GlobalComps";
import { isMobile } from "react-device-detect";
import { FaMobileAlt } from "react-icons/fa";

export function PracticeContainer(asdfew) {
  const { transid, url, dataList } = asdfew;
  const type = transid;
  const {
    userObj,
    userLoad,
    romTrue,
    playOpenTrans,
    playOpenOrig,
    toLang,
    fromLang,
  } = useContext(MainContext);

  const {
    //
    swapTrue,
    currentPracticeItem,
    speakTrans,
    // speakTrans,
  } = useContext(PracticeContext);

  // 1hover
  const [hvro, sethvro] = useState(true);

  // 1data
  const { verb, transString } = currentPracticeItem;

  bearlog.lug(`___ currentPracticeItem ___`, currentPracticeItem);

  const baseargs = {
    ...asdfew,
    ...currentPracticeItem,
    fromLang,
    toLang,
  };

  useEffect(() => {
    setTimeout(function () {
      // focusInput(inpref);
      speakTrans(transString);
    }, 1050);
  }, []);

  function aswq() {
    const sfdger = PracticeItem({
      ...baseargs,
      hoverTrue: hvro,
    });

    const sdkfgs = PracticeItemTitle(baseargs);

    return (
      <>
        {sdkfgs}
        {sfdger}
      </>
    );
  }

  // 1disable 1function 1main
  const dasdnwqe =
    //
    // "";
    aswq();

  const qsdfe = {
    fontSize: "1.5em",
    title: "",
    style: { margin: "2vw", ...BearColors("white", "darkblue") },
  };

  // 1app
  function Appggo() {
    let obj =
      //
      // "Save progress in App";
      // "Open in app"
      "Save Progress";

    obj = BearTextMedia({
      textvar: obj,
      iconvar: FaMobileAlt(),
    });

    return AppStoreButt({
      ...qsdfe,
      obj,
      className: "pointer",
    });
  }

  function showPl() {
    const plssuo =
      //
      Appggo();
    // PlusMessage(qsdf);

    // 1plus

    let flotBott = BearFloat({
      // center: wqwqeqw,
      bottom: plssuo,
    });

    // flotBott = BearCloseable(flotBott, {
    //   // closeIcon: "Adfweq",
    // });

    return flotBott;
  }

  const donePLs =
    //
    "";
  // isMobile;
  // !userLoad && mobView && !userObj?.hasPlus;

  const extr = (
    <>
      {dasdnwqe}
      {donePLs && showPl()}
    </>
  );

  const left = BearDiv({
    obj: extr,
    fontSize: "24px",
    style: { padding: "0 2%" },
  });

  const confo = {
    verb,
    pageType: "practice",
    left,
    ...asdfew,
  };

  const adsfwe =
    //
    // FixDash(confo);
    PracticeSideButtons(confo);

  return adsfwe;
}
