import React, { Children, useContext } from "react";
import { BearDiv } from "@sillyscribe95/bear-ui-web";
import { verbLink } from "../../functions/linkFuncs";
import { VerbPopularButtons } from "./VerbPopularButtons";
import { SearchPortal } from "./SearchPortal";
import { PageContainer } from "../PageContainer";
import { CountryShow } from "../../components/CountryShow";

export function VerbSearchPage(aaaaa) {
  // 1const
  let {
    match: { url, params },
  } = aaaaa;

  const verbWeek =
    //
    "analyze";

  // 1nav
  const ndwasae = {
    // list:
  };

  const nsew = {
    // match,
    // langfrom,
    // langto: langmain,
    // baseLanguage,
    ...params,
    url,
    headerConfig: {
      // left: "",
      navLeft: {
        list: ["about", "blog"],
      },
      // left: PageDropdown(),
      // center: "",
    },
    // footer: PageFooter(),
  };

  const oaske = SearchPortal(nsew);

  // 1choose 1verb
  function VerbFind() {
    return VerbPopularButtons({
      //
      ...params,
      listConfig: { displayNumber: 3 },
      collapseHeight: "120px",
      itemStyle: { minWidth: "100px", margin: "10px" },
    });
  }

  // 1verb 1week
  function WekVerb() {
    // const jftrew =

    const iasdfwe = {
      ...params,
      linkTrue: true,
      verb: verbWeek,
      titleConfig: { className: "buttonHover" },
    };

    const ijsfwr = verbLink({ verb: verbWeek, ...params });

    const cxvasd = {
      link: ijsfwr,
      fontSize: "1.3em",
      style: { margin: "0 20%", padding: "20px" },
      className: "shadowMain buttonHover",
    };

    const weekBase = BearDiv({
      obj: verbWeek,
    });

    const ijfwrw = (
      <>
        {BearDiv({ obj: "Verb of the week:" })}
        {weekBase}
        {/* {TitleComp(VerbTitle(iasdfwe))} */}
      </>
    );

    return BearDiv({
      obj: ijfwrw,
      ...cxvasd,
    });
  }

  const mvfdew = (
    <>
      {/*  */}
      {oaske}
      {WekVerb()}
      {/* {VerbFind()} */}
    </>
  );

  const children =
    //
    // "";
    // HomeFront()
    BearDiv({ obj: mvfdew, style: { marginTop: "-60px" } });

  // 1country
  function ConBase() {
    return CountryShow({ type: "to" });
  }

  const left =
    //
    ConBase();

  const headerConfig = {
    left,
    center: "",
    // right: "",
  };

  let asdeae = PageContainer({
    ...params,
    headerConfig,
    children,
    align: "center",
    fontSize: "24px",
  });

  return asdeae;
}
