import { BearDiv, BearList } from "@sillyscribe95/bear-ui-web";
import { useContext } from "react"; //
import { MdSearch } from "react-icons/md";
import { useHistory } from "react-router";
import { useLocation } from "react-router-dom";
import { MainSiteButtons } from "../components/MainSiteButtons";
import { MainContext } from "../context/MainContext";

export function PageTopButtons({ speedConfig, ...xxcz } = "") {
  const { fromLang, toLang } = useContext(MainContext);

  const qwaas = {};

  let history = useHistory();
  let { pathname } = useLocation();

  // const asease = BearSwitch(pathname {
  //   "/dashboard": {
  //    obj: MdClose(),
  //   }
  //   "/default": {
  //     obj: MdSearch(),
  //     link: ""
  //   }
  // })

  const nasase = [
    // SpeedButton({ ...qwaas, ...speedConfig }),
    // RomanButton(qwaas),
    BearDiv({
      obj: MdSearch(),
      onClick: sijear,
      ...qwaas,
    }),
  ];

  // 1search
  function sijear() {
    const asijdq =
      //
      // "/search";
      "/dashboard";
    // `/search/${buildLink({ fromLang, toLang })}`;

    history.push(asijdq);
  }

  const list =
    //
    // nasase
    [
      //
      "Roman",
      "Speaker",
      // "Settings",
    ];

  function renderItem(item) {
    // xxcz
    return MainSiteButtons(item, xxcz);
  }

  const okfdsd = BearList({
    bearName: "aokqw",
    horiz: true,
    renderItem,
    noShowItems: true,
    list,
    ...xxcz,
  });

  return okfdsd;
}
