import { bearlog, BearLower } from "@sillyscribe95/bear-ui-web";
import { useContext, useEffect } from "react";
import { BearNetlifyFetch } from "../components/bear/BearNetlifyFetch";
import { MainContext } from "../context/MainContext";
import { getUserlang } from "../functions/userFuncs";
import { getVoiceBase } from "../functions/voiceFuncs";
import { PageContainerMain } from "./page-container-main";

export function PageContainerData(ccc) {
  let {
    //
    onFetchLanguagesNames,
    transid,
    url,
    metaTit,
    metaConfig,
    headerConfig,

    closeRight,
    noChoose,
    userConfig,
    ...args
  } = ccc;

  let {
    userPaid,
    romTrue,
    setromTrue,
    setfromLang,
    settoLang,
    voiceCurr,
    setvoiceCurr,
    userObj,
    userLoad,
    countGo,
    settransid,
    setcountGo,
    fromName,
    toName,
    setfromName,
    settoName,
    langCodeDict,
    bothLang,
    setlangCodeDict,
  } = useContext(MainContext);

  ccc = {
    ...bothLang,
    ...ccc,
  };

  const { toLang, fromLang } = getUserlang(userObj, ccc);
  const langChoose = toLang;

  const params = { toLang, fromLang, cap: true };
  const { data, loading } = BearNetlifyFetch("getLanguageNames", {
    params,
    onFetch,
  });

  const baseargs = {
    ...ccc,
    toLang,
    fromLang,
  };

  function onFetch(data) {
    bearlog.lug(`___ data ___`, data);
    const { fromName, toName, langCodeDict } = data;

    settoName(toName);
    setfromName(fromName);
    setlangCodeDict(langCodeDict);

    if (onFetchLanguagesNames) {
      onFetchLanguagesNames(data);
    }
  }

  transid = transid && BearLower(transid);

  useEffect(() => {
    settransid(transid);
    settoLang(langChoose);
    setfromLang(fromLang);

    if (!voiceCurr) {
      setvoiceCurr(getVoiceBase(langChoose));
    }
    // return () => {
    //   cleanup
    // }
  }, [toLang]);

  function BaseRa() {
    const res = PageContainerMain(baseargs);
    return res;
  }

  // 1console
  // bearlog.lug(`___ zzasda ___`, zzasda);

  // 1children
  let oaske =
    //
    // "";
    // extrmain;
    BaseRa();
  // AllChoose();
  // countGo ? chosoe : aslBa;

  return oaske;
}
