import { BearBackBorder } from "@sillyscribe95/bear-ui-web";
import { AiFillPlusCircle } from "react-icons/ai";
import { BearColors } from "../components/bear/BearGlobals";
import { getLangBase } from "../containers/translate/getLangFuncs";
import { linkPlus } from "../functions/linkFuncs";

export function getPlusConst(res) {
  const akfrwe = {
    // link: linkAuth(),
    style: BearColors("white", "darkblue"),
  };

  const textvar =
    //
    getLangBase("plusLearn");

  const link =
    //
    linkPlus();

  const iconvar =
    //
    // ""
    AiFillPlusCircle();

  // 1plus
  return {
    textvar,
    iconvar,
    link,
    ...akfrwe,
    ...res,
  };
}
