import { bearlog, BearLower, BearURLParam } from "@sillyscribe95/bear-ui-web";
import { shuffle } from "lodash";
import React, { createContext, useState } from "react";
import { BearSpeechAPI } from "../../components/bear/BearSpeechApi";
import { axiosGet, getStorage, getTypesFront } from "../../functions/mainFuncs";
import { getPracticeData } from "./functions/get-practice-data";
import {
  getRandomPractice,
  mapParsePrac,
  mapPracParse,
  mapVerbPracParse,
  checkPracticeAnswer,
} from "./practiceFuncs";

export const PracticeContext = createContext();

const PracticeContextProvider = (props) => {
  //   1const
  const [practiceLevel, setpracticeLevel] = useState();

  // 1verbs
  // const [mapVerbs] = useQueryParams("type", ArrayParam);
  const [mapBas, setmapVerbs] = useState();
  const mapVerbs = mapBas;

  const [mapPronouns, setmapPronouns] = useState();

  // 1settingsTrue
  const [settingsTrue, setsettingsTrue] = useState();
  //
  // true

  // 1use
  const [userStreak, setuserStreak] = useState(0);

  // 1answer
  const [sessionCount, setsessionCount] = useState(0);
  const [showTogether, setshowTogether] = useState();

  const [previous, setprevious] = useState();
  const [practiceAnswer, setpracticeAnswer] = useState();
  const [answerTrue, setanswerTrue] = useState();
  const swappo = BearURLParam("swap");
  const swapTrue =
    //
    // ""
    // swappo;
    true;

  const [baeo, setmetaInfo] = useState("");

  const basefInfo = baeo;
  const metaInfo = {
    swapTrue,
    ...basefInfo,
  };
  const { adjMetaTo, toLang, fromLang } = metaInfo;

  const [dataList, setdataList] = useState();
  const [anon, setnewBase] = useState();
  const pracFetc = anon ? anon : dataList ? randConst(dataList) : "";
  const currentPracticeItem = pracFetc ? mapParsePrac(pracFetc, metaInfo) : "";

  bearlog.lug(`___ currentPracticeItem ___`, currentPracticeItem);

  const { transString, origString, correctResult } = currentPracticeItem;

  // 1gender
  const [genderType, setgenderType] = useState("male");
  const [pluralTreu, setpluralTreu] = useState();

  // 1console
  bearlog.lug(`___ pracContext ___`, { currentPracticeItem });

  // 1console
  const baseScript = {
    base: {
      //   hereo: hereo,
    },
    form: {
      //
    },
  };

  // 1functions

  // 1practice 1data
  async function loadPracticeData(data) {
    const { transid } = data;

    bearlog.lug(`___ loadPracticeData aaaa ___`, data);

    const adsf = await axiosGet("get-meta-dict-api", data, { onFetch });

    function onFetch(adjMetaTo) {
      bearlog.lug(`___ PracticeContainData onFetch ___`, adjMetaTo);

      const { level } = adjMetaTo;
      setpracticeLevel(level);

      data = {
        ...data,
        adjMetaTo,
      };

      setmetaInfo(data);
      onShufflePractice(data);
    }
  }

  // 1forget
  function onDeletePractice(string) {
    ifOneLeft(function () {
      delete dataList[string];
      delete adjMetaTo[string];
    });

    bearlog.lug(`___ dataList ___`, { string, dataList });

    getRand(dataList);
  }

  // 1focus
  function inpfocus() {
    // focusInput(inpref);
  }

  // 1progress
  function onProgressPractice(params) {
    setsessionCount(sessionCount + 1);
    ifOneLeft(function () {
      getRand(dataList);
    });
    setTimeout(() => {
      inpfocus();
    }, 200);
  }

  function ifOneLeft(func) {
    const lengtho = Object.keys(dataList).length;
    const oneLeft = lengtho == 1;

    if (oneLeft) {
      onShufflePractice();
    } else {
      func();
    }
  }

  const speechSynthesis = window.speechSynthesis;
  const voices = speechSynthesis.getVoices();

  // 1play 1open 1orig 1trans
  const [playOpenTrans, setplayOpenTrans] = useState(
    getStorage("playOpenTrans", true)
  );
  const [playOpenOrig, setplayOpenOrig] = useState();

  // 1speak

  function speeko(ifBase, data) {
    const goit = ifBase && speechSynthesis;

    bearlog.lug(`___ speko THERE ___`, goit);

    if (goit) {
      BearSpeechAPI({ voices, speechSynthesis, ...data });
    }
  }

  function speakTrans(string) {
    bearlog.lug(`___ spekTrans ___`, { currentPracticeItem, string });

    if (swapTrue) {
      // origSpBase(string);
    } else {
      transSpBase(string);
    }
  }

  // 1origspeak
  function speakOrig(string) {
    if (swapTrue) {
      transSpBase(string);
    } else {
      origSpBase(string);
    }
  }

  function transSpBase(string) {
    const base = playOpenTrans && toLang;
    speeko(base, {
      text: string,
      langBase: toLang,
      //   speechSynthesis,
    });
  }

  function origSpBase(string) {
    const base = playOpenOrig && fromLang;
    speeko(base, {
      text: string,
      langBase: fromLang,
      //   speechSynthesis,
    });
  }

  function doneItem(randLis) {
    let { origString, transString, chooseOptions } = randLis ? randLis : "";

    if (origString) {
      chooseOptions = shuffle(chooseOptions);
      randLis = {
        ...randLis,
        chooseOptions,
      };
      setprevious(origString);
      setnewBase(randLis);
      speakTrans(transString);
    }
  }

  function clearAnswer() {
    setpracticeAnswer();
    setanswerTrue();
  }

  function downData(lsto) {
    const { mainData } = lsto;

    if (mainData) {
      getRand(mainData);
    } else {
      doneItem(lsto);
    }
  }

  function randConst(data) {
    const randLis = getRandomPractice(data, previous);
    return randLis;
  }

  function getRand(data) {
    bearlog.lug(`___ getRand ___`, data);

    clearAnswer();
    const randLis = randConst(data);
    doneItem(randLis);
  }

  function getBaseInit(extra) {
    const dsfowr = {
      mapVerbs,
      mapPronouns,
      swapTrue,
      ...metaInfo,
      ...extra,
    };

    return getPracticeData(dsfowr);
  }

  // 1shuffle
  function onShufflePractice(extra) {
    clearAnswer();

    const data = getBaseInit(extra);
    const { mainData } = data;

    bearlog.lug(`___ mainData ___`, mainData);

    setdataList(mainData);
    downData(data);
  }

  // 1success
  function onSuccessPractice(message) {
    //
    // const aerwar
    const zxvaasd = userStreak + 1;
    setuserStreak(zxvaasd);
  }

  // 1fail
  function onFailurePractice() {
    setuserStreak(0);
  }

  // 1pronouns
  const [addPronouns, setaddPronouns] = useState();

  // 1submit
  function onSubmitPractice(resulto) {
    if (answerTrue) {
      onProgressPractice();
    } else {
      setanswerTrue(true);

      const { answer, correct } = checkPracticeAnswer(resulto, correctResult);

      if (correct) {
        onSuccessPractice();
      } else {
        onFailurePractice();
      }

      setpracticeAnswer(answer);
      setprevious(transString);
      speakOrig(origString);
    }
  }

  const okwe =
    //
    "base";

  const osdre = okwe ? baseScript[okwe] : baseScript;

  const xvokbcv = {
    // 1user
    userStreak,
    setuserStreak,
    //
    // 1answer
    sessionCount,
    setsessionCount,
    swapTrue,
    practiceAnswer,
    setpracticeAnswer,
    answerTrue,
    setanswerTrue,
    showTogether,
    setshowTogether,
    //
    // 1function
    onDeletePractice,
    onProgressPractice,
    onFailurePractice,
    onSuccessPractice,
    onSubmitPractice,
    onShufflePractice,
    loadPracticeData,
    //
    // 1data
    dataList,
    setdataList,
    metaInfo,
    setmetaInfo,
    adjMetaTo,
    currentPracticeItem,
    ...currentPracticeItem,
    //
    // 1verbs
    mapVerbs,
    setmapVerbs,
    // 1pronouns
    mapPronouns,
    setmapPronouns,
    //
    settingsTrue,
    setsettingsTrue,
    //
    // 1speak
    speakTrans,
    speakOrig,
    playOpenTrans,
    setplayOpenTrans,
    playOpenOrig,
    setplayOpenOrig,
    practiceLevel,
    setpracticeLevel,
    addPronouns,
    setaddPronouns,
  };

  let isfas = {
    value: xvokbcv,
  };

  // 1return 1end
  return (
    <PracticeContext.Provider {...isfas}>
      {props.children}
    </PracticeContext.Provider>
  );
};

export default PracticeContextProvider;
