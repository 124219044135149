import { bearlog, BearLower } from "@sillyscribe95/bear-ui-web";
import { BearSwitch } from "../../../components/bear/BearGlobals";
import { getMetaFront } from "../../../functions/getMetaFront";
import { practiceGetVerbsConjugated } from "../practice-get-verbs-conjugated";
import { practiceGetDefaultAPI } from "./practice-get-default-api";
import { practiceGetNumbers } from "./practice-get-numbers";

export function getPracticeData(dddd) {
  dddd = {
    optionNumber: 3,
    ...dddd,
  };

  return getPracArgs(dddd);
}

export function getPracArgs(dddd) {
  bearlog.lug("___ getPracData ___", dddd);

  let { transid, toLang, fromLang, level } = dddd;
  let baseo;

  const type = BearLower(transid);

  // const type = getType(dddd);

  bearlog.lug(`___ getPracrgs type ___`, type);

  switch (type) {
    case "conjugations":
    case "conjugations-list":
      bearlog.lug(`___ dddd ___`, dddd);
      const adjMetaTo = practiceGetVerbsConjugated(dddd);

      bearlog.lug(`___ adjMetaTo ___`, adjMetaTo);

      dddd["adjMetaTo"] = adjMetaTo;
      break;
    default:
  }

  switch (type) {
    case "numbers":
      baseo = practiceGetNumbers(dddd);
      break;

    default:
      baseo = BearSwitch(type, dddd, {
        // countries: countryPractice,
        // alphabet: alphabetPractice,
        default: defaultRet,
        // verbs: verbPractice,
        // names: (dddd) => alphabetPractice(dddd),
      });
  }

  function defaultRet() {
    const metaDict = getMetaFront(type);

    return practiceGetDefaultAPI({
      ...dddd,
      type,
      metaDict,
    });
  }

  bearlog.lug(`___ getPracticData bbb ___`, baseo);

  return baseo;
}
