import {
  BearDiv,
  BearFloat,
  BearList,
  BearTextMedia,
  BearTitle,
} from "@sillyscribe95/bear-ui-web";
import { useContext, useState } from "react"; //
import { BiExtension } from "react-icons/bi";
import { FaRegPlayCircle, FaSave, FaUniversity } from "react-icons/fa";
import { RiPlayList2Fill } from "react-icons/ri";
import { MainContext } from "../../context/MainContext";
import { getLangBase } from "../translate/getLangFuncs";

export function UpgradeBenefits({ title, ...ccccvv }) {
  const { fromLang, toLang } = useContext(MainContext);

  const [expando, setexpando] = useState();
  const ase = {
    //   imagevar: "",
    iconvar: FaRegPlayCircle(),
    textvar: getLangBase("plusTranslitTit"),
    subtitle: getLangBase("plusTranslit"),
    // langList:
  };

  // 1sound
  const sdferw = {
    // imagevar: ""
    iconvar: FaRegPlayCircle(),
    textvar: getLangBase("plusSpeechTit"),
    subtitle: getLangBase("plusSpeech"),
  };

  //   1practice
  const xcvdds = {
    iconvar: FaUniversity(),
    textvar: getLangBase("plusPracticeTit"),
    subtitle: getLangBase("plusPractice"),
  };

  //   1save
  const save = {
    iconvar: FaSave(),
    textvar: getLangBase("plusSaveTit"),
    subtitle: getLangBase("plusSave"),
    coming: true,
  };

  //   1playlist
  const playlist = {
    iconvar: RiPlayList2Fill(),
    textvar: getLangBase("Playlists"),
    subtitle: getLangBase("plusPlaylist"),
    coming: true,
  };

  const extension = {
    iconvar: BiExtension(),
    textvar: getLangBase("plusExtTit"),
    subtitle: getLangBase("plusExt"),
    coming: true,
  };

  const iajfer = {
    roman: ase,
    sound: sdferw,
    practice: xcvdds,
    save,
    playlist,
    extension,
  };

  const list = [
    //
    // checkRoman({ toLang, fromLang }) && "roman",
    "sound",
    "practice",
    "roman",
    "save",
    "playlist",
    "extension",
    // "",
  ];

  const adskferw = {
    style: {
      //
      // margin: "2%",
      margin: "0 2% 60px",
      // padding: "5px 20%",
    },
    // className: "shadowMain buttonHover",
  };

  function ajease({ coming, practice, langList, subtitle, itemName, ...asda }) {
    const { iconvar, textvar } = asda;

    const cxfdsf =
      //
      // "";
      // true;
      expando == itemName;

    let askdfr = BearTextMedia(asda);

    // 1release
    let releaseBase = coming
      ? {
          background: "darkblue",
          color: "white",
          obj: getLangBase("plusFeatureComing"),
        }
      : {
          obj: getLangBase("plusFeatureLive"),
        };

    const releo = BearDiv({
      className: "shadowMain",
      fontSize: "20px",
      ...releaseBase,
      style: {
        padding: "0 10px",
        ...releaseBase,
      },
    });

    askdfr = BearFloat({ left: askdfr, right: releo });

    const srsae = BearTitle({
      subtitle,
      subtitleConfig: { fontSize: "0.6em" },
      title: askdfr,
      lineBetween: true,
      spaceBetween: "15px",
    });

    return BearDiv({
      ...adskferw,
      // obj: cxfdsf ? srsae : sdafwer,
      obj: srsae,
      // onClick: () =>
      // (cxfdsf ? "" : srsae),
    });
  }

  const adsfer = BearTitle({
    title,
    fontSize: "22px",
    // lineBetween: true,
    bold: true,
    spaceBetween: "30px",
  });

  const okfdsd = BearList({
    ...ccccvv,
    title: adsfer,
    bearName: "asdf",
    dictvar: iajfer,
    list,
    ignoreEmpty: true,
    fontSize: "32px",
    itemConfig: {
      iconConfig: {
        // style: { width: "120px" },
        // fontSize: "1.2em",
      },
    },
    renderItem: ajease,
    // typeList: "button",
  });

  return okfdsd;
}
