import { bearlog } from "@sillyscribe95/bear-ui-web";
import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { axiosGet } from "../../functions/mainFuncs";

export function BearNetlifyFetch(
  name,
  { refetchData, changeParams, params, onFetch, extra }
) {
  const [dataBase, setdataBase] = useState();
  async function getData() {
    const data = await axiosGet(name, params);
    if (onFetch) {
      onFetch(data);

      if (!refetchData) {
        setdataBase(true);
      }
    }
    return data;
  }

  // useEffect(() => {

  //   const extr= getData()

  // }, [])

  const enabled = !dataBase;

  const oext = {
    enabled,
  };

  const bbbbb =
    //
    useQuery(name, getData, oext);
  // useQuery(name, getData, extra);
  // useQuery([name, ""], getData, extra);
  // useQuery([name, changeParams], getData, extra);

  bearlog.lug("___ BearNetlifyFetch ___", bbbbb);

  const { data } = bbbbb;
  bbbbb["data"] = data ? data : "";

  return bbbbb ? bbbbb : "";
}
