import { bearlog, BearLower } from "@sillyscribe95/bear-ui-web";
import { adjectivesMeta } from "../const/Adjectives/adjectives-meta";
import { numbersMeta } from "../const/meta-numbers";
import { conjugationsMeta } from "../const/conjugations-meta";
import { wordsMeta } from "../const/meta-words";
import { nounsMeta } from "../const/Nouns/nouns-meta";
import { prepostionMeta } from "../const/prepositions-meta";
import { verbsMeta } from "../const/Verbs/verbs-meta-front";

export function getMetaFront(type, level) {
  bearlog.lug(`___ type ___`, type);

  type = BearLower(type);

  const metaDict = {
    adjectives: adjectivesMeta,
    nouns: nounsMeta,
    verbs: verbsMeta,
    prepositions: prepostionMeta,
    numbers: numbersMeta,
    words: wordsMeta,
    conjugations: conjugationsMeta,
    // countries: ,
  }[type];

  return level ? metaDict.lists[level] : metaDict;
}
