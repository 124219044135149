import { genderRepsDict } from "../const/genderConst";
import { pronounDict } from "../const/pronounDict";
import { genderSplit, splitWithTail } from "./mainFuncs";
import { get } from "lodash";
import { bearlog } from "@sillyscribe95/bear-ui-web";

export function addGender(verb, gender, lang) {
  const genDict = get(genderRepsDict, `${lang}[${gender}]`);
  const realGen = genDict ? `${genDict} ` : "";

  const res =
    //
    `${realGen}${verb}`;
  //

  return res;
}
// 1english
export function getGenderBase(string, lang) {
  bearlog.lug(`___ getGendBase aaa ___`, { string, lang });

  const { gender, verb } = abstractGender(string);

  const res = addGender(verb, gender, lang);

  bearlog.lug(`___ getGendBase bbbbb ___`, {
    lang,
    gender,
    verb,
  });

  return res;
}

// 1french
export function getGendFrench(gendo, type, conjo) {
  gendo = pronounDict[type];

  gendo = gendo[conjo];

  return gendo;
}

export function genGendPlural(text, lngo, genType) {
  const jqasdw = text == "he";

  const diasw =
    //
    pronounDict[lngo];

  const typeSing = diasw && diasw[text];
  let dictvar = typeSing && diasw[typeSing];
  let current = dictvar && dictvar[genType];
  let list = dictvar && Object.keys(dictvar);

  const logBase = { text, genType, lngo, typeSing, dictvar, current, list };

  return { current, list, dictvar };
}

export function rendGen(enBit, lang, messo) {
  let { gender, verb } = getGender(enBit, lang, "last");
  const { current } = genGendPlural(gender, lang);

  const zxsas = current ? current : gender;
  const sdfwer = `${zxsas} ${verb}`;

  const sewase =
    gender ==
    //
    "he";

  return sdfwer;
}

export function genderTrue({ toLang, fromLang }) {
  const ksdmr = ["en", "fr", "es", "el", "de"].includes(toLang);

  return ksdmr;
}

export function abstractGender(string) {
  bearlog.lug(`___ abstractGeder aaa ___`, string);

  const split = splitWithTail(string, " ", 1);
  const gender = split[0];
  const verb = split[1];

  const res = { split, gender, verb };

  bearlog.lug(`___ abstractGeder bbbb ___`, res);

  return res;
}

export function getGender(vrbo, fromLang, typo = "") {
  let gender;
  let verb;

  const arrGoes = genderSplit(vrbo, fromLang, typo, `gtGender-${typo}`);

  switch (fromLang) {
    default:
      gender = arrGoes[0].split(" ")[0];
      // gender = arrGoes;
      verb = arrGoes[1];
  }

  const lgtru =
    //
    gender == "you all";
  if (lgtru) {
  }

  return { gender, verb };
}
