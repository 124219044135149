import {
  BearDiv,
  BearFloat,
  BearList,
  BearTitle,
} from "@sillyscribe95/bear-ui-web";
import React, {
  useContext,
  //
  useState,
} from "react";
import { getListDictArgs } from "../../components/bear/BearGlobals";
import { BearPrivateRoute } from "../../components/bear/BearPrivateRoute";
import { DashMobButtons } from "../../components/GlobalComps";
import { mobView } from "../../const/mainConst";
import { MainContext } from "../../context/MainContext";
import { getAllLevels } from "../../functions/levelFuncs";
import { chooseLangLink } from "../../functions/linkFuncs";
import { getSearchConst } from "../../search/searchConst";
import { PageContainer } from "../PageContainer";
import { getLangBase } from "../translate/getLangFuncs";
import { UserSignOut, userSignOut } from "../user/UserComponents";
import { DashboardContainer } from "./DashboardContainer";
import { DashLanguages } from "./DashLanguages";

export function DashboardPage(aaaaa) {
  // 1const
  const {
    //
    match: { url, params },
    ...args
  } = aaaaa;

  const { userObj, userLoad, toName, fromLang, toLang } =
    useContext(MainContext);

  const routeBase = {
    yesComponent: DashboardContainer,
    noRedirect: "/plus",
    userLoad,
    userObj,
    // userAttribute: "hasPlus",
  };

  const list = getAllLevels();

  const doneo =
    //
    // BearPrivateRoute(routeBase);
    DashboardContainer(aaaaa);

  return doneo;
}
