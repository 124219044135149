import {
  BearDiv,
  BearFloat,
  BearList,
  bearlog,
  BearTitle,
} from "@sillyscribe95/bear-ui-web";
import React, {
  useContext,
  //
  useState,
} from "react";
import { getListDictArgs } from "../../components/bear/BearGlobals";
import { DashMobButtons } from "../../components/dash-mob-buttons";
import { mobView } from "../../const/mainConst";
import { MainContext } from "../../context/MainContext";
import { chooseLangLink, linkPracticeChoose } from "../../functions/linkFuncs";
import { getSearchConst } from "../../search/searchConst";
import { PageContainer } from "../PageContainer";
import { getLangBase } from "../translate/getLangFuncs";
import { UserSignOut, userSignOut } from "../user/UserComponents";
import { DashboardPracticeLevels } from "./dashboard-practice-levels";
import { DashboardPracticeLinks } from "./dashboard-practice-links";
import { DashLanguages } from "./DashLanguages";
import { useQueryParam, NumberParam, StringParam } from "use-query-params";
import { getAllLevels } from "../../functions/levelFuncs";

export function DashboardContainer(aaaaa) {
  // 1const
  const {
    //
    match: { url, params },
    ...args
  } = aaaaa;

  //   const params = aaaaa;

  const [transid = "", settransid] = useQueryParam("type", StringParam);
  params["transid"] = transid;

  const { userObj, toName, fromLang, toLang } = useContext(MainContext);
  const [lang, setcurrFind] = useState(toLang);

  function showTiT() {
    const okfwew = BearDiv({
      obj: `Language: ${toName}`,
      link: chooseLangLink({ fromLang, toLang }),
      // style: {color:}
    });

    const LaNGlist = DashLanguages({
      chosenItem: lang,
    });
    const dfaa = BearFloat({
      center: LaNGlist,
      // bottom: "ASdfe",
    });

    const subtitle =
      //
      // okfwew;
      dfaa;
    // LaNGlist;
    // userObj.hasPlus ?
    // "ASdew";

    const tilo = BearFloat({
      left: getLangBase("dashAccount"),
      right: UserSignOut(),
    });

    let nvjdsf = BearTitle({
      title: tilo,
      spaceBetween: "10px",
      lineBetween: true,
      fontSize: "28px",
      subtitle,
    });

    return nvjdsf;
  }

  const asdfer = {
    lang,
    ...params,
  };

  function BaseLinks() {
    function click(dsfgs) {
      bearlog.lug(`___ dsfgs ___`, dsfgs);

      // settransid(dsfgs)
    }
    const adwe = {
      // click,
      ...params,
    };

    bearlog.lug(`___ adwe ___`, adwe);

    return DashboardPracticeLinks(adwe);
  }

  const naeaswe =
    //
    "";
  // doLevels()
  // "";
  // BaseLinks();
  // DashboardPracticeLevels(asdfer);

  // 1level
  function doLevels() {
    const list =
      //
      "";
    // getAllLevels();
    const asdwqe = { list, ...asdfer };
    return DashboardPracticeLevels(asdwqe);
  }

  const showLet =
    //
    // "anotehr bass";
    // showTiT();
    BaseLinks();
  // doLevels();

  const ASDW = BearList({
    horiz: !mobView,
    bearName: "asdlw,q",
    list: [
      //
      showLet,
      naeaswe,
    ],
    typeList: "return",
    ignoreEmpty: true,
  });

  const children = (
    <>
      {/* {nvjdsf} */}
      {ASDW}
    </>
  );

  const sdif = {
    url,
    metaTit: "Dashboard",
    ...params,
    // closeRight: true,
    topRightButtons: false,
    children,
  };

  return PageContainer(sdif);
}
