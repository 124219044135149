const nounsEnglishMeta = [
  "family",
  "transport",
  "face",
  "Human Body",
  "vegetables",
  "fruits",
  "kitchen",
  "school",
  "furniture",
  "clothes",
  "money",
];

export const nounsMeta = {
  showWikitionary: true,
  lists: nounsEnglishMeta,
};
