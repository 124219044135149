import { BearDiv } from "@sillyscribe95/bear-ui-web";
import React, { useContext } from "react"; //
import { getListDictArgs } from "../../components/bear/BearGlobals";
import { TopicList } from "../../components/GlobalComps";
import { MainContext } from "../../context/MainContext";
import { linkPractice } from "../../functions/linkFuncs";
import { searchAll } from "../../search/searchConst";
import { PageContainer } from "../PageContainer";

export function ListPage(aaaaa) {
  const {
    //
    match: { url, params },
    ...args
  } = aaaaa;

  const { fromLang, toLang } = useContext(MainContext);

  function renderItem(sdgrew) {
    const { itemName } = sdgrew;
    const sdfkg = { transid: itemName, fromLang, toLang };
    const link =
      //
      //   itemName;
      linkPractice(sdfkg);
    //   `/practice/${itemName}/l1/${fromLang}/ `;

    const asdfrerw =
      //
      BearDiv({
        link,
        obj: itemName,
        ...sdgrew,
      });

    return asdfrerw;
  }

  const fontSize =
    //
    "32px";

  const sadfwer = TopicList({
    // red,
    itemConfig: {
      className: "buttonHover",
    },
    align: "center",
    fontSize,
    renderItem,
    ...getListDictArgs(searchAll),
  });

  const okfdsd = (
    <>
      {/*  */}
      {sadfwer}
    </>
  );

  return PageContainer({
    url,
    ...params,
    children: okfdsd,
  });
}
