import {
  BearAuthPortal,
  BearAuthPortalWithEmailForm,
} from "@sillyscribe95/bear-ui-web";
import firebase from "firebase/compat/app";
import {
  useContext,
  //
  useState,
} from "react";
import { AiOutlineMail } from "react-icons/ai";
import { useHistory } from "react-router-dom";
import { MainContext } from "../../context/MainContext";

export function AuthBase({
  //
  onFailure,
  emailFormConfig,
  onSuccess,
  ...aaaa
}) {
  // 1const

  const { userObj, setUserObj } = useContext(MainContext);

  const [xvcbsa, setemClicko] = useState();
  const emClicko =
    //
    xvcbsa;
  // "e1";

  let history = useHistory();

  async function ASDJA(asdf) {
    const deeets =
      //
      "";
    // await axios.get("/.netlify/functions/authSuccess", {
    //   params: asdf,
    // });

    const { userObj } = deeets;

    setUserObj(userObj);

    const plusTrue = userObj.hasPlus;

    if (plusTrue) {
      history.push("/dashboard");
    }
    // sdfr(asdf);
  }

  // 1authportal
  function sdfr({
    additionalUserInfo: {
      isNewUser,
      profile: { given_name, username, login, name },
    },
  }) {
    let jifas = given_name
      ? given_name
      : username
      ? username
      : login
      ? login
      : name;

    onSuccess(jifas);
  }

  const dfsgrea = {
    icon: AiOutlineMail,
    style: { background: "green" },
    activeStyle: { background: "lightgreen" },
  };

  //   1form
  const nisae = {
    errorButtonConfig: {
      minHeight: "70px",
    },
    ...emailFormConfig,
    // list: ["username", "email", "password"],
    // dictvar: { username: },
  };

  let asdaweas = [
    //
    "google",
    // "facebook",
    // "github",
    // "email",
  ];

  const xcvbijr = {
    list: asdaweas,
    onSuccess: ASDJA,
    spaceBetween: "20px",
    onBackEmail: () => setemClicko(false),
    emailClick: () => setemClicko(true),
    emailTrue: emClicko,
    emailButtonConfig: dfsgrea,
    emailFormConfig: nisae,
    //
    loadSubmit: true,
    firebase,
    ...aaaa,
  };

  const dijert =
    //
    BearAuthPortal(xcvbijr);
  // BearAuthPortalWithEmailForm(xcvbijr);

  return dijert;
}
