import React, { useContext } from "react"; //
import {
  //
  bearlog,
  BearDiv,
  BearFloat,
  BearList,
} from "@sillyscribe95/bear-ui-web";
import { MainContext } from "../../context/MainContext";
import { VerbConjugateList } from "../../components/VerbConjugateList";
import { PracticeContext } from "./PracticeContext";
import { BearArrayAddRemove } from "../../functions/bear/BearArrayFuncs";
import { butActive } from "../../functions/mainFuncs";
import { verbLink, linkPractice } from "../../functions/linkFuncs";
import { BearColors } from "../../components/bear/BearGlobals";

export function PracticeSubmitTop() {
  const { bothLang, transid } = useContext(MainContext);
  const {
    transString,
    practiceLevel,
    mapVerbs,
    setmapVerbs,
    onShufflePractice,
    currentPracticeItem,
    mapPronouns,
    setmapPronouns,
  } = useContext(PracticeContext);

  // 1top 1conj
  function conjChoose() {
    function awe({ itemName }) {
      //

      const truo = itemName === mapVerbs;
      const newBase =
        //
        truo ? "" : itemName;
      // BearArrayAddRemove(mapVerbs, itemName);

      bearlog.lug(`___ newBase ___`, { mapVerbs, itemName, newBase });

      setmapVerbs(newBase);
      onShufflePractice({ mapVerbs: newBase });
    }

    const res = VerbConjugateList({
      fontSize: "26px",
      style: {
        margin: "10px",
      },
      chosenItem: mapVerbs,
      click: awe,
    });

    return res;
  }

  // 1pronoun
  function PronChoose() {
    function click(typo) {
      const { itemName } = typo;

      setmapPronouns(itemName);
      onShufflePractice({ mapPronouns: itemName });
    }
    const list = [
      //
      "s-m",
      "s-y",
      "s-h",
      "p-m",
      "p-y",
      "p-h",
    ];

    return BearList({
      list,
      click,
      typeList: "return",
      itemConfig: {
        className: "buttonHover",
      },
      chosenConfig: {
        style: BearColors("white", "darkblue"),
      },
      bearName: "asdqwe",
      horiz: true,
      chosenItem: mapPronouns,
    });
  }

  function baseTop() {
    return (
      <>
        {/* {PronChoose()} */}
        {conjChoose()}
      </>
    );
  }

  bearlog.lug(`___ pract submit top ___`, { transid });

  function linkBase(params) {
    const asdfds = BearDiv({
      ...butActive(true),
      obj: "Study",
      ...params,
    });

    return asdfds;
  }

  bearlog.lug(`___ transid ___`, transid);

  let res;
  switch (transid) {
    case "conjugations":
      bearlog.lug(`___ getCenter ___`, { practiceLevel, transid });

      const verb =
        //
        practiceLevel;
      // "speak";

      let link = verbLink({ verb, ...bothLang });
      link = `${link}?back=true`;

      res = (
        <>
          {baseTop()}
          {linkBase({ link })}
        </>
      );
      break;
    case "conjugations-list":
      res = <>{baseTop()}</>;
      break;
    case "verbs":
      let linko = linkPractice({
        transid: "conjugations",
        level: transString,
        ...bothLang,
      });
      res = linkBase({ link: linko });
      break;
    default:
  }

  let endo = res;

  endo = BearDiv({
    obj: endo,
    style: { margin: "20px" },
  });

  endo = BearFloat({
    bottom: endo,
  });

  return endo;
}
