import { bearlog, BearUpper } from "@sillyscribe95/bear-ui-web";
import axios from "axios";
import { compact, filter, pickBy, union } from "lodash";
import {
  BearListRemove,
  BearLocalStorage,
} from "../components/bear/BearGlobals";

export function splitMulti(str, tokens) {
  var tempChar = tokens[0];
  for (var i = 1; i < tokens.length; i++) {
    str = str.split(tokens[i]).join(tempChar);
  }
  str = str.split(tempChar);
  return str;
}
export function trimArr(rest) {
  return rest.trim();
}

export function getFirst(str) {
  let rest = str.substr(0, str.indexOf(" "));
  let last = str.substr(str.indexOf(" ") + 1);

  return { gender: trimArr(rest), verb: trimArr(last) };
}

export function genderSplit(aaaa, lang, tpoe = "last", messge) {
  const str =
    //
    // "";
    aaaa;
  // ?<=^\S+)\s

  let rest = "";
  let last = "";

  switch (tpoe) {
    case "last":
      rest = str.substring(0, str.lastIndexOf(" "));
      last = str.substring(str.lastIndexOf(" ") + 1, str.length);
      break;
    case "first":
      rest = str.substr(0, str.indexOf(" "));
      last = str.substr(str.indexOf(" ") + 1);
      break;
    default:
  }

  const kweq = [
    //
    rest.trim(),
    last.trim(),
    // asijdwe[-1]
  ];

  let asijdwe =
    //
    compact(kweq);
  // aaaa.split(" ");
  // aaaa.match(/(\s\w+$)/g);

  // let findla = asijdwe.slice(1, -1);

  return asijdwe;
}

export function splitBase(str) {
  var tempChar = str.split(",").join(",").split("，").join(",").split(",");

  tempChar = tempChar.map(asdf);

  function asdf(fsdd) {
    return fsdd.trim();
  }
  return tempChar;
}

export function butActive(sdfgr, extra) {
  const jdsewq = sdfgr && {
    //
    color: "white",
    background: "darkblue",
    // ...BearBackBorder("darkblue"),
  };
  var tempChar = {
    className: "buttonHover",
    style: {
      ...extra,
      ...jdsewq,
    },
  };
  return tempChar;
}

export function focusInput(inpref) {
  if (inpref.current) {
    inpref.current.focus();
  }
}

export function removeAll(list, init, others = []) {
  others = init ? [init, ...others] : others;
  list = union(list);

  const result = BearListRemove(list, others);

  return result;
}

const verbId = `vblz-`;
export function getStorage(item, none) {
  let storo = BearLocalStorage(`${verbId}${item}`, none);

  return storo;
}

export function setStorage(id, item, funco) {
  localStorage.setItem(`${verbId}${id}`, item);
  funco(item);
}

export function removeItemArray(niasdw, bbbb) {
  return filter(niasdw, function (currentObject) {
    return currentObject !== bbbb;
  });
}

export async function axiosGet(link, params, { onFetch } = "") {
  link = `/.netlify/functions/${link}`;
  const { data } = await axios.get(link, {
    params,
  });

  if (onFetch) {
    onFetch(data);
  }

  return data;
}

export function getTypesFront(ext = "") {
  let { level, transid } = ext;

  const endbasds = {
    conjugations: "Verbs",
    "conjugations-base": "Verbs",
  }[transid];

  transid = endbasds ? endbasds : transid;
  transid = BearUpper(transid);
  const levelOrig = level;

  const numbTrue = transid == "Numbers";
  const keepSame = !level || numbTrue;

  const levelTitle = keepSame
    ? level
    : level
        .split("-")
        .map((item) => BearUpper(item))
        .join(" ");

  level =
    //
    levelTitle;
  // BearUpper(level);

  const endo = { ...ext, transid, level, levelTitle, levelOrig };

  return endo;
}

// export function mapObject

export function removeEmptyDict(dict) {
  bearlog.lug(`___ removeEmptDict aaa ___`, dict);

  const base = pickBy(dict);

  bearlog.lug(`___ removeEmptDict bbbbb ___`, base);

  return base;
}

export function splitWithTail(str, delim, count) {
  var parts = str.split(delim);
  var tail = parts.slice(count).join(delim);
  var result = parts.slice(0, count);
  result.push(tail);
  return result;
}
