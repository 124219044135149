import { bearlog } from "@sillyscribe95/bear-ui-web";

export function buildLinkFull({ transid, level, ...sad }) {
  level = level ? `/${level}` : "";

  return `${transid}${level}/${buildLink(sad)}`;
}

export function buildLink(pars) {
  let { transid, level, fromLang, toLang } = pars;

  bearlog.lug(`___ pars ___`, pars);

  fromLang = fromLang ? fromLang : "";
  toLang = toLang ? `/${toLang}` : "";

  return `${fromLang}${toLang}`;
}

export function buildLinkVerb(asdas) {
  const { verb } = asdas;
  return `${verb}/${buildLink(asdas)}`;
}

export function verbLink(aaaa) {
  return `/Verbs/${buildLinkVerb(aaaa)}`;
}

export function linkPractice(aaaa) {
  const { transid, level = "l1", type } = aaaa;

  const nsjfwer =
    //
    // level
    // `/practice/${transid}/${buildLink(aaaa)}`;
    `/p/${buildLinkFull(aaaa)}`;

  return nsjfwer;
}

export function linkPracticeChoose(aaaa) {
  const nsjfwer = `/practice/${buildLinkFull(aaaa)}`;

  return nsjfwer;
}

export function linkStudy({ verb, type, ...aaaa }) {
  const dfsgok = verb ? `verb/${verb}` : type;
  const nsjfwer =
    //
    // level
    // `/practice/${transid}/${buildLink(aaaa)}`;
    `/${dfsgok}/${buildLink(aaaa)}`;

  return nsjfwer;
}

export function addHome(aaa) {
  return `${aaa}`;
}

// 1plus
export function linkPlus(aaa) {
  return "/plus";
}

export function linkAuth(aaa) {
  return "/auth";
}

export function changeLink(xcxv) {
  window.location.href = xcxv;
}

export function linkBase(nass, param) {
  return `${nass}/${buildLink(param)}`;
}

export function chooseLangLink(aaa) {
  return `/choose/${buildLink(aaa)}`;
}
