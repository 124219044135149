import {
  BearDiv,
  BearHeight,
  BearTitle,
  BearURLParam,
} from "@sillyscribe95/bear-ui-web";
import React, { useContext, useState } from "react";
import { BearPageWithStripeCheckout } from "../../components/bear/BearPageWithStripeCheckout";
import { CurrencyBase } from "../../components/CurrencyBase";
import { mobView } from "../../const/mainConst";
import { MainContext } from "../../context/MainContext";
import { AuthBase } from "../auth/AuthBase";
import { AuthWrapWithLower } from "../auth/AuthWrapWithLower";
import { PageContainer } from "../PageContainer";
import { getLangBase } from "../translate/getLangFuncs";
import { CheckoutMain } from "./CheckoutMain";
import { UpgradeBenefits } from "./UpgradeBenefits";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import { UpgradeContainer } from "./UpgradeContainer";
import { BearPrivateRoute } from "../../components/bear/BearPrivateRoute";

export function UpgradePage(aaaaa) {
  const {
    //
    match: { url, params },
    ...args
  } = aaaaa;

  const { userLoad, userObj } = useContext(MainContext);

  const routeBase = {
    yesRedirect: "/dashboard",
    noComponent: UpgradeContainer,
    userLoad,
    userObj,
    userAttribute: "hasPlus",
  };

  const children =
    //
    // BearPrivateRoute(routeBase);
    // UpgradeContainer(params);
    UpgradeContainer(params);
  // userLoad ? "" : userObj?.hasPlus ? "no" : UpgradeContainer(params);

  const userConfig = { showPlus: false };

  const headerConfig = {
    //
    // style: { zIndex: 1000 },
    center: "",
  };

  const righsano = mobView && { closeRight: true };

  const sdif = {
    ...params,
    ...righsano,
    headerConfig,
    align: "center",
    userConfig,
    children,
  };

  return PageContainer(sdif);
}
