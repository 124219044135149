import { BearSelect, BearTextMedia } from "@sillyscribe95/bear-ui-web";
import { useContext } from "react"; //
import { IoMdList } from "react-icons/io";
import { getLangBase } from "../containers/translate/getLangFuncs";
import { MainContext } from "../context/MainContext";
import { buildLink, changeLink, verbLink } from "../functions/linkFuncs";
import { SearchOptions } from "./SearchOptions";

export function SearchBar(xzcvsa) {
  const { fromLang, toLang } = useContext(MainContext);

  function vdasdzf(asdfwes) {
    const ksreess =
      //
      "asewaf";
    return ksreess;
  }

  const snsae = {
    optionsArray: ["asdqweq", "qwsa"],
  };

  const asdjfew = {
    fontSize: "20px",
    align: "left",
    // className: "buttonHover",
  };

  const sdijfra = SearchOptions(asdjfew);

  const asdfew =
    //
    sdijfra;
  // searchDatos;
  // searchBase;
  const skaswe = getLangBase("Verb");

  // 1nooptions
  function zfadsx(cxvdss) {
    const verb = cxvdss;
    const { origText } =
      //
      "";
    // getTitleVerb({ verb, fromLang, toLang });

    const jwqws =
      //
      // origText;
      verb;

    const asdf = `${skaswe}: "${jwqws}"`;

    return BearTextMedia({
      align: "left",
      iconvar: IoMdList(),
      fontSize: "18px",
      textvar: asdf,
    });
  }

  // 1search
  function dsfgdfer(verb) {
    const nsfsa = verbLink({ fromLang, toLang, verb });

    changeLink(nsfsa);
  }

  // 1extra
  function extVerb(typesa) {
    const verbTrue = typesa == "Verbs";
    const nsfsa = verbTrue
      ? `/verb/${buildLink({ fromLang, toLang })}`
      : `/${typesa}/${buildLink({ fromLang, toLang })}`;

    changeLink(nsfsa);
  }

  function wersje(asdsad) {
    asdsad =
      //
      `/${asdsad}`;
    // extVerb(asdsad)

    changeLink(asdsad);
  }

  const djfwer = {
    onChangeSetValue: true,
    noOptionsMessage: zfadsx,
    // optionsDict: asdfew,
    optionsArray: asdfew,
    bearName: "ASDfwe",
    hideSearchIcon: true,
    onCreate: dsfgdfer,
    onChange: wersje,
    placeholder: "Search",
    create: true,
    // typeList: "button",
    // renderItem: vdasdzf,
    // width:
    ...xzcvsa,
  };

  // 1console

  const okfdsd = BearSelect(djfwer);

  return okfdsd;
}
