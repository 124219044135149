import React, { useContext } from "react"; //
import {
  //
  bearlog,
  BearDiv,
} from "@sillyscribe95/bear-ui-web";
import { PracticeContext } from "../containers/practice/PracticeContext";
import { getTypesFront } from "../functions/mainFuncs";
import { getLangBase } from "../containers/translate/getLangFuncs";
import { MainContext } from "../context/MainContext";

export function BaseTitle(asdasd) {
  let { toName } = useContext(MainContext);

  let { beforeText } = asdasd;
  let { level, transid } = getTypesFront(asdasd);

  level = level ? ` - ${getLangBase(level)}` : "";
  transid = transid ? ` ${getLangBase(transid)}` : "";
  beforeText = beforeText ? `${getLangBase(beforeText)} ` : "";

  bearlog.lug(`___ transid ___`, transid);

  const titFind = !toName ? "" : `${beforeText}${toName}${transid}${level}`;

  const mainTit =
    //
    // "ASqweqw";
    titFind;

  bearlog.lug(`___ mainTit ___`, mainTit);

  return mainTit;
}
