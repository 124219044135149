import { voiceConst } from "../const/voiceConst";

export function getVoiceBase(lang) {
  const voice = voiceConst.find(asdfewr);

  function asdfewr(obj) {
    const adsf = obj.lang.substring(0, 2);
    const sdifwr = adsf === lang;
    return sdifwr;
  }

  return voice;
}
