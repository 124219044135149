import { BearDiv, bearlog } from "@sillyscribe95/bear-ui-web";
import React, { useState } from "react";
import { BearSpeechRecognition } from "../../components/bear/BearSpeechRecognition";

export function TestSpeechRecognition(aaaaa) {
  // 1const
  // 1const
  const {
    //
    match: { url, params },
    ...args
  } = aaaaa;

  const [speechBase, setspeechBase] = useState();

  function renderItem(base) {
    const {
      finalTranscript,
      interimTranscript,
      transcript,
      start,
      stop,
      recording,
    } = base;

    bearlog.lug(`___ base ___`, base);

    const obj = speechBase ? "TRACKIN" : "RECORD";

    function onClick() {
      if (speechBase) {
        stop();
      } else {
        const language =
          //
          "el";
        // const baseStar = { language: "zh-CN" };
        start({ language });
      }
      setspeechBase(!speechBase);
    }

    const button = BearDiv({
      onClick,
      obj,
      fontSize: "30px",
      className: "buttonHover",
    });

    return (
      <>
        {button}
        <br />
        transcript: {transcript}
        <br />
        interimTranscript: {interimTranscript}
        <br />
        finalTranscript: {finalTranscript}
      </>
    );
  }

  function onStart() {
    //
  }

  function onStop() {
    //
    setspeechBase(false);
  }

  const secondsSilence = 2;

  const okfdsd = BearSpeechRecognition({
    onStart,
    onStop,
    renderItem,
    secondsSilence,
  });

  return okfdsd;
}
