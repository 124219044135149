import { BearList, BearUpper } from "@sillyscribe95/bear-ui-web";
import React, {
  //
  useState,
} from "react";
import { TransBlock } from "../../components/TransBlock";

export function NameContainer({ nameDict }) {
  const listType =
    //
    Object.keys(nameDict);

  //   1name
  //   metaConfig:

  function rwerwe(zxcvd) {
    const sdfkower = {
      // origText: zxcvd,
      transText: zxcvd,
    };

    const rreas =
      //
      // "adsflwe"
      TransBlock(sdfkower);

    return rreas;
  }

  function asdfW({ click, width, ...asdfe }) {
    const { chosenItem } = asdfe;

    function fcxdg({ itemName }) {
      const sdfk = chosenItem == itemName;
      const jis = sdfk ? "" : itemName;

      click(jis);
    }

    function xfgs(xdfxc) {
      return BearUpper(xdfxc);
    }

    const sdfgre = {
      className: "buttonHover",
      align: "center",
      style: {
        minWidth: width,
        fontSize: "24px",
        background: "lightgrey",
        padding: "0 10px",
      },
    };

    return BearList({
      //
      horiz: true,
      chosenConfig: { style: { background: "darkblue", color: "white" } },
      itemConfig: sdfgre,
      style: { margin: "24px 0" },
      bearName: "asdfwe",
      ...asdfe,
      renderItem: xfgs,
      click: fcxdg,
    });
  }

  const [setGen, setsetGen] = useState(
    //
    ""
    // "male"
  );

  //   1gender
  const genderLines = asdfW({
    //
    width: "21vw",
    list: ["Male", "Female"],
    chosenItem: setGen,
    click: (aaaa) => setsetGen(aaaa),
  });

  // 1type
  const [typeo, settypeo] = useState(
    //
    ""
    // "rare"
  );
  //   1types
  const typeList = asdfW({
    // width: "140px",
    list: listType,
    chosenItem: typeo,
    click: (aaaa) => settypeo(aaaa),
  });

  function rendos(xvbdf) {
    const nasw = setGen
      ? xvbdf[setGen]
      : [].concat.apply([], Object.values(xvbdf));

    return nasw;
  }

  function concMap() {
    const dsafr = rendos(nameDict[typeo]);

    const dskmfw =
      //
      dsafr;
    //   concat([1], 2, [3], [[4]]);
    //   concat(dsafr);

    return dskmfw;
  }
  function adsfaew() {
    const jiwer = keMMina.map((ccc) => rendos(nameDict[ccc]));

    return jiwer;
  }

  //   1list
  const keMMina = Object.keys(nameDict);
  let cvbfds = typeo ? concMap() : adsfaew();
  cvbfds = [].concat.apply([], cvbfds);
  // cvbfds = shuffle(cvbfds);

  const sdiasjwe =
    //
    BearList({
      renderItem: rwerwe,
      bearName: "asdqw",
      list: cvbfds,
    });

  // 1main
  const okfdsd = (
    <>
      {/*  */}
      {typeList}
      {genderLines}
      {sdiasjwe};
    </>
  );

  return okfdsd;
}
