import {
  BearDiv,
  BearFixSide,
  BearFloat,
  BearList,
  BearTextMedia,
  bearlog,
  BearTitle,
} from "@sillyscribe95/bear-ui-web";
import React from "react"; //
import { FaUniversity, FaWikipediaW } from "react-icons/fa";
import { GiBrain } from "react-icons/gi";
import { mobView } from "../const/mainConst";
import { DashboardButton } from "../containers/DashboardButtons";
import { getLangOpp } from "../functions/languageFuncs";
import { linkPractice, linkStudy } from "../functions/linkFuncs";
import { getTypesFront } from "../functions/mainFuncs";
import { getGoogleLink } from "../functions/openGoogle";
import { getSearchConst } from "../search/searchConst";
import { BaseTitle } from "./BaseTitle";
import { BearColors, getListDictArgs } from "./bear/BearGlobals";
import { RomanConvert } from "./RomanConvert";
import { TranslateOpen } from "./TranslateOpen";

export function DashMobButtons({
  titleConfig,
  listConfig,
  renderTitle,
  width = mobView ? "90vw" : "200px",
  fontSize = "22px",
  ...asdasd
}) {
  bearlog.lug(`___ DashMobBttons aaaa ___`, asdasd);

  const mainTit = BaseTitle({ beforeText: "Practice", ...asdasd });

  const title = BearTitle({
    // style: { padding: "0 10%" },
    title: mainTit,
    // lineBetween: true,
    fontSize,
    ...titleConfig,
  });

  const itemConfig = {
    align: "center",
    // className: "shadowMain buttonHover",
    style: {
      // width,
      margin: "20px 0",
    },
    ...listConfig,
  };

  const anoto = {
    title,
    bearName: "adqwe",
    fontSize,
    itemConfig,
    ignoreEmpty: true,
    ...asdasd,
  };

  const resso =
    //
    // BearSearchList;
    BearList;

  return resso(anoto);
}
