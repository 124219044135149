import {
  BearDiv,
  BearHeight,
  BearList,
  BearTextMedia,
  BearTitle,
  BearURLParam,
} from "@sillyscribe95/bear-ui-web";
import React, { useContext, useState } from "react";
import { BearPageWithStripeCheckout } from "../../components/bear/BearPageWithStripeCheckout";
import { CurrencyBase } from "../../components/CurrencyBase";
import { mobView } from "../../const/mainConst";
import { MainContext } from "../../context/MainContext";
import { AuthBase } from "../auth/AuthBase";
import { AuthWrapWithLower } from "../auth/AuthWrapWithLower";
import { PageContainer } from "../PageContainer";
import { getLangBase } from "../translate/getLangFuncs";
import { CheckoutMain } from "./CheckoutMain";
import { UpgradeBenefits } from "./UpgradeBenefits";
import { useQueryParam, NumberParam, StringParam } from "use-query-params";
import {
  AiOutlineInfo,
  AiOutlineInfoCircle,
  AiOutlineQuestion,
  AiOutlineQuestionCircle,
} from "react-icons/ai";

export function UpgradeItemParse({ currenBase }) {
  const [typeBase = "main", settypeBase] = useQueryParam("type", StringParam);

  const { toName, userLoad, userObj } = useContext(MainContext);

  const ewass = userObj ? `${userObj.name}, ` : "";
  let sewas = userLoad
    ? ""
    : getLangBase("plusSubtitle", {
        lang: toName,
        cost: currenBase,
        name: userObj?.name,
      });

  const extro = {
    about: rendAbout(),
    features: rendFeatures(),
    main: rendMain(),
  };

  const asdwe = extro[typeBase];

  // 1mainfunc
  function rendMain() {
    const topBit = BearTitle({
      title: getLangBase("plusBanner", {
        // logTrue: true,
        lang: toName,
      }),
      spaceBetween: "30px",
      subtitle: BearDiv({
        style: { height: "140px" },
        obj: `${ewass}${sewas}`,
      }),
    });

    function renderItem({ clock, ...asdw }) {
      function onClick() {
        settypeBase(clock);
      }

      return BearTextMedia({
        fontSize: "24px",
        className: "shadowMain buttonHover",
        onClick,
        ...asdw,
      });
    }

    const list = [
      {
        iconvar: AiOutlineInfoCircle(),
        textvar: "What you get with Plus",
        clock: "features",
      },
      {
        iconvar: AiOutlineQuestionCircle(),
        textvar: "Why we're making Verbalize",
        clock: "about",
      },
    ];

    const bottomBit = BearList({
      list,
      renderItem,
      spaceBetween: "30px",
      bearName: "asdqw",
    });

    return { topBit, bottomBit };
  }

  //   1about
  function rendAbout() {
    const topBit = "Hello";
    const bottomBit = "Render";
    return { topBit, bottomBit };
  }

  function rendFeatures() {
    const topBit = "";

    const fdfds = getLangBase("plusUpgradeTitle");

    const features = UpgradeBenefits({
      title: `↓  ${fdfds}  ↓`,
      // titleConfig:
    });
    const bottomBit =
      //
      // "features; ";
      features;

    return { topBit, bottomBit };
  }

  return asdwe;
}
