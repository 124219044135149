import { bearlog } from "@sillyscribe95/bear-ui-web";
import {
  BsArrowLeft,
  BsArrowRight,
  BsArrowUp,
  BsExclamationLg,
} from "react-icons/bs";

export const conjBasic = {
  Present: {
    iconvar: BsArrowUp(),
    shortName: "Pr",
    symbol: "^",
  },
  Past: {
    iconvar: BsArrowLeft(),
    shortName: "Pa",
    symbol: "<",
  },
  Future: {
    iconvar: BsArrowRight(),
    shortName: "Fu",
    symbol: ">",
  },
  // Command: {
  //   iconvar: BsExclamationLg(),
  // },
};

const conjExtra = {
  PROGRESSIVE_PAST: {
    shortName: "Prg Pa",
    symbol: "<<",
  },
  PROGRESSIVE_PRESENT: {
    shortName: "Prg Pr",
  },
  PERFECT_PAST: {
    shortName: "Prf Pa",
  },
  PERFECT_PRESENT: {
    shortName: "Prf Pre",
  },
  PERFECT_FUTURE: {
    shortName: "Prf Fu",
  },
  PERFECT_PROGRESSIVE_PAST: {
    shortName: "Prf Pa",
  },
  PERFECT_PROGRESSIVE_PRESENT: {
    shortName: "Prf Pr",
  },
  PERFECT_PROGRESSIVE_FUTURE: {
    shortName: "Prf Fu",
  },
  Imperative: {
    symbol: "!",
    // shortName: "!",
  },
};

export const conjDict =
  //
  { ...conjBasic, ...conjExtra };
