import { BearDiv, BearFloat, BearHeight } from "@sillyscribe95/bear-ui-web";
import React, { useContext } from "react";
import useKeypress from "react-use-keypress";
import { BearURLParams } from "../../components/bear/BearGlobals";
import { mobView } from "../../const/mainConst";
import { PracticeItemTop } from "./practice-item-top";
import { PracticeContext } from "./PracticeContext";
import { PracticeSubmit } from "./PracticeSubmit";

export function PracticeItem(zcvz) {
  const {
    swapTrue,
    inpref,
    answerTrue,
    setpracticeAnswer,
    setanswerTrue,
    onDeletePractice,
    onProgressPractice,
    currentPracticeItem,
    onSubmitPractice,
  } = useContext(PracticeContext);

  let {
    //
    inputConfig,
    transString,
    correctResult,
  } = currentPracticeItem;

  // 1const

  useKeypress("Enter", () => {
    onSubmitPractice();
  });

  // 1answertype

  const aisjdfewr = {
    ...zcvz,
    inputConfig: {
      // ref: inpref,
      ...inputConfig,
    },
  };

  const kasdfrew =
    //
    // "";
    BearDiv({
      obj: PracticeSubmit(aisjdfewr),
      // style: { margin: "50px" },
    });

  // 1top
  function topBase() {
    //
    // transString

    const config = {
      // config: { fontSize: "1.1em" },
      ...zcvz,
      style: {
        // background: "blue",
        height: "80px",
      },
    };

    return PracticeItemTop(config);
  }

  const ijwqwew =
    //
    // sdfvds
    // dsfkwer;
    transString && topBase();

  // 1bottom
  function BottBut() {
    let botto =
      //
      "";
    // PracticeSubmitButtons({});

    botto = !mobView
      ? botto
      : BearFloat({
          bottom: BearDiv({
            style: { margin: "5vh 5vw" },
            obj: botto,
          }),
        });

    return botto;
  }

  const bottomBit =
    //
    // jasdwqe
    transString && BottBut();

  const okfdsd = BearDiv({
    style: { marginTop: "30px" },
    obj: (
      <>
        {/*  */}
        {ijwqwew}
        {BearHeight(40)}
        {kasdfrew}
        {bottomBit}
      </>
    ),
  });

  return okfdsd;
}
