import React, { useContext, useState } from "react"; //
import {
  //
  bearlog,
  BearDiv,
  BearList,
  BearFloat,
  BearTextMedia,
} from "@sillyscribe95/bear-ui-web";
import { MainContext } from "../../context/MainContext";
import { linkBase, linkPractice } from "../../functions/linkFuncs";
import { DashboardPracticeLevels } from "../Dashboard/dashboard-practice-levels";
import { PracticeLevelButtons } from "../../components/practice-level-buttons";
import { CustomLanguageSelect } from "../../components/custom-language-select";
import { butActive, setStorage } from "../../functions/mainFuncs";
import { getAllLevels } from "../../functions/levelFuncs";
import { MdRecordVoiceOver } from "react-icons/md";

export function HomeStartPrac() {
  const { toName, toLang, settoLang, bothLang, setToLang } =
    useContext(MainContext);

  // useq
  // const [toLang=, settypeBase] = useQueryParam("type", StringParam);

  function onChange(zxcvsd) {
    bearlog.lug(`___ onChnge ___`, zxcvsd);
    setStorage("toLang", zxcvsd, settoLang);
  }

  const [valsoa, setvalsoa] = useState();
  function onBlurValue(xcvbd) {
    bearlog.lug(`___ xcvbd ___`, xcvbd);
  }

  //   1choose
  function Pracit() {
    // const options =
    const endra = CustomLanguageSelect({
      onChange,
      // onBlurValue,
      // onChangeSetValue: false,
      onInputChange: setvalsoa,
      inputValue: valsoa,
      width: "200px",
      notClearable: true,
      // isSearchable: false,
      menuHeight: "200px",
      fontSize: "26px",
    });
    return endra;
  }

  const basego = {
    ...bothLang,
    transid: "nouns",
  };

  const list = [
    //
    // "have",
    "emotions",
    "colors",
    "family",
    "numbers",
  ];

  const levList = getAllLevels();

  bearlog.lug(`___ levList ___`, levList);

  const okfdsd = PracticeLevelButtons({
    title: "",
    list,
    dictvar: {
      have: {
        transid: "conjugations",
        level: "have",
        iconvar: MdRecordVoiceOver(),
        textvar: "Verb - 'To Speak'",
      },
      numbers: { transid: "numbers", level: "0 - 100" },
      emotions: { transid: "adjectives" },
      colors: { transid: "words" },
    },
    // style: { margin: "60px 0 60px" },
    fontSize: "30px",
    transid: "Nouns",
    bearName: " asdqw",
  });

  //   1explore
  function explor() {
    return BearDiv({
      link: linkBase("/dashboard", bothLang),
      ...butActive(true, {
        marginTop: "50px",
        // textAlign: "center",
        // position: "absolute",
        // bottom: 0,
        // left: 60,
      }),
      obj: "Explore 100+ Tests",
    });
  }

  const tpppo = BearTextMedia({
    // iconvar: "Practice",
    textvar: Pracit(),
  });

  const bbob = explor();

  const exlpro = (
    <>
      {tpppo}
      {okfdsd}
      {bbob}
    </>
  );

  return exlpro;
}
