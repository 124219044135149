import { PageTranslate } from "../PageTranslate";
import { nameCelonst } from "./nameConst";
import { NameContainer } from "./NameContainer";

export function NamePage(aaaaa) {
  // 1const
  // 1const
  const {
    //
    match: { url, params },
    ...args
  } = aaaaa;

  const { toLang } = params;

  const nameDict = nameCelonst[toLang];

  const titConfig = {
    actionConfig: {
      practiceLink: "names",
    },
  };

  const children =
    //
    nameDict && NameContainer({ nameDict });

  const sdif = {
    hasData: nameDict,
    titConfig,
    ...params,
    children,
    transid: "Names",
    // titRight: typeList,
  };

  return PageTranslate(sdif);
}
