import { verbsEnglishMeta } from "./verbs-english-meta";

function listFunc(list, { fromName, toName }) {
  return [...list];
}

export const verbsMeta = {
  showWikitionary: true,
  lists: verbsEnglishMeta,
  listFunc,
};
