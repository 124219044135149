import { bearlog } from "@sillyscribe95/bear-ui-web";
import { shuffle, random } from "lodash";
import { numToWords } from "../../numbers/numberFuncs";
import { practiceMapList } from "../practiceFuncs";
import { getRangeMap } from "../../../functions/bear/BearArrayFuncs";

// 1number
export function practiceGetNumbers(args) {
  const {
    swapTrue,
    toLang,
    fromLang,
    levelOrig,
    level,
    optionNumber,
    ...asdsd
  } = args;

  bearlog.lug(`___ practiceGtNumbers ___`, args);

  const splitaRRAY = levelOrig && splitNumb(levelOrig);

  function splitNumb(levelOrig) {
    const array = levelOrig.split("-").map((num) => parseInt(num));
    return array;
  }

  function parseNumberLevel() {
    const [low, high] = splitaRRAY;
    const rando = random(low, high);
    bearlog.lug(`___ parseNumbLevel bbb ___`, { low, high, rando });

    return rando;
  }

  function getNum() {
    const item =
      //
      parseNumberLevel();
    // Math.floor(Math.random() * asdfe) * multBase;

    return item;
  }

  function donie(num) {
    const onBase = numToWords(num, toLang);
    const ansbase = swapTrue ? onBase : num;

    const origText =
      //
      num;
    // ansbase;

    const transText =
      //
      onBase;

    const basego = { origText, transText };

    return basego;
  }

  function genFunc(rightNum) {
    // const rightNum = getNum();
    const previous = rightNum;
    const transNum = numToWords(rightNum, toLang);

    const origString = swapTrue ? transNum : rightNum;
    const transString = swapTrue ? rightNum : transNum;

    const resultString = `${rightNum} : ${origString}`;

    const origArray =
      //
      [origString];
    // origString.split(" ");

    const transArray =
      //
      [transString];
    // transString.split(" ");

    const inputType = "number";

    const existingArray =
      //
      [origString];

    const extraOpts = getRangeMap(optionNumber - 1, () => donie(getNum()), {
      existingArray,
      // logTrue: false,
    });

    const chooseOptions = shuffle(
      //
      [donie(rightNum), ...extraOpts]
    );

    const correctResult =
      //
      [origString, rightNum, rightNum.toString(), origArray];

    let sfwer = {
      chooseOptions,
      resultString,
      origArray,
      transArray,
      transString,
      origString,
      correctResult,
      inputType,
    };

    bearlog.lug(`___ practice GET NUMBER ___`, sfwer);

    return sfwer;
  }

  const list = getRangeMap(10, getNum, {
    // logTrue: false,
  });

  const mainData =
    //
    // genFunc();
    practiceMapList({ ...asdsd, list, genFunc });

  bearlog.lug(`___ mainData ___`, mainData);

  return { mainData };
}

function switchItem(level) {
  let asdfe = 10;
  let multBase = 1;
  switch (level) {
    case "1":
      asdfe = 12;
      multBase = 1;
      break;
    case "2":
      multBase = 10;
      break;
    case "3":
      asdfe = 100;
      break;
    case "4":
      multBase = 100;
      break;
    // case "5":

    //   break;
    default:
      asdfe = 1000;
  }
}
