import { testUse } from "./testUser";

// 1search
export const searchDatos = {
  //
  adsfw: "adsfw",
  Sadfpwel: "asdfw",
};

// 1user
export const testuser = testUse;

// 1number
export const baseNumb = [
  // {origText, transText}
  // 'cero ,', ' uno ,', ' dos ,', ' Tres ,', ' cuatro', ' cinco ,', ' seis ,', ' Siete ,', ' ocho ,', ' nueve ,', ' diez'
];

// 1greek
export const testGreeKpresent = {
  //
  fromLang: "en",
  toLang: "el",
  conj: "Present",
  type: "Present",
  verb: "run",
  origTit: "to run",
  transTit: "Περπατάω",
  list: [
    { origText: "I run", transText: "Τρέχω", transPlusGender: "εγω Τρέχω" },
    {
      origText: "you run",
      transText: "τρέχεις",
      transPlusGender: "εσύ τρέχεις",
    },
    {
      origText: "he runs",
      transText: "τρεχει",
      transPlusGender: "αυτός τρεχει",
    },
    {
      origText: "we run",
      transText: "τρέχουμε",
      transPlusGender: "εμείς τρέχουμε",
    },
    {
      origText: "you all run",
      transText: "τρέχετε",
      transPlusGender: "εσείς τρέχετε",
    },
    {
      origText: "they run",
      transText: "τρέχουν",
      transPlusGender: "αυτό τρέχουν",
    },
  ],
};

// 1spanish
export const testspanishpresent = {
  //
  fromLang: "en",
  toLang: "es",
  conj: "Present",
  type: "Present",
  verb: "believe",
  origTit: "to believe",
  transTit: "creer",
  list: [
    { origText: "I believe", transText: "yo creo" },
    { origText: "you believe", transText: "tu crees" },
    { origText: "he believes", transText: "él cree" },
    { origText: "we believe", transText: "creemos" },
    {
      origText: "you all believe",
      transText: "todos ustedes creen\n ellos creen",
    },
  ],
};

// 1french
export const testfrenchpresent = {
  //
  fromLang: "en",
  toLang: "el",
  conj: "Present",
  type: "Present",
  verb: "run",
  origTit: "to run",
  transTit: "Περπατάω",
  list: [
    {
      origText: "I search",
      transText: "je recherche",
    },
    {
      origText: "you search",
      transText: "tu cherchez",
    },
    {
      origText: "he searches",
      transText: "il  cherche",
    },
    {
      origText: "we search",
      transText: "nous recherchons",
    },
    {
      origText: "you all search",
      transText: "vous cherchez",
    },
    {
      origText: "they search",
      transText: "ils recherchent",
    },
  ],
};

export const testVerbData =
  //
  testspanishpresent;
// testGreeKpresent;
// testfrenchpresent;

export function testTransFetch() {
  const osdfere =
    //
    // [];
    testVerbData.list;
  // testVerbData.list.map(({ transText }) => transText);

  const skfjqew = [
    { origText: testVerbData.origTit, transText: testVerbData.transTit },
    ...osdfere,
  ];

  return skfjqew;
}
