// 1english

import { engAlph } from "../../const/Alphabet/alphabet-english";

// 1spanish

// 1chinese
const asfwrw = [
  "诶",
  "比",
  "西",
  "迪",
  "伊",
  //   诶	origText: A,	ēi
  // 比	B	bǐ
  // 西	C	xī
  // 迪	D	dí
  // 伊	E	yī
  // 艾弗	F	ài fú
  // 吉	G	jí
  // 艾尺	H	ài chǐ
  // 艾	I	ài
  // 杰	J	jié
  // 开	K	kāi
  // 艾勒	L	ài lè
  // 艾马	M	ài mǎ
  // 艾娜	N	ài nà
  // 哦	O	ó
  // 屁	P	pì
  // 吉吾	Q	jí wú
  // 艾儿	R	ài ér
  // 艾丝	S	ài sī
  // 提	T	tí
  // 伊吾	U	yī wú
  // 维	V	wéi
  // 豆贝尔维	W	dòu bèi ěr wéi
  // 艾克斯	X	yī kè sī
  // 吾艾	Y	wú ài
  // 贼德	Z	zéi dé
];

//// 1greek
const grAlph = {
  α: "άλφα",
  β: "βήτα",
  γ: "γάμμα",
  δ: "δέλτα",
  ε: "έψιλον",
  ζ: "ζήτα",
  η: "ήτα",
  θ: "θήτα",
  ι: "ιώτα",
  κ: "κάππα",
  λ: "λάμβδα",
  μ: "μυ",
  ν: "νυ",
  ξ: "ξι",
  ο: "όμικρον",
  π: "πι",
  ρ: "ρώ",
  σ: "σίγμα",
  τ: "ταυ",
  υ: "ύψιλον",
  φ: "φι",
  χ: "χι",
  ψ: "ψι",
  ω: "ωμέγα",
};

export const mainAlph = {
  base: engAlph,
  // es:
  "zh-CN": asfwrw,
  el: grAlph,
};
