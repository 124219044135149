import {
  BearDiv,
  BearFloat,
  BearHeight,
  BearList,
  BearTextMedia,
} from "@sillyscribe95/bear-ui-web";
import { RiStarSFill } from "react-icons/ri";
import { BearHeroSplash } from "../../components/BearHeroSplash";
import { mobView } from "../../const/mainConst";
import { PageContainer } from "../PageContainer";
import { HomeStartPrac } from "./HomeStartPrac";

export function HomePage(aaaaa) {
  // 1const
  let {
    match: { url, params },
  } = aaaaa;

  function PracticeItem() {
    //
    const obj = HomeStartPrac();
    const baseoas = !mobView && {
      width: "36vw",
      height: "70vh",
    };
    return BearDiv({
      obj,
      className: "shadowMain",
      align: "center",
      style: {
        position: "relative",
        right: "0",
        padding: "20px",
        ...baseoas,
      },
    });
  }

  let title =
    //
    "Speak more, learn faster.";
  title = (
    <>
      {BearHeight(40)}
      {title}
    </>
  );

  const duonotfast = "Want to start simpler than what Duolingo gives you?";

  const learnFirs = "Learn the first thousand words of +25 languages.";

  const nogames =
    //
    "No gamification, no scoreboards: just fast, organised learning.";

  const sqwe = [
    //
    "Phonetic spelling",
    "Custom text-to-speech",
    "Change languages instantly",
  ];

  function renderItem(textvar) {
    const iconvar =
      //
      RiStarSFill();

    return BearTextMedia({
      align: "left",
      iconvar,
      textvar,
    });
  }

  const typase = BearList({
    list: sqwe,
    renderItem,
    bearName: "aoskdw",
  });

  const subtitle = (
    //
    <>
      {/* <div>{duonotfast}</div> */}
      {learnFirs}
      {BearHeight(20)}
      {nogames}
      {BearHeight(20)}
      {typase}
    </>
  );

  const right = PracticeItem();

  function renderMobile() {
    let children = (
      <>
        {learnFirs}
        {BearHeight(40)}
        {right}
        {BearHeight(40)}
        {nogames}
      </>
    );

    children = BearDiv({
      children,
      style: { margin: "20px" },
      align: "center",
    });

    return children;
  }

  const children = BearHeroSplash({
    title,
    style: { marginTop: "10px" },
    fontSize: "32px",
    subtitle,
    right,
    mobileView: mobView,
    renderMobile,
  });

  const footer = BearDiv({
    obj: "ajdqeqw",
  });

  let asdeae = PageContainer({
    children,
    style: { padding: "2px", margin: "2px" },
    // footer,
    ...params,
  });

  return asdeae;
}
