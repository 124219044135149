const sfrs = {
  // a: "ay",
  // b: "bee",
  // c: "see",
  // d: "dee",
  // e: "ee",
  // f: "eff",
  // g: "gee",
  // h: ""
};

export const engAlph = [
  "a",
  "b",
  "c",
  "d",
  "e",
  "f",
  "g",
  "h",
  "i",
  "j",
  "k",
  "l",
  "m",
  "n",
  "o",
  "p",
  "q",
  "r",
  "s",
  "t",
  "u",
  "v",
  "w",
  "x",
  "y",
  "z",
];
