import { BearDiv } from "@sillyscribe95/bear-ui-web";
import { useContext } from "react"; //
import { MainContext } from "../context/MainContext";

export function SpeedButton({ text = "Playback: ", ...args }) {
  // 1const
  const {
    toLang,
    settoLang,
    playSpeed,
    setplaySpeed,
    romTrue,
    setromTrue,
    romLanguage,
    slowTrue,
    setslowTrue,
    showGender,
    setshowGender,
  } = useContext(MainContext);

  // const sdfjewr =
  // Fractions Symbols ½ ⅓ ⅔ ¼ ¾ ⅕ ⅖ ⅗ ⅘ ⅙ - i2Symbol

  function dasfr() {
    //
    let sdfe;
    switch (playSpeed) {
      case 1:
        sdfe = 0.5;
        break;
      case 0.5:
        sdfe = 0.75;
        break;
      case 0.75:
        sdfe = 1;
        break;
      default:
    }

    setplaySpeed(sdfe);
  }

  const dfijge =
    //
    true;

  let markit;
  switch (playSpeed) {
    case 1:
      markit = "1";
      break;
    case 0.5:
      markit = "½";
      break;
    case 0.75:
      markit = "¾";
      break;
    default:
  }

  const nadsjfer = {
    onClick: dasfr,
  };

  function aewr() {
    const sdiafj = {
      // textvar: markit,
      obj: markit,
      // iconvar: BsSpeedometer(),
      ...args,
      ...nadsjfer,
      // className: "buttonHover",
      // swapItems: true,
      // iconConfig: nadsjfer,
    };

    let vcbdfdsd =
      //
      BearDiv(sdiafj);
    // BearTextMedia(sdiafj);
    // BearFlex(sdiafj)

    return vcbdfdsd;
  }

  const okfdsd = !dfijge ? "" : aewr();

  return okfdsd;
}
