import React, { useContext } from "react";
import { SiGoogletranslate } from "react-icons/si";
import { MainContext } from "../context/MainContext";
import { BearGoogleTranslate } from "./GlobalComps";

export function TranslateOpen(text) {
  const { fromLang, toLang } = useContext(MainContext);

  return BearGoogleTranslate({
    obj: <SiGoogletranslate />,
    className: "buttonHover",
    style: {
      color: "blue",
    },
    from: fromLang,
    to: toLang,
    text,
    // ...bbbb,
  });
}
