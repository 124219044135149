import React, { useContext } from "react"; //
import {
  //
  bearlog,
  BearDiv,
  BearFloat,
} from "@sillyscribe95/bear-ui-web";
import { VerbChunk } from "../../components/VerbChunk";
import { PracticeButton } from "../../components/GlobalComps";
import { VerbConjugateList } from "../../components/VerbConjugateList";
import { PageTranslate } from "../PageTranslate";
import { VerbPageData } from "./verb-page-data";
import { VerbLists } from "../VerbList";
import { VerbTitle } from "../VerbTitle";
import { MainContext } from "../../context/MainContext";
import { useHistory } from "react-router-dom";
import { VerbPageInfo } from "./verb-page-info";
import { mobView } from "../../const/mainConst";

export function VerbPageMain() {
  const { currVerbArr, baseData } = useContext(MainContext);

  bearlog.lug(`___ currVerbArr ___`, currVerbArr);
  const { verb } = currVerbArr;

  let chunko = VerbChunk();
  chunko = BearDiv({
    style: { marginTop: "30px" },
    obj: chunko,
  });
  function pracBase() {
    let res = PracticeButton({
      fontSize: "34px",

      level: verb,
      ...baseData,
      transid: "conjugations",
    });

    res = BearFloat({
      bottom: res,
      // style: { margin: "10x" },
    });
    return res;
  }

  const askrww = mobView && pracBase();
  const okfdsd = (
    <>
      {/*  */}
      {chunko}
      <hr />
      {askrww}
    </>
  );

  return okfdsd;
}
