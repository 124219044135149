import { bearlog } from "@sillyscribe95/bear-ui-web";
import { useContext } from "react";
import { BearNetlifyFetch } from "../../components/bear/BearNetlifyFetch";
import { MainContext } from "../../context/MainContext";

export function Translateapipage(aaaaa) {
  // 1const
  let {
    //
    match: { params },
    ...args
  } = aaaaa;

  const { transid, level } = params;

  params = {
    // redoTrue: true,
    ...params,
  };
  const { toName } = useContext(MainContext);

  const { data, loading } = BearNetlifyFetch("createTranslateFile", { params });

  if (loading) {
    return "LOADING";
  }

  if (data) {
    bearlog.lug("___ TRANSLATE PAGE ___", data);

    const name = `${toName} ${transid} ${level}`;

    return (
      <div>
        {JSON.stringify(data)}
        <div>{name}</div>
      </div>
    );
  }

  return "NO DATA";
}
