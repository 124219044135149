import React from "react"; //
import {
  //
  bearlog,
  BearDiv,
} from "@sillyscribe95/bear-ui-web";
import { useAuthState } from "react-firebase-hooks/auth";
import { useDocumentData } from "react-firebase-hooks/firestore";

export function BearAuthFetch({ firebase, fetchCollection }) {
  const authBase = firebase.auth();
  const store = firebase.firestore();

  // 1auth
  const [auth, authLoading, authErr] = useAuthState(authBase);

  // 1store
  const docco = fetchCollection && auth ? store.doc(`users/${auth.uid}`) : null;
  const [data, dataLoading, dataErr] = useDocumentData(docco);

  const loading = authLoading || dataLoading;

  let userDetails = !loading &&
    auth && {
      name: auth.displayName,
      // ...auth,
      ...data,
    };

  const error = authErr;

  bearlog.lug(`___ userDetails ___`, userDetails);

  return { userDetails, loading, error };
}
