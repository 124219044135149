import { PageTranslate } from "../PageTranslate";
import { countrydata } from "./countries-fetch";
import { CountryContainer } from "./CountryContainer";

export function CountryPage(aaaaa) {
  const {
    //
    match: { url, params },
    ...args
  } = aaaaa;

  const { fromLang, toLang } = params;

  // 1const
  const toConts = countrydata[toLang];

  // 1title
  const titlos = {
    actionConfig: {
      // playText: mapNumSound,
      practiceLink: "countries",
    },
  };

  const baseargsre =
    //
    CountryContainer();

  const sdif = {
    hasData: toConts,
    url,
    ...params,
    children: baseargsre,
    titConfig: titlos,
    transid: "Countries",
  };

  return PageTranslate(sdif);
}
