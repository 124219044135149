// 1english
export const en = {
  "s-m": "I",
  "s-y": "you (s)",
  "s-h": "it",
  "p-m": "we",
  "p-y": "you (p)",
  "p-h": "they",
};

// 1spanish
export const es = {
  "s-m": "yo",
  "s-y": "tu",
  "s-h": "el",
  "p-m": "мы",
  "p-y": "вы",
  "p-h": "они",
};
export const ru = {
  "s-m": "я",
  "s-y": "ты",
  // "s-m": "он",
  "s-f": "она",
  "s-h": "это",
  "p-m": "мы",
  "p-y": "вы",
  "p-h": "они",
};

export const el = {
  // "s-m": "εχο",
  // "s-y": "εσι",
  // "s-h": "it",
  // "p-m": "we",
  // "p-y": "you (p.)",
  // "p-h": "they",
};

export const genderRepsDict = {
  en,
  ru,
  el,
};
