import { bearlog, BearLower } from "@sillyscribe95/bear-ui-web";
import { get, sampleSize, shuffle } from "lodash";
import { removeItemArray } from "../../../functions/mainFuncs";
import { prepPracticeList } from "../practiceFuncs";

// 1verbs
export function practiceGetDefaultAPI({
  toLang,
  fromLang,
  romTrue,
  swapTrue,
  level = "l1",
  userObj,
  metaDict,
  adjMetaTo,
  adjMetaFrom,
  showWikitionary,
  optionNumber = 3,
  ...dsfad
}) {
  bearlog.lug(`___ adjMetaTo ___`, adjMetaTo);

  const adjMetaGen = metaDict;

  // const toList = Object.keys(adjMetaTo)
  const mainList = get(adjMetaGen, `lists[${level}]`);
  const list = mainList ? mainList : Object.keys(adjMetaTo);
  const { currList, previous } = prepPracticeList({ list, ...dsfad });

  const mainData = {};

  function gettodict(item) {
    bearlog.lug(`___ item ___`, item);

    let transBase = get(adjMetaTo, item);
    transBase = transBase && BearLower(transBase);
    return transBase;
  }

  currList.map(getDataAll);
  function getDataAll(current) {
    const languageRand = current;

    const transBase = gettodict(languageRand);
    const findLangDict = adjMetaFrom ? adjMetaFrom[languageRand] : languageRand;
    const origBase = transBase ? findLangDict : "";

    const allList = removeItemArray(list, origBase);
    // const stringGet
    const chooseOptOrig = shuffle([
      //
      ...sampleSize(allList, optionNumber - 1),
      origBase,
    ]);
    const chooseOptTrans =
      //
      // "";
      chooseOptOrig.map((aaa) => gettodict(aaa));

    const chooseOptBoth = chooseOptOrig.map(goBest);
    function goBest(origText) {
      bearlog.lug(`___ origText ___`, origText);

      return { origText, transText: gettodict(origText) };
    }

    const origString = swapTrue ? transBase : origBase;
    const origArray = [origString];
    const transString = swapTrue ? origBase : transBase;
    const transArray = [transString];

    const chooseOptions =
      //
      // chooseOptOrig;
      // chooseOptTrans;
      chooseOptBoth;
    // swapTrue ? chooseOptTrans : chooseOptOrig;

    const speechString = swapTrue && transBase;

    const correctResult = origArray;

    const extBase = {
      speechString,
      origArray,
      transArray,
      chooseOptBoth,
    };

    const asdfrw = {
      // ...extBase,
      origString,
      transString,
      correctResult,
      chooseOptions,
    };

    const logBase = {
      //
      chooseOptOrig,
      chooseOptTrans,
      // verbMeta,
      current,
      list,
      ...asdfrw,
      // languageBase,
    };
    // 1console
    bearlog.lug("pgetd-sa", logBase);

    mainData[transString] = asdfrw;
  }

  // 1console

  return {
    toLang,
    fromLang,
    mainData,
    previous,
    showWikitionary,
  };
}
