import {
  BearDiv,
  BearFloat,
  BearList,
  BearPage,
  BearSocialLinks,
  BearTextMedia,
} from "@sillyscribe95/bear-ui-web";
import React, { useContext } from "react"; //
import { FaFacebook } from "react-icons/fa";
import { IoInformationCircleOutline, IoSettingsOutline } from "react-icons/io5";
import { CountryShow } from "../components/CountryShow";
import { mobView } from "../const/mainConst";
import { MainContext } from "../context/MainContext";
import { SearchBar } from "../search/SearchBar";
import { UserSiteButtons } from "./UserSiteButtons";

export function PageDesktop({ userConfig, headerConfig, ...ddd }) {
  // 1search 1submit

  const { toLang } = useContext(MainContext);

  const ewrwe =
    //
    // "";
    // VerbSearch({
    "";

  // 1roman
  const osdfwe =
    //
    //
    // "";
    BearList({
      horiz: true,
      bearName: "adsfew",
      list: [
        //
        "info",
        "settings",
      ],
      typeList: "button",
      itemConfig: {
        style: {
          //
          width: "40px",
          // padding: "2px",
        },
        noText: true,
        className: "buttonHover",
      },
      dictvar: {
        settings: { iconvar: IoSettingsOutline() },
        info: { iconvar: IoInformationCircleOutline() },
      },
      style: { marginRight: "10px" },
    });
  // PageTopButtons({});
  // RomanButton();

  // 1center
  const jifeqwa =
    //
    // osdfwe
    BearFloat({
      center: ewrwe,
      // left: osdfwe,
    });
  // ewrwe;

  // const swea = Bera;

  const ijswrer = CountryShow({
    lang: toLang,
    type: "to",
  });

  // 1right
  const right =
    //
    UserSiteButtons(userConfig);
  // BearDiv({
  //   //
  //   style: { minWidth: "30vw" }, obj: UserSiteButtons(userConfig) });

  headerConfig = {
    right,
    center: jifeqwa,
    ...headerConfig,
    left: BearTextMedia({ textvar: ijswrer, iconvar: headerConfig.left }),
    // leftConfig: { style: { width: "100px" } },
  };

  const ijwae = BearSocialLinks({
    fontSize: "60px",
    bearName: "ASea",
    list: ["facebook", "slack"],
    horiz: true,
    dictvar: {
      facebook: {
        icon: <FaFacebook />,
        link: "http://mubs.com/",
      },
    },
  });

  const sdfgrew = BearFloat({
    // style: { height: "100px" },
    left: "asdeq aseewq",
    right: ijwae,
  });

  // 1footer
  const foot = {
    // footer: sdfgrew,
    footerConfig: {
      style: { padding: "10px 50px" },
      // sticky: true,
      // height: "60px",
    },
  };

  let ksfwa = {
    ...foot,
    ...ddd,

    headerConfig,
  };

  return BearPage(ksfwa);
}
