import { BearTypes } from "../../components/bear/BearGlobals";

export function getAdjective(aaaaa) {
  const { text, gender, lang } = aaaaa;
  const sewqr = BearTypes(lang, {
    // el: (aa) => greekAdjFunc(aaaaa),
  });

  return sewqr;
}
