import { BearTitle, BearURLParam } from "@sillyscribe95/bear-ui-web";
import { useContext } from "react"; //
import { FixBase } from "../../components/GlobalComps";
import { MainContext } from "../../context/MainContext";
import { PageContainer } from "../PageContainer";
import { AuthWrapWithLower } from "./AuthWrapWithLower";

export function AuthPage(vvvv) {
  const {
    match: { url, params },
    ...args
  } = vvvv;

  const { fromLang, toLang, toName } = useContext(MainContext);

  //   1auth
  let currentType = BearURLParam("auth");
  let xcvrwe = AuthWrapWithLower({ currentType });

  // 1title
  const jssdf = toName && `${toName} lessons`;
  const dswrsa = BearTitle({
    fontSize: "34px",
    align: "center",
    title: jssdf,
    subtitle:
      "We offer the most comprehensive, bar none, language transfalte ion.",
  });

  const sdwew = FixBase({
    left: dswrsa,
    right: xcvrwe,
  });

  const okfdsd = PageContainer({
    //
    ...params,
    headerConfig: { right: "" },
    // userConfig,
    // style: { marginTop: "10vh" },
    children: sdwew,
  });

  return okfdsd;
}
