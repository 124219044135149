import { bearMobileView } from "@sillyscribe95/bear-ui-web";

export const mobView =
  //
  // true;
  // "";
  bearMobileView;

export const testTrue =
  //
  // "";
  "qwe";
