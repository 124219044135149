import { BearDiv, bearlog } from "@sillyscribe95/bear-ui-web";
import { useContext } from "react";
import { AiOutlineCloseCircle, AiOutlineSetting } from "react-icons/ai";
import { IoSwapVerticalSharp } from "react-icons/io5";
import { MdRecordVoiceOver } from "react-icons/md";
import { useHistory } from "react-router-dom";
import { PracticeContext } from "../containers/practice/PracticeContext";
import { MainContext } from "../context/MainContext";
import { butActive, setStorage } from "../functions/mainFuncs";
import { RomanButton } from "./RomanButton";
import { SpeedButton } from "./SpeedButton";

export function MainSiteButtons(type, anot) {
  bearlog.lug(`___ MainSiteButtons aa ___`, anot);

  const { transid, romTrue, inpref, toLang, fromLang, swapTrue, setswapTrue } =
    useContext(MainContext);

  const {
    settingsTrue,
    setsettingsTrue,
    playOpenTrans,
    setplayOpenTrans,
    playOpenOrig,
  } = useContext(PracticeContext);

  // const shareBase =

  const history = useHistory();

  const buttonSpeak =
    //
    MdRecordVoiceOver();
  // IoChatbubbleSharp();
  // BsFillPersonLinesFill()

  function rendo(ereadas) {
    return BearDiv({
      ...ereadas,
      ...anot,
    });
  }

  const dictvar = {
    Close: rendo({
      ...butActive(),
      obj: AiOutlineCloseCircle(),
      onClick: () => history.goBack(),
    }),
    Settings: rendo({
      ...butActive(),
      obj: AiOutlineSetting(),
      onClick: () => setsettingsTrue(true),
    }),
    Speed: rendo({
      ...butActive(),
      obj: SpeedButton({}),
    }),
    Roman: RomanButton({}),
    Swap: rendo({
      ...butActive(swapTrue),
      obj: IoSwapVerticalSharp(),
      // link: linkPractice()
    }),
    Speaker: rendo({
      ...butActive(playOpenTrans),
      obj: buttonSpeak,
      onClick: () =>
        setStorage("playOpenTrans", !playOpenTrans, setplayOpenTrans),
    }),
  };

  return type ? dictvar[type] : dictvar;
}
