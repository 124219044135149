export function getGoogleLink({ trans, from, to, home }) {
  home = home ? home : from;
  const baseLink = `https://translate.google.com/?hl=${home}&sl=${from}&tl=${to}`;

  const sdfewrsa =
    //
    `${baseLink}&op=translate&text=${trans}`;

  return sdfewrsa;
}

export function openGoogle(trans, fromLang, toLang) {
  trans = trans.map((asdrea) =>
    asdrea.join(
      //
      "%0A"
      // "\n"
    )
  );
  trans = trans.join("%0A%0A%0A");

  // 1translate
  const sdfewrsa = getGoogleLink(trans, fromLang, toLang);
  //
  //

  let aidwe =
    //
    // ""
    // "_blank"
    "_parent";
  // "_self"

  window.open(sdfewrsa, aidwe);
  //
}
