import { bearlog } from "@sillyscribe95/bear-ui-web";

import React, { useEffect, useState } from "react";
import { BearNetlifyFetch } from "../../components/bear/BearNetlifyFetch";
import { axiosGet } from "../../functions/mainFuncs";
export function VerbapiPage(aaaaa) {
  // 1const
  const {
    //
    match: { params },
    ...args
  } = aaaaa;

  const { verb } = params;
  params["level"] = verb;
  params["transid"] = "conjugations";
  params["redoTrue"] = "true";

  let basad =
    //
    // `get-meta-dict-api`;
    `get-conjugations-api`;

  const { data, loading } = BearNetlifyFetch(basad, { params, onFetch });

  bearlog.lug(`___ data ___`, data);

  function onFetch(xcvasd) {
    bearlog.lug(`___ xcvasd ___`, xcvasd);
  }

  const [dicto, setdicto] = useState();
  useEffect(() => {
    // deicto();

    return () => {};
  }, []);

  async function deicto() {
    let dicot = await axiosGet(basad, params);

    bearlog.lug(`___ dicot ___`, dicot);

    const doneo =
      //
      "";
    // ["Future"]

    let inner = "";
    inner = {
      indent: "",
      singleQuotes: false,
    };

    dicot =
      //
      dicot;
    // stringifyObject(dicot, inner);
    // JSON.stringify(dicot, doneo);

    bearlog.lug(
      `___ dicot aaaaaa ___`,
      //
      dicot
    );

    setdicto(dicot);
  }

  function verBone(verb) {
    // VerbapiRend({ ...params, verb, dicto });
    // return <div>{`http://localhost:8888/VERBAPI/${verb}/en/en`}</div>;
  }

  const okfdsd =
    //
    // each;
    // "Res";
    dicto ? dicto : "LOADING";

  bearlog.lug(`___ okfdsd ___`, okfdsd);

  if (loading) {
    return "LOADING --- -asds";
  }

  return (
    <>
      datat here:
      {/* {okfdsd} */}
    </>
  );
}
