import { union } from "lodash";
// import { googLangCodes } from "../const/Languages/languages-en";
import { allWebVoice } from "../const/voiceConst";

const googLangCodes = {};

export function getLangChoose({ toLang, fromLang }) {
  // de es fr el ru zh-CN hi

  let iadsf = [
    "es",
    "en",
    "fr",
    "el",
    "sv",
    // "ga",
    "de",
    "he",
    // "it",
    // "pt",
    "ru",
    // "ja",
    // // "ko",
    // "zh-CN",
    // // "ar",
    // // "bn",
    // "hi",
    // // "tr",
  ];

  const langArr = getArrBase(toLang);

  let googLag = Object.keys(langArr);
  // googLag = shuffle(googLag);

  iadsf = union([
    //
    ...allWebVoice,
    // ...iadsf,
    // ...googLag,
  ]);

  iadsf = iadsf.filter(function (item) {
    const eqTo = item !== toLang;
    const eqFrom = item !== fromLang;
    const dsgroe =
      //
      eqTo && eqFrom;

    return dsgroe;
  });

  return iadsf;
}

export function getLangOpp(lango) {
  let langBase = {
    el: "gr",
  }[lango];

  return langBase ? langBase : lango;
}

function getLangOptions({ fromLang, toLang }) {
  const mapDict = {};
  Object.entries(googLangCodes).map(
    ([code, language]) => (mapDict[code] = { code, language })
  );

  return { list: getLangChoose({ fromLang, toLang }), dictvar: mapDict };
}

export function getArrBase(lang) {
  const langArr = googLangCodes;

  return langArr;
}

export function getLangLanguage(fromLang, langDict) {
  const ewwe = langDict[fromLang];

  const nadw = ewwe ? ewwe : googLangCodes;
  return nadw;
}

export function getLangCode(fromLang, lang) {
  const jsfasa = getArrBase(fromLang);
  const asdfer = googLangCodes[lang];

  const sdfr =
    //
    asdfer;
  // jsfase;
  // asdfer;
  // lang;

  return sdfr;
}
