import { bearlog } from "@sillyscribe95/bear-ui-web";
import {
  useContext,
  //
  useState,
} from "react";
import { useHistory } from "react-router-dom";
import { axiosGet } from "../../functions/mainFuncs";
import { userAddData } from "../../functions/userFuncs";
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import { MainContext } from "../../context/MainContext";

export function CheckoutMain(aaaa) {
  const { totalPayment } = aaaa;
  const { userObj } = useContext(MainContext);

  async function paymentIntentFunction() {
    return await axiosGet("stripePaymentIntent", {
      amount: totalPayment,
      currency: "usd",
    });
  }

  // 1payment
  const asiwease = {
    paymentLabel: "request a song",
    paymentConfig: {
      country: "GB",
      currency: "gbp",
    },
    // nativePaymentConfig: {
    //   requestPayerName: true,
    //   requestPayerEmail: true,
    // },
  };

  const swereas = {
    // background: "blue",
    // minHeight: "80px",
    padding: "0 10%",
  };

  // 1native
  const nativos = {
    nativePayment: true,
    nativePaymentConfig: {
      hideErrors: true,
      loadingItem: "",
      noNativePaymentMessage: "",
      style: swereas,
    },
  };

  let history = useHistory();

  const db = firebase.firestore();

  // 1success
  function onSuccess(asdf) {
    bearlog.lug(`___  plus checkout SUCCESS ___`, { asdf, userObj });

    userAddData(
      db,
      userObj,
      {
        // name: ""
        hasPlus: true,
      },
      { onSuccess: donei }
    );

    function donei() {
      const linkJoin =
        //
        "/dashboard?plus=joined";

      history.push(linkJoin);
    }
  }

  // 1failure
  function onFailure(asdre) {
    bearlog.lug(`___ plus checkout FAILBASE ___`, asdre);
  }

  // 1cancel
  function onCancel() {}

  const appearance = {
    theme: "stripe",
    variables: {
      colorPrimary: "#0570de",
      colorBackground: "#ffffff",
      colorText: "#30313d",
      colorDanger: "#df1b41",
      fontFamily: "Ideal Sans, system-ui, sans-serif",
      spacingUnit: "2px",
      borderRadius: "4px",
      // See all possible variables below
    },
  };

  const style = {
    style: {
      base: {
        iconColor: "#c4f0ff",
        color: "#fff",
        fontWeight: "500",
        fontFamily: "Roboto, Open Sans, Segoe UI, sans-serif",
        fontSize: "16px",
        fontSmoothing: "antialiased",
        ":-webkit-autofill": {
          color: "#fce883",
        },
        "::placeholder": {
          color: "#87BBFD",
        },
      },
    },
  };

  const elementConfig = {
    // hidePostalCode: true,
    // appearance,
    // style,
    // fields,
  };

  // 1title
  function onLoading() {
    // setloadTrue();
    setloadTrue("Loading...");
  }

  const formConfig = {
    // confirmModal: "Please confirm your payment",
    onLoading,
    onCancel,
    onFailure,
    onSuccess,
  };

  const [buttonText, setloadTrue] = useState("Pay");

  const buttonConfig = {
    style: {
      width: "300px",
      margin: "20px",
      // padding: "0 80px"
    },
    className: "expandHover buttonHover",
  };

  const config = {
    height: "60px",
    fill: "grey",
    speed: 1,
    strokeOpacity: 1,
    strokeWidth: 1,
  };
  const loadItem =
    //
    "";
  // "LOADING";
  // Bars(config)
  // BallTriangle(config)

  const errorItem = "ERROR";

  const testConfig = {
    publicKey: process.env.REACT_APP_STRIPE_testpubKey,
    return_url: "http://localhost:8888/dashboard",
  };

  const liveConfig = {
    publicKey: process.env.REACT_APP_STRIPE_livepubKey,
    return_url: "https://verbalize.one/dashboard",
  };

  // 1test
  const testTrue =
    //
    // "";
    true;

  const stripeConfig = testTrue ? testConfig : liveConfig;

  const usjdre = {
    // 1native
    ...nativos,
    bearName: "bearCheckTest",
    // 1payment
    ...asiwease,
    ...formConfig,
    buttonText,
    buttonConfig,
    paymentIntentFunction,
    elementConfig,
    loadItem,
    errorItem,
    ...stripeConfig,
    ...aaaa,
  };

  const dsnafaer =
    //
    usjdre;
  // loadItem;
  // BearCheckoutStripeNew(usjdre);
  // "asdokqwe";

  return dsnafaer;
}
