import { BearDiv, BearTextMedia, BearTitle } from "@sillyscribe95/bear-ui-web";
import { getPlusConst } from "../const/plus-const";
import { getLangBase } from "../containers/translate/getLangFuncs";
import { linkPlus } from "../functions/linkFuncs";
import { BearColors } from "./bear/BearGlobals";

export function PlusMessage({ plusConfig, ...asdfsd }) {
  const title = getLangBase("plusDashMess");
  const subtitle = BearTextMedia(getPlusConst(plusConfig));

  const okfdsd = BearTitle({
    title,
    subtitle,
    align: "center",
    ...asdfsd,
  });

  return okfdsd;
}
