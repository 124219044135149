import React, { useEffect, useState } from "react"; //
import {
  //
  bearlog,
  BearDiv,
} from "@sillyscribe95/bear-ui-web";
import { loadStripe } from "@stripe/stripe-js";
import { axiosGet } from "../../functions/mainFuncs";
import { CheckoutMain } from "../upgrade/CheckoutMain";

export function Testcheckout() {
  return CheckoutMain({});
}
