import { BearContextProvider } from "@sillyscribe95/bear-ui-web";
// import { KeyboardCustom } from "./main/key/KeyboardCustom";
import "rc-tooltip/assets/bootstrap_white.css";
import { QueryClient, QueryClientProvider } from "react-query";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import "./App.css";
import { BearPrivateRoute } from "./main/components/bear/BearPrivateRoute";
import { firebaseConfig } from "./main/const/firebaseConst";
import { AlphaPage } from "./main/containers/alphabet/AlphaPage";
import { AuthPage } from "./main/containers/auth/AuthPage";
import { ChooseLangPage } from "./main/containers/config/choose-languages/ChooseLangPage";
import { CountryPage } from "./main/containers/countries/CountryPage";
import { DashboardPage } from "./main/containers/Dashboard/DashboardPage";
import { DashboardPracticeChoosePage } from "./main/containers/Dashboard/DashboardPracticeChoosePage";
import { DataListPage } from "./main/containers/datalist/DataListPage";
import { ListPage } from "./main/containers/list/ListPage";
import { NamePage } from "./main/containers/names/NamePage";
import { NumberPage } from "./main/containers/numbers/NumberPage";
import PracticeContextProvider from "./main/containers/practice/PracticeContext";
import { PracticePage } from "./main/containers/practice/PracticePage";
import { TestMetaList } from "./main/containers/test/test-meta-list";
import { Testcheckout } from "./main/containers/test/Testcheckout";
import { TestPage } from "./main/containers/test/TestPage";
import { Translateapipage } from "./main/containers/translateapi/TranslateAPIPage";
import { UpgradePage } from "./main/containers/upgrade/UpgradePage";
import { VerbSearchPage } from "./main/containers/verbSearch/verb-search-page";
import { WatchForm } from "./main/containers/watchkeep/WatchForm";
import MainContextProvider from "./main/context/MainContext";
import { VerbPage } from "./main/containers/verb-page/VerbPage";
import firebase from "firebase/compat/app";
import { GroundPage } from "./main/pages/GroundPage";
import { QueryParamProvider } from "use-query-params";
import { HomePage } from "./main/containers/homepage/HomePage";
import { Testlanguages } from "./main/containers/test/Test-languages";
import { VerbapiPage } from "./main/containers/verbAPI/VerbapiPage";
import { TestSpeechRecognition } from "./main/containers/test/Test-speech-recognition";
import { testTrue } from "./main/const/mainConst";

//
const app =
  //
  // "";
  firebase.initializeApp(firebaseConfig);

const queryClient = new QueryClient();

function returnLink(baso) {
  const baseCont = `${baso}/:fromLang?/:toLang?`;

  return baseCont;
}
function App() {
  // 1dashboard
  const redRos =
    //
    "/plus";
  // "auth/?auth=signin"

  const dashConfig = {
    path: returnLink("/dashboard"),
    component:
      //
      // DashboardPracticeChoosePage,
      DashboardPage,
    // noRedirect: redRos,
    // yesComponent: DashboardPage,
  };

  // 1plus
  const plusConfig = {
    path: returnLink("/plus"),
    component: UpgradePage,
    // noRedirect: redRos,
    // yesComponent: UpgradePage,
  };

  // 1home
  const homeConfig = {
    path: "/",
    component:
      //
      GroundPage,
    // HomePage,
  };

  // 1test
  const testLinks = testTrue && [
    { path: returnLink("/testspeak"), component: TestSpeechRecognition },
    { path: returnLink("/verbapi/:verb"), component: VerbapiPage },
    {
      path: returnLink("/translateapi/:transid/:level"),
      component: Translateapipage,
    },
    { path: returnLink("/metalist/:transid/:level"), component: TestMetaList },
  ];
  // <Routel149
  //                   path={returnLink("/verbapi/:verb")}
  //                   component={VerbapiPage}
  //                 />
  //                 <Route
  //                   path={returnLink("/translateapi/:transid/:level")}
  //                   component={Translateapipage}
  //                 />
  //                 <Route
  //                   path={returnLink("/metalist")}
  //                   component={TestMetaList}
  //                 />

  const main = (
    <QueryClientProvider client={queryClient}>
      <MainContextProvider>
        <PracticeContextProvider>
          <BearContextProvider chakra>
            <BrowserRouter>
              <QueryParamProvider ReactRouterRoute={Route}>
                <Switch>
                  <Route path="/search" component={ListPage} />
                  <Route
                    path="/datalist/:type/:level/:fromLang?/:toLang?/"
                    component={DataListPage}
                  />
                  <Route
                    path="/numbers/:fromLang?/:toLang?/"
                    component={NumberPage}
                  />
                  <Route
                    path="/alphabet/:fromLang?/:toLang?/"
                    component={AlphaPage}
                  />
                  <Route
                    path={returnLink("/names/:name?")}
                    component={NamePage}
                  />
                  <Route
                    path={returnLink("/countries/")}
                    component={CountryPage}
                  />
                  <Route
                    path={returnLink("/Verbs/:verb")}
                    component={VerbPage}
                  />
                  <Route
                    path={returnLink("/verbs")}
                    component={VerbSearchPage}
                  />
                  {testLinks && testLinks.map((aaaa) => <Route {...aaaa} />)}
                  <Route
                    path="/choose/:langFrom?/:langTo?"
                    component={ChooseLangPage}
                  />
                  <Route path="/watchkeep/" component={WatchForm} />
                  <BearPrivateRoute
                    yesRedirect="/dashboard/"
                    path="/auth/:fromLang?/:toLang?"
                    noComponent={AuthPage}
                  />
                  <BearPrivateRoute {...plusConfig} />
                  {/* <Route path="/plus-club" component={UpgradePage} /> */}
                  <Route path="/checkout" component={Testcheckout} />
                  <Route path="/test" component={TestPage} />
                  <Route {...dashConfig} />
                  <Route
                    path={returnLink("/practice/:transid")}
                    component={DashboardPracticeChoosePage}
                  />
                  <Route
                    path="/p/:transid/:level/:fromLang?/:toLang?/"
                    component={PracticePage}
                  />
                  <Route path="/langs" component={Testlanguages} />
                  <Route path="/home" component={HomePage} />
                  <Route {...homeConfig} />
                </Switch>
              </QueryParamProvider>
            </BrowserRouter>
          </BearContextProvider>
        </PracticeContextProvider>
      </MainContextProvider>
    </QueryClientProvider>
  );
  return main;
}

export default App;
