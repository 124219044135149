import {
  BearButtonList,
  BearFloat,
  BearTextMedia,
} from "@sillyscribe95/bear-ui-web";
import { useContext } from "react";
import { BsShuffle } from "react-icons/bs";
import { RiDeleteBinLine } from "react-icons/ri";
import { BearColors } from "../../components/bear/BearGlobals";
import { mobView } from "../../const/mainConst";
import { PracticeContext } from "./PracticeContext";

export function PracticeSubmitButtons(params) {
  const { onProgressPractice } = useContext(PracticeContext);

  function onSubmit() {}
  function onShuffle() {}
  function onDelete() {}

  //  1dictvar
  const jiqew = {
    Continue: {
      background: "green",
      onClick: onProgressPractice,
    },
    Focus: {
      background: "green",
      //   onClick: inpfocus,
    },
    Submit: {
      background: "green",
      onClick: onSubmit,
    },
    Skip: {
      background: "black",
      onClick: onSubmit,
    },
    Shuffle: {
      iconvar: BsShuffle(),
      background: "darkblue",
      onClick: onShuffle,
    },
    Remove: {
      iconvar: RiDeleteBinLine(),
      background: "red",
      onClick: onDelete,
    },
    Reveal: {
      background: "darkblue",
      onClick: onSubmit,
    },
    Retry: {
      background: "darkblue",
    },
  };

  const nasdfqewr = {
    // fontSize,
    style: {
      maxWidth: mobView ? "40vw" : "200px",
      color: "white",
    },
  };

  const list = ["Continue"];

  const jasdwqe = BearButtonList({
    bearName: "asdqwkeq",
    dictvar: jiqew,
    ignoreEmpty: true,
    itemConfig: nasdfqewr,
    list,
    horiz: true,
    ...params,
  });

  // 1remove
  const deletBut = BearTextMedia({
    ...jiqew["Remove"],
    style: BearColors("white", "red"),
    className: "buttonHover expandHover",
  });

  // 1save

  // 1shuffle
  const shuflo = BearTextMedia({
    ...jiqew["Shuffle"],
    style: BearColors("white", "darkblue"),
    className: "buttonHover expandHover",
  });

  // 1bottom
  const left = shuflo;

  const center = jasdwqe;

  const right = deletBut;

  let floatboT = BearFloat({
    style: { marginTop: "50px" },
    left,
    fontSize: "1.4em",
    center,
    right,
  });

  const res =
    //
    // floatboT
    jasdwqe;

  return res;
}
