import { BearDiv } from "@sillyscribe95/bear-ui-web";
import { useContext } from "react"; //
import { MainContext } from "../../../context/MainContext";
import { PageContainer } from "../../PageContainer";
import { ChooseLangContainer } from "./ChooseLangContainer";

export function ChooseLangPage(aaaaa) {
  // 1history
  const {
    //
    match: { url, params },
    ...args
  } = aaaaa;

  const { langFinished, prevLink, fromName, toLang, fromLang } =
    useContext(MainContext);

  const baseargs = {
    ...params,
    url,
  };

  const ijerwe =
    //
    // "ASdfpwelr";
    // toLang + fromLang;
    // "";
    ChooseLangContainer(baseargs);
  // langFinished ? ChooseLangContainer(baseargs) : "";

  let aswew = PageContainer({
    children: BearDiv({
      obj: ijerwe,
      style: { padding: "0 16%" },
    }),
    waitUser: false,
    noChoose: true,
    closeRight: true,
    ...baseargs,
  });

  return aswew;
}
