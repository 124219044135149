import React, { createContext, useRef, useState } from "react";
import { BearAuthFetch } from "../components/bear/BearAuthFetch";
import { getStorage } from "../functions/mainFuncs";

import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import { bearlog, BearLower } from "@sillyscribe95/bear-ui-web";
import { testuser } from "../const/testConst";
import { getGenderBase } from "../functions/genderFuncsFront";
import { genderRepsDict } from "../const/genderConst";
import { prepVerbFunc } from "../functions/verbFuncsFront";

export const MainContext = createContext();

const MainContextProvider = (props) => {
  //   1const

  //
  const [tim, settim] = useState();

  const [adsfa, setUserObj] = useState();

  // 1auth
  const authObj =
    //
    firebase;
  // firebase.firestore()
  const { userDetails, loading, error } =
    //
    // "";
    adsfa
      ? adsfa
      : BearAuthFetch({
          firebase: authObj,
          fetchCollection: "users",
        });

  const userLoad =
    //
    // "";
    loading;

  // 1user
  const userObj =
    //
    // "";
    testuser;
  // userDetails;

  // 1test
  const testTrue =
    //
    // "asqewe";
    "";
  //

  function testCheck(bitOne, bitTwo) {
    return testTrue ? bitOne : bitTwo;
  }

  // 1form
  const [viewType, setviewType] = useState();
  const [countGo, setcountGo] = useState();

  // 1lang
  const [tolgon, settoLang] = useState();
  const [fmgon, setfromLang] = useState();
  const fromLang = fmgon;
  const toLang = tolgon;

  const inpref = useRef(null);

  const langFinished = toLang && fromLang;
  const bothLang = { toLang, fromLang };

  const [langCodeDict, setlangCodeDict] = useState({});
  const [fmN, setfromName] = useState();
  const [tNm, settoName] = useState();

  const fromName = langCodeDict[fromLang];
  const toName = langCodeDict[toLang];

  const [slowTrue, setslowTrue] = useState();
  const [showGender, setshowGender] = useState();
  const [genderType, setgenderType] = useState("neut");

  const [infoView, setinfoView] = useState();

  const [transid, settransid] = useState();
  const transidTitle = transid && BearLower(transid);

  const baseData = {
    ...bothLang,
    transid,
  };

  // 1roman
  const romInit = getStorage("romTrue", true);
  const [romTrue, setromTrue] = useState(romInit);

  // 1play
  const [playTrue, setplayTrue] = useState();
  const [playSpeed, setplaySpeed] = useState(1);

  // 1loop
  const [loopTrue, setloopTrue] = useState(false);

  const [voiceCurr, setvoiceCurr] = useState();

  const [currConj, setcurrConj] = useState("Present");
  const [verbBaseaar, setcurrVerbArr] = useState();
  const currVerbArr = verbBaseaar
    ? prepVerbFunc(verbBaseaar, { showGender, currConj, ...bothLang })
    : "";

  // 1console
  const baseScript = {
    base: {
      //   hereo: hereo,
    },
    user: {
      userObj,
      userLoad,
    },
    form: {
      //
    },
  };

  const okwe =
    //
    "user";

  const osdre = okwe ? baseScript[okwe] : baseScript;

  bearlog.lug(`___ osdre ___`, osdre);

  const xvokbcv = {
    romTrue,
    setromTrue,
    slowTrue,
    setslowTrue,
    showGender,
    setshowGender,
    //
    //
    // 1lang
    bothLang,
    toLang,
    settoLang,
    fromLang,
    setfromLang,
    fromName,
    setfromName,
    toName,
    settoName,
    langCodeDict,
    setlangCodeDict,
    //
    //
    countGo,
    setcountGo,
    tim,
    settim,
    viewType,
    setviewType,
    testCheck,

    // 1verbs
    currConj,
    setcurrConj,
    currVerbArr,
    setcurrVerbArr,
    ...currVerbArr,
    genderType,
    setgenderType,
    //
    // 1play
    playTrue,
    setplayTrue,
    playSpeed,
    setplaySpeed,
    loopTrue,
    setloopTrue,
    langFinished,
    voiceCurr,
    setvoiceCurr,
    //
    inpref,
    // FORM
    //
    // 1user
    userObj,
    setUserObj,
    userLoad,
    //
    // 1transid
    transid,
    settransid,
    transidTitle,
    baseData,
    //
    infoView,
    setinfoView,
    //
  };

  let isfas = {
    value: xvokbcv,
  };

  // 1return 1end
  return (
    <MainContext.Provider {...isfas}>{props.children}</MainContext.Provider>
  );
};

export default MainContextProvider;
