import React, { useContext } from "react"; //
import {
  //
  bearlog,
  BearDiv,
  BearFloat,
} from "@sillyscribe95/bear-ui-web";
import { RomanConvert } from "../components/RomanConvert";
import { VerbActions } from "../components/VerbActions";
import { MainContext } from "../context/MainContext";
import { TextWithSpeech } from "./TextWithSpeech";
import { CoolLink } from "../components/GlobalComps";
import { testTrue } from "../const/mainConst";

export function TitleComp({
  transText,
  titleConfig,
  titleLink,
  origText,
  showButtons,
  transid,
  actionConfig = {},
  ...asdf
}) {
  const { currVerbArr, fromLang, toLang } = useContext(MainContext);

  bearlog.lug(`___ currVerbArr ___`, currVerbArr);

  const { verb, transTitle } = currVerbArr;
  const ijewwas = RomanConvert(transText);

  const kadsfre = (
    <>
      {origText} - {ijewwas}
    </>
  );

  const newae = TextWithSpeech(kadsfre, {
    padvar: "10px",
    text: transText,
  });

  const endos = BearFloat({
    center: newae,
    link: titleLink,
    ...titleConfig,
  });

  const playText =
    //
    // transText
    "";

  actionConfig = {
    playText,
    transid,
    ...actionConfig,
  };

  const dsaijewr =
    //
    // "";
    testTrue && CoolLink({ verb: transTitle, toLang });
  // VerbActions({ verb });
  // VerbActions(actionConfig);
  // showButtons && VerbActions(actionConfig);

  const naifewrw = BearFloat({
    left: endos,
    // center: endos,
    right: dsaijewr,
    fontSize: "18px",
    ...asdf,
  });

  const jsfdger =
    //
    naifewrw;
  // endos;

  return jsfdger;
}
