import React from "react"; //
import {
  //
  bearlog,
  BearDiv,
  BearNavigation,
  BearTextMedia,
  BearList,
  BearBackBorder,
  BearBorder,
} from "@sillyscribe95/bear-ui-web";
import { FaUserAlt } from "react-icons/fa";
import { linkPlus } from "../functions/linkFuncs";
import { getLangBase } from "../containers/translate/getLangFuncs";

export function BearUserNav({
  plusTrue,
  loading,
  user,
  navConfig,
  signedInConfig,
  userButtonConfig,
}) {
  function Userbo() {
    const { name, hasPlus } = user;

    const usImteo = BearTextMedia(userButtonConfig);

    const style = {
      ...BearBackBorder("black", "5px"),
      color: "white",
      // padding: "0 10px",
    };
    const showPlus =
      //
      // "";
      !hasPlus;

    const frwef =
      showPlus &&
      BearDiv({
        style,
        obj: getLangBase("plusButton"),
        link: linkPlus(),
      });

    return BearList({
      list: [frwef, usImteo],
      bearName: "dsfdfewr",
      typeList: "return",
      spaceBetween: "10px",
      noShowItems: true,
      horiz: true,
      ...signedInConfig,
    });
  }

  const idjase = {
    bearName: " 'gdert",
    ignoreEmpty: true,
    // itemConfig
    ...navConfig,
  };

  const asdfre = loading ? Userbo() : user ? Userbo() : BearNavigation(idjase);

  return asdfre;
}
