import React from "react";
import { Redirect, Route } from "react-router-dom";

export function BearPrivateRoute({
  yesRedirect,
  noRedirect,
  noComponent,
  yesComponent,
  userObj,
  userLoad,
  userAttribute,
  ...rest
}) {
  function asdfew(mmess, linko, Component, props) {
    return linko ? (
      <Redirect to={{ pathname: linko, state: { from: props.location } }} />
    ) : (
      <Component {...props} />
    );
  }

  const showObj = userObj && userAttribute ? userObj[userAttribute] : userObj;

  return (
    <Route
      {...rest}
      render={(props) =>
        userLoad
          ? ""
          : showObj
          ? asdfew("yes", yesRedirect, yesComponent, props)
          : asdfew("no", noRedirect, noComponent, props)
      }
    />
  );
}
