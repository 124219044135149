import { BearList, bearlog } from "@sillyscribe95/bear-ui-web";
import { useContext } from "react";
import { mobView } from "../const/mainConst";
import { MainContext } from "../context/MainContext";
import { rendGen } from "../functions/genderFuncsFront";
import { GenderButton } from "./GenderButton";
import { TransBlock } from "./TransBlock";

export function VerbChunk() {
  // 1const
  const { currVerbArr, fromLang } = useContext(MainContext);

  // 1container
  function asgder(bxc) {
    bearlog.lug(`___ asgder ___`, bxc);

    return vcbkdof(bxc);
  }

  function vcbkdof({ transPlusGender, origText, transText }) {
    const origTrans =
      //
      origText;
    // rendGen(origText, fromLang, genderType);

    origText =
      //
      origText;
    // GenderButton(origText);

    // 1gender
    transText =
      //
      transText;
    // transPlusGender;
    // showGender ? GenderConvert(transPlusGender, toLang) : transText;

    // 1showtranslate VERB
    const showTranslateLink =
      //
      ["en"].includes(fromLang);

    const sdfwas = {
      origText,
      origTrans,
      transText,
      showTranslateLink,
    };

    const okdsa = TransBlock(sdfwas);

    return okdsa;
  }

  const { list } = currVerbArr;

  const res = BearList({
    // ...args,
    list,
    bearName: "sad",
    renderItem: asgder,
  });

  return res;
}
