import {
  BearList,
  //
  bearlog,
} from "@sillyscribe95/bear-ui-web";
import React, { useContext } from "react";
import { BearNetlifyFetch } from "../../components/bear/BearNetlifyFetch";
import { EmptyResult } from "../../components/EmptyResult";
import { TransBlock } from "../../components/TransBlock";
import { MainContext } from "../../context/MainContext";
export function CountryContainer() {
  const { langFinished, bothLang } = useContext(MainContext);

  const params = { bothLang };
  const { data, isLoading } =
    //
    BearNetlifyFetch("getCountryData", { params });
  // !langFinished ? "" : BearNetlifyFetch("getCountryData", bothLang);

  const dataBest = data;

  bearlog.lug("___ dataBest ___", dataBest);

  //   1config
  const nsdifjr = {
    //
    // bott
  };

  function rwerwe(zxcvd) {
    const sdfkower = {
      showWiki: true,
      ...zxcvd,
      ...nsdifjr,
    };

    const rreas =
      //
      //   "adsflwe";
      TransBlock(sdfkower);

    return rreas;
  }

  function rendNoCont(sdkqwe) {
    const nsjdae =
      //
      "Can't find your country?";
    // `Want to add "${sdkqwe}"?`

    return EmptyResult({
      searchType: "country",
      title: nsjdae,
    });
  }

  if (isLoading) {
    return "loading";
  }

  if (dataBest) {
    const xcvsdfg = {
      bearName: "search name",
      list: dataBest,
      searchAttrs: ["origText"],
      renderItem: rwerwe,
      noOptionsMessage: rendNoCont,
      menuHeight: "60vh",
      // initialValue: "oaeaewa",
      // inputValue: "oaeaewa",
      // width: "80vw",
      // itemConfig:
      placeholder: "Choose a component",
    };

    const sdiasjwe =
      //
      // BearSelect(xcvsdfg);
      // BearSearchList(xcvsdfg);
      BearList(xcvsdfg);

    const okfdsd = (
      <>
        {/*  */}
        {sdiasjwe}
      </>
    );

    return okfdsd;
  }
}
