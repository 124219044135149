import { BearInputSubmit } from "@sillyscribe95/bear-ui-web";
import { useContext } from "react";
import { MainContext } from "../../context/MainContext";
export function PracticeSubmitInput({
  transArray,
  onProgress,
  chooseOptions,
  onChange,
  origString,
  onSubmit,
  failTrue,
  resultString,
  inputType,
  correctResult,
  inputConfig,
}) {
  const { practiceAnswer } = useContext(MainContext);

  let inopt;
  switch (inputType) {
    case "select":
      inopt = {
        menuHeight: "200px",
        onChangeValue: onSubmit,
        width: "250px",
      };
      break;
    default:
      inopt = {
        //   value: adfkw,
        //   onChangeValue: setadfkw,
        onSubmit: adsfwe,
      };

      function adsfwe(ewrewsdf) {
        ewrewsdf = ewrewsdf && ewrewsdf.trim();
        onSubmit(ewrewsdf);
      }
  }

  const confor = {
    ...inopt,
    inputType,
    autocomplete: "off",
    ...inputConfig,
  };

  const fdwewae =
    //
    // BearForm
    BearInputSubmit(confor);

  let aeokqw = fdwewae;

  return aeokqw;
}
