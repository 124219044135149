import {
  BearDiv,
  BearFloat,
  BearList,
  bearlog,
} from "@sillyscribe95/bear-ui-web";
import { useContext } from "react";
import { CoolLink } from "../../components/GlobalComps";
import { RomanConvert } from "../../components/RomanConvert";
import { TranslateOpen } from "../../components/TranslateOpen";
import { TransWordHover } from "../../components/TransWordHover";
import { VerbActions } from "../../components/VerbActions";
import { mobView } from "../../const/mainConst";
import { MainContext } from "../../context/MainContext";
import { verbLink } from "../../functions/linkFuncs";
import { butActive } from "../../functions/mainFuncs";
import { SpeechContainer } from "../SpeechContainer";
import { TextWithSpeech } from "../TextWithSpeech";
import { PracticeContext } from "./PracticeContext";
import { PracticeDeleteButton } from "./PracticeDeleteButton";

export function PracticeItemTop(config) {
  const { bothLang, toLang, transid } = useContext(MainContext);
  const { practiceLevel, swapTrue, currentPracticeItem } =
    useContext(PracticeContext);

  let {
    //
    origArray,
    transArray,
    transBaseData,
    transString,

    speechString,
    origString,
  } = currentPracticeItem;

  //   1trans
  const mapBase =
    //
    transArray;
  // [transString];

  const hovTrue =
    //

    "";
  // hoverTrue && mapBase;
  // "asd";

  const transios =
    //
    // "";
    // transBaseData;
    // mapBase.map(rendios);
    RomanConvert(transString);
  // hovTrue ? mapBase.map(rendios) : RomanConvert(transString);
  // RomanConvert(mapBase.join(" "));

  function rendios(text, indox) {
    const origText = origArray[indox];

    return TransWordHover({
      origText,
      text,
      // fontSize,
    });
  }

  const sdfvds = TextWithSpeech(transios, {
    text: origString,
    // fontSize: "20px",
    style: { padding: "10px" },
    className: "pointer",
    // iconvar: ijqeqw,
    // textvar: transios,
  });

  // 1actions
  const sdfewrs = VerbActions({
    // fontSize: "1em",
    // showWiki: true,
    // showWikitionary,
    // showTranslate: true,
    lang: toLang,
    // playText: transString,
    textBase: transString,
    transText: transString,
  });

  speechString = speechString ? speechString : transString;

  const speechBase = BearDiv({
    obj: SpeechContainer(speechString),
    fontSize: "2em",
  });

  // 1speech
  const spechi =
    //
    // sdfewrs
    speechBase;

  const showCl =
    //
    true;
  // BearURLParam("cool");

  function Linkos() {
    const coolo = CoolLink({
      toLang,
      verb: swapTrue ? origString : transString,
    });

    const transo = TranslateOpen(swapTrue ? transString : origString);

    return BearList({
      horiz: true,
      list: [coolo, transo],
      bearName: "aasd",
      typeList: "return",
    });
  }

  const coolo = showCl && !mobView && Linkos();

  // 1left
  const left =
    //
    // spechi
    sdfvds;
  // "";

  // 1center
  const center =
    //
    "";
  // sdfvds;
  // transios;

  // const showWikitionary =
  //   //
  //   true;
  // 1right
  const right =
    //
    // "";
    PracticeDeleteButton(transBaseData, { fontSize: "34px" });
  // coolo;
  // sdfewrs

  let obj = BearFloat({
    left,
    center,
    right,
    // style: { background: "red" },
  });

  obj = BearDiv({ ...config, obj });

  return obj;
}
