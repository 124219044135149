import {
  BearDiv,
  BearFixSide,
  BearFloat,
  BearList,
  bearlog,
  BearTextMedia,
  BearTitle,
} from "@sillyscribe95/bear-ui-web";
import React from "react"; //
import { FaUniversity, FaWikipediaW } from "react-icons/fa";
import { GiBrain } from "react-icons/gi";
import { mobView } from "../const/mainConst";
import { DashboardButton } from "../containers/DashboardButtons";
import { getLangBase } from "../containers/translate/getLangFuncs";
import { getLangOpp } from "../functions/languageFuncs";
import { linkPractice, linkStudy } from "../functions/linkFuncs";
import { getTypesFront } from "../functions/mainFuncs";
import { getGoogleLink } from "../functions/openGoogle";
import { getSearchConst } from "../search/searchConst";
import { BearAppStoreLink } from "./bear/BearAppStoreLink";
import { BearColors, getListDictArgs } from "./bear/BearGlobals";
import { RomanConvert } from "./RomanConvert";
import { TranslateOpen } from "./TranslateOpen";

export function RomanBelow(texto) {
  const sdfger = BearTitle({
    title: texto,
    subtitle: RomanConvert(texto),
  });

  const okfdsd = <>{sdfger}</>;

  return okfdsd;
}

export function AppStoreButt(config) {
  return BearAppStoreLink({
    ios: "adqweqw",
    android: "adsqweqw",
    home: "asdqwe",
    ...config,
  });
}

export function WikitionaryLink({ lang, text, ...asdf }) {
  const ertds =
    //
    // en.wiktionary.org/wiki
    `https://${lang}.wiktionary.org/wiki/${text}`;

  const sdfgera = BearTextMedia({
    iconvar: FaWikipediaW(),
    outsideLink: ertds,
    ...asdf,
  });

  return sdfgera;
}

export function WikiLink({ textBase, lang, ...asdf }) {
  const wikText = textBase;
  const ertds = `https://${lang}.wikipedia.org/wiki/${wikText}`;

  const sdfgera = BearDiv({
    obj: BearTextMedia({
      iconvar: FaWikipediaW(),
    }),
    outsideLink: ertds,
    ...asdf,
  });

  return sdfgera;
}

export function OrigBlock({
  showTranslateLink,
  origTrans,
  origText,
  ...sdssss
}) {
  origTrans = origTrans ? origTrans : origText;

  const kasdfe =
    //
    showTranslateLink;
  //   true;
  // !mobView;

  const asdfjwer = kasdfe && TranslateOpen({ text: origTrans });

  const center =
    //
    origText;

  return BearFloat({
    center,
    right: asdfjwer,
    ...sdssss,
  });
}

export function JoinLink({}) {
  //
}

export function FixDash(asdfdsa) {
  let right =
    //
    // "dsfokewrew";
    DashboardButton(asdfdsa);

  const asdfir = FixBase({
    ...asdfdsa,
    right,
  });

  const sdfewrw =
    //
    // right;
    asdfir;

  return sdfewrw;
}

// 1fix
export function FixBase(asdfdsa) {
  let { fixConfig, right } = asdfdsa;

  fixConfig = {
    // className: "shadowMain",
    fontSize: "24px",
    align: "center",
    className: "shadowMain",
    style: {
      top: "120px",
      padding: "0 20px",
      height: "360px",
      width: "320px",
    },
    ...fixConfig,
  };

  const { left } = asdfdsa;

  return mobView
    ? left
    : BearFixSide({
        mainWidth: "50vw",
        bearName: "sokqweq,",
        // fixConfig,
        ...asdfdsa,
        right,
        fixConfig,
      });
}

export function PracticeButton({ pageType = "", style, ...zxcv } = "") {
  let naisdfer;

  switch (pageType) {
    case "study":
      // 1save
      naisdfer = {
        textvar: "Sa",
        iconvar: FaUniversity(),
        outsideLink: linkStudy(zxcv),
      };
      break;
    case "practice":
      // naisdfer = {
      //   textvar: "Save",
      //   iconvar: AiOutlineSave(),
      //   link: "/dashboard",
      //   background: "green",
      // };
      break;

    default:
      const lbso = linkPractice(zxcv);
      naisdfer = {
        iconvar: GiBrain(),
        textvar: "Practice",
        link: lbso,
        // textvar:
      };
  }

  let sdfwerew = BearTextMedia({
    ...naisdfer,
    className: "buttonHover",
    style: {
      ...BearColors("white", "darkblue"),
      ...style,
    },
    ...zxcv,
  });

  return sdfwerew;
}

// export function

export function TopicList(params) {
  const base =
    //
    // "";
    getListDictArgs(getSearchConst());

  return BearList({
    bearName: "asdwqe",
    lineBetween: true,
    fontSize: "22px",
    ...base,
    ...params,
    // ...asdfasd,
  });
}

export function CoolLink({ toLang, verb }) {
  const lang = getLangOpp(toLang);
  const outsideLink =
    //
    `https://cooljugator.com/${lang}/` + verb;

  const bos = {
    outsideLink,
    obj: "C",
  };

  return BearDiv(bos);
}

export function BearGoogleTranslate({ text, from, to, home, ...bbbb }) {
  return BearDiv({
    outsideLink: getGoogleLink({ trans: text, home, from, to }),
    ...bbbb,
  });
}
