import { bearlog, BearLower } from "@sillyscribe95/bear-ui-web";
import { get, range, sample, shuffle } from "lodash";
import { BearListRemove } from "../../components/bear/BearGlobals";
import { getRangeMap } from "../../functions/bear/BearArrayFuncs";
import { removeAll } from "../../functions/mainFuncs";
import { getGenderBase } from "../../functions/genderFuncsFront";

// 1name
function namePractice({ previous, toLang, fromLang, level }) {
  const nameMeta = {
    // el: greekNameConst,
  }[toLang];

  const chooseOptions = ["Male", "Female"];

  let optBase = Object.keys(nameMeta);
  const randOpts = sample(optBase);

  const randGen = sample(chooseOptions);

  let list = get(nameMeta, `${randOpts}[${randGen}]`);
  list = BearListRemove(list, previous);

  const transString = sample(list);
  const correctResult = [randGen];

  return {
    transString,
    correctResult,
    chooseOptions,
    // inputConfig,
    // inputType: "select",
  };
}

// 1alphabet
function alphabetPractice({ toLang, fromLang, level }) {
  // cst
  // return {};
}

export function prepPracticeList({
  list,
  previous,
  removeList,
  currList,
  totalNumber = 6,
}) {
  //

  const mainList = removeAll(list, previous, removeList);
  const shuffleList = shuffle(mainList);
  const cutList = shuffleList.slice(0, totalNumber);

  const doneList =
    //
    // mainList;
    // shuffleList;
    cutList;

  const current = doneList && sample(doneList);
  currList = doneList;

  previous = current;

  return { currList, current };
}

export function practiceMapList({ totalNumber, list, genFunc }) {
  const mainData = {};

  bearlog.lug(`___ practiceMapLst aaaa ___`, { list });

  const basicList =
    //
    list ? list.map(genFunc) : getRangeMap(totalNumber, genFunc);
  // list.map(genFunc);

  basicList.map(function (data) {
    //

    bearlog.lug(`___ data ___`, data);

    const { transString } = data;
    mainData[transString] = data;
  });

  const resso =
    //
    // basicList
    mainData;

  bearlog.lug(`___ practiceMapLst bbb ___`, resso);

  return resso;
}

export function getRandomPractice(data, previous) {
  const list = Object.keys(data);
  const removed = previous ? removeAll(list, previous) : list;
  const randLis = data[sample(removed)];
  return randLis;
}

export function mapParsePrac(data, base = {}) {
  bearlog.lug(`___ mapParsePrac ___`, { data, base });

  const { transString } = data;
  const transBaseData = transString;

  data = {
    transBaseData,
    ...data,
  };

  const { transid } = base;

  bearlog.lug(`___ transid ___`, transid);

  switch (BearLower(transid)) {
    case "conjugations":
    case "conjugations-list":
      data = mapVerbPracParse(data, base);
      break;
    default:
  }

  return data;
}

// 1gender
export function mapVerbPracParse(data, base) {
  bearlog.lug(`___ verbsPracParse aaaa `, { data, base });
  let { transid, toLang, fromLang, swapTrue } = base;

  let { correctResult, origString, transString, chooseOptions } = data;

  const frlang = swapTrue ? toLang : fromLang;
  const tlang = swapTrue ? fromLang : toLang;

  function convorig(s) {
    return getGenderBase(s, frlang);
  }
  function convtrans(s) {
    return getGenderBase(s, tlang);
  }

  origString = convorig(origString);
  transString = convtrans(transString);

  correctResult = correctResult.map(convorig);
  chooseOptions = chooseOptions.map(function ({ origText, transText }) {
    origText = swapTrue ? convtrans(origText) : convorig(origText);
    transText = swapTrue ? convorig(transText) : convtrans(origText);

    const asdf = { origText, transText };

    bearlog.lug(`___ asdf ___`, asdf);

    return asdf;
  });

  const extDone = {
    origString,
    transString,
    chooseOptions,
    correctResult,
  };

  bearlog.lug(`___ extDone ___`, extDone);

  data = {
    ...data,
    ...extDone,
  };

  return data;
}

export function checkPracticeAnswer(resulto, correctResult) {
  const refVale =
    //
    "";
  // get(inpref, `current`);

  const asfwe =
    //
    resulto && BearLower(resulto);
  // "";
  // refVale ? refVale.value : asdfe;

  const basRight =
    //
    // correctResult == asfwe
    correctResult.includes(asfwe);

  bearlog.lug(`___ onSubmtPractice ___`, {
    resulto,
    correctResult,
    basRight,
  });

  return { answer: asfwe, correct: basRight };
}
