import { BearBackBorder, BearDiv, BearList } from "@sillyscribe95/bear-ui-web";
import axios from "axios";
import { compact, uniq, without } from "lodash";
import React, {
  //
  useState,
} from "react";
import { removeString } from "../../components/bear/BearGlobals";
import { BearListChunk } from "../../components/bear/BearListChunk";
import { BearSpeechAPI } from "../../components/bear/BearSpeechApi";
import { BearTextToSpeech } from "../../components/bear/BearTextToSpeech";
import { LanguageName } from "../../components/LanguageName";
import { testGreeKpresent } from "../../const/testConst";
import { voiceConst } from "../../const/voiceConst";
import { getLangChoose } from "../../functions/languageFuncs";
import { getAdjective } from "../adjectives/adjectiveFuncs";
import { PageContainer } from "../PageContainer";
import { Testdata } from "./Testdata";

export function Testlanguages(aaaaa) {
  // 1const
  // 1const
  const {
    //
    match: { url, params },
    ...args
  } = aaaaa;

  // 1voice
  function voicetype() {
    const lenttho = voiceConst.map(({ lang }) => lang.substring(0, 2));
    const nasde = uniq(lenttho);

    const toLang = "el";
    const list = getLangChoose({ toLang });
    function renderItem(code) {
      return (
        <div>
          {code}: {LanguageName(code)}
        </div>
      );
    }
    const asjwe = BearList({
      renderItem,
      list,
      bearName: "asdweq",
    });

    return (
      <>
        <div>length: {nasde.length}</div>
        <div>langs: {nasde.map((item) => `"${item}",`)}</div>
        {asjwe}
      </>
    );
  }

  const sdif = voicetype();

  return sdif;
}
