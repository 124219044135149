import { BearButtonList, bearlog } from "@sillyscribe95/bear-ui-web";
import { useContext } from "react"; //
import { AiFillInfoCircle, AiOutlineInfoCircle } from "react-icons/ai";
import { FaSave, FaUniversity } from "react-icons/fa";
import { ImLoop } from "react-icons/im";
import { SpeechContainer } from "../containers/SpeechContainer";
import { MainContext } from "../context/MainContext";
import { linkPractice } from "../functions/linkFuncs";
import { butActive } from "../functions/mainFuncs";
import { WikiLink, WikitionaryLink } from "./GlobalComps";
import { TranslateOpen } from "./TranslateOpen";

export function VerbActions({
  verb,
  itemConfig,
  textBase,
  playText,
  playAll,
  practiceLink,
  transid,
  dictvar,
  speechConfig,
  lang,
  showTranslate,
  showWiki,
  showWikitionary,
  ...dddd
}) {
  const { infoView, setinfoView, currVerbArr, fromLang, toLang, userStore } =
    useContext(MainContext);

  const { transText } = dddd;
  const qweas =
    //
    "";
  // userStore.verb;

  const [saveTreu, setsaveTreu] =
    //
    ["", ""];
  // useState(qweas);

  function saveLang() {
    setsaveTreu(true);
  }

  const sdfwer =
    //
    playText;
  // "textcon";

  // 1play 1speech
  function Speecho(zxcvfs) {
    const [gdfoe, setgdfoe] =
      //
      ["", ""];
    // useState();

    const sdjiwer = gdfoe && {
      style: {
        background: "black",
        color: "white",
      },
      //
    };
    const jsfrw = SpeechContainer(zxcvfs, {
      // ...sdjiwer,
      className: "",
      // playTrue: gdfoe,
      // onPlay: () => setgdfoe(true),
      // onStop: () => setgdfoe(false),
      ...speechConfig,
    });

    return jsfrw;
  }

  // 1wiki

  // 1trans
  const nadsfer = TranslateOpen({
    from: toLang,
    to: fromLang,
    home: fromLang,
    text: textBase,
  });

  // 1info
  const asdfiew =
    //
    AiOutlineInfoCircle();
  // AiFillInfoCircle()

  function infClick() {
    bearlog.lug(`___ infClick ___`);
    // infoView,
    setinfoView(!infoView);
  }

  const info = {
    iconvar: asdfiew,
    onClick: infClick,
    ...butActive(infoView),
  };

  const jirwsa = {
    info,
    wikitionary: {
      // textvar: "Play All",
      iconvar: WikitionaryLink({
        lang: fromLang,
        text: transText,
      }),
    },

    wiki: {
      // textvar: "Play All",
      iconvar: WikiLink({ lang: toLang, textBase }),
    },
    play: {
      textvar: "Play All",
      iconvar: Speecho(sdfwer),
    },
    playAll: {
      iconvar: SpeechContainer(playAll, {
        displayText: ImLoop(),
      }),
    },
    practice: {
      link: linkPractice({ transid, verb, fromLang, toLang }),
      textvar: "Practice",
      iconvar: FaUniversity(),
    },
    translate: {
      iconvar: nadsfer,
    },
    save: {
      style: saveTreu && {
        color: "white",
        background: "green",
      },
      onClick: saveLang,
      textvar: "Save",
      iconvar: FaSave(),
    },
    ...dictvar,
  };

  itemConfig = {
    // className: "buttonHover",
    noText: true,
    style: {
      // ...BearBorder("darkblue", "5px"),
    },
    ...itemConfig,
  };

  // 1list
  const fdjgwe = [
    //
    showWikitionary && "wikitionary",
    showWiki && "wiki",
    playText && "play",
    playAll && "playAll",
    showTranslate && "translate",
    practiceLink && "practice",
    // "save",
  ];

  // 1console

  const iasjdqwe = BearButtonList({
    bearName: "asoeqw",
    list: fdjgwe,
    horiz: true,
    dictvar: jirwsa,
    ignoreEmpty: true,
    itemConfig,
    ...dddd,
  });

  return iasjdqwe;
}
