import { BearList } from "@sillyscribe95/bear-ui-web";
import { useContext } from "react"; //
import { TransBlock } from "../../components/TransBlock";
import { MainContext } from "../../context/MainContext";
// import { translateJoined } from "../../functions/translateFront";
import { numToWords } from "./numberFuncs";

export function NumberFetch({ lsto, from, to, icr }) {
  const { fromLang, toLang } = useContext(MainContext);

  const typeFirst =
    //
    "";

  const sdrqq =
    //
    lsto;

  function dfgewer(sdfesd) {
    const asfwe = numToWords(sdfesd, toLang);
    const sdfger = numToWords(sdfesd, fromLang);
    return { origNum: sdfesd, origText: sdfger, transText: asfwe };
  }

  const { data, isLoading } =
    //
    "";
  //   useQuery("number", werwe);

  async function werwe() {
    const fdsgre = sdrqq.map((aaa) => numToWords(aaa, fromLang));
    //

    const dfewrwe =
      //
      "";
    // await translateJoined(fdsgre, fromLang, toLang);

    return dfewrwe;
  }

  //   1list
  const dfewqew =
    //
    // data;
    sdrqq.map(dfgewer);

  // 1render
  function adsfr(cvvcb) {
    return dsfg(cvvcb);
  }

  function dsfg({ origNum, origText, transText }) {
    const asdfie =
      //
      origNum.toString();
    //   origNum ? origNum.toString() : origText;

    const xcvsd = {
      origConfig: {
        style: {
          //   ...BearBorder("red"),
          minWidth: "10vw",
        },
      },
      transConfig: {
        style: {
          //   ...BearBorder("black"),
          minWidth: "70vw",
        },
      },
    };

    return TransBlock({
      showTranslateLink: true,
      origText: asdfie,
      origTrans: origText,
      transText,
      speechConfig: { fontSize: "100px" },
      // ...xcvsd,
    });
  }

  const sdfers =
    //
    BearList({
      style: { margin: "0 10%" },
      bearName: "Sdasd",
      list: dfewqew,
      // typeList: "return",
      renderItem: adsfr,
    });

  return sdfers;
}
