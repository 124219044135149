// 1greece

export const countrydata = {
  // en: englandcountry,
  // de: germanyconst,
  // es: countryiesSpanish,
  // fr: countriesFrench,
  // it: countriesItalian,
  // "zh-CN": countriesChinese,
  // el: greekCountry,
};
