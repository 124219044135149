export const spanishTransConst = {
  Countries: "Países",
  Names: "Nombres",
  Numbers: "Números",
  Alphabet: "Alfabeto",
  Transliterate: "Transcribir",
  Gender: "Género",
  Verbs: "Verbos",
  Verb: "Verbo",
  Pronoun: "Pronombre",
  Speed: "Velocidad",
  Loop: "Repetir",
  Present: "Presente",
  Past: "Pasado",
  Future: "Futuro",
  plusButton: "Mejorar",
  // plusBanner: "",
};

export const greekVerbs = {
  //
};
