import {
  BearDiv,
  BearHeight,
  BearTitle,
  BearURLParam,
} from "@sillyscribe95/bear-ui-web";
import React, { useContext, useState } from "react";
import { BearPageWithStripeCheckout } from "../../components/bear/BearPageWithStripeCheckout";
import { CurrencyBase } from "../../components/CurrencyBase";
import { mobView } from "../../const/mainConst";
import { MainContext } from "../../context/MainContext";
import { AuthBase } from "../auth/AuthBase";
import { AuthWrapWithLower } from "../auth/AuthWrapWithLower";
import { PageContainer } from "../PageContainer";
import { getLangBase } from "../translate/getLangFuncs";
import { CheckoutMain } from "./CheckoutMain";
import { UpgradeBenefits } from "./UpgradeBenefits";
import { UpgradeItemParse } from "./UpgradeItemParse";

export function UpgradeContainer(aaaaa) {
  // 1const
  const params = aaaaa;

  // 1roman
  const { toName, userLoad, userObj } = useContext(MainContext);

  const totalPayment =
    //
    5000;

  // 1const
  const currenBase =
    //
    userObj ? CurrencyBase(totalPayment) : "";

  const { topBit, bottomBit } = UpgradeItemParse({ currenBase });

  const asdfre = bottomBit;

  // 1subtitle

  const dsewsw = BearDiv({
    fontSize: "26px",
    obj: topBit,
  });

  // 1left
  const left = (
    <>
      {dsewsw}
      {BearHeight(40)}
      {asdfre}
    </>
  );

  let dfask = BearURLParam("auth");
  dfask = dfask ? dfask : "signup";
  // const [dfask, setdfask] = useState("signup");
  const sdfwqw = {
    signin: "Sign In",
    signup: "Sign Up",
  }[dfask];

  //
  function authCahng(adf) {
    // setdfask(adf)
    var reffo = new URL(window.location);
    reffo.searchParams.set("auth", adf);
    const zxafs = reffo.href;

    window.location = zxafs;
  }

  // 1failure
  async function onSuccess(asdfwe) {}

  // 1success
  async function onFailure(zcxvsdf) {}

  const ssvsd = getLangBase("plusPayTit");

  const costbos = currenBase && `: ${currenBase}`;
  const sadfeewq = (
    //
    <>
      {getLangBase("cardPay")}
      {costbos}
    </>
  );
  // `Lifetime access for ${currenBase}`,

  // 1title
  const basicTitle = BearTitle({
    title: ssvsd,
    subtitle: sadfeewq,
    fontSize: "28px",
    style: { marginBottom: "30px" },
  });

  // 1signin 1auth

  function RendAuth() {
    // 1auth

    const baseSucc = {
      // onSuccess,
      // onFailure,
    };

    return AuthBase(baseSucc);
  }

  const autho = BearTitle({
    style: { marginTop: "10vh" },
    fontSize: "24px",
    title: getLangBase("plusAuthJoin"),
    subtitle: RendAuth(),
  });

  const title = BearTitle({
    //
    title: basicTitle,
    // subtitle: autTitle,
  });

  function renderMobile(CheckoutItem) {
    CheckoutItem = BearDiv({
      obj: CheckoutItem,
      className: "shadowMain",
      style: { padding: "4vw" },
    });

    return BearDiv({
      obj: (
        <>
          {dsewsw}
          {BearHeight(40)}
          {CheckoutItem}
          {BearHeight(80)}
          {asdfre}
        </>
      ),
      style: { padding: "0 30px" },
    });
  }

  const mobileTrue = mobView;

  const checkoutConfig = CheckoutMain({
    title,
    totalPayment,
    // renderCheckout
    //
  });

  function basRnd(chkitem) {
    const loadCHeko =
      //
      // chkitem;
      // showMe ? chkitem : ClickShow();
      userLoad ? "" : userObj ? chkitem : autho;

    return loadCHeko;
  }

  function renderCheckout(checkCba) {
    const res =
      //
      // "Afoweqrqwe";
      basRnd(checkCba);

    return res;
  }

  // 1checkout
  const checkOn = BearPageWithStripeCheckout({
    mobileTrue,
    mainItem: left,
    renderMobile,
    checkoutConfig,
  });

  const children =
    //
    // loadCHeko;
    checkOn;

  return children;
}
