import { bearlog } from "@sillyscribe95/bear-ui-web";
import {
  ArrayParam,
  NumberParam,
  StringParam,
  useQueryParams,
} from "use-query-params";
import { without } from "lodash";
import { abstractGender } from "../../functions/genderFuncsFront";

export function practiceGetVerbsConjugated(aaaa) {
  bearlog.lug("___ gVerbsConjugated aaaa ___", aaaa);

  // 1const

  const dicto = {};

  let { mapPronouns, mapVerbs, adjMetaTo, metaDict, verb, toLang } = aaaa;

  const lengtBase =
    //
    mapVerbs;
  //   mapVerbs.length > 0;

  function checkPronoun(string) {
    const { gender } = abstractGender(string);
    const truego = mapPronouns == gender;
    return truego;
  }

  mapVerbs = lengtBase ? [mapVerbs] : without(Object.keys(adjMetaTo), "level");
  //   mapVerbs = ["Past"];

  bearlog.lug(`___ mapVerbs ___`, mapVerbs);

  mapVerbs.map(onDone);
  function onDone(conj) {
    const mappo = adjMetaTo[conj];

    bearlog.lug(`___ mappo ___`, mappo);

    if (mappo) {
      Object.entries(mappo).map(function ([key, value]) {
        bearlog.lug(`___ [key, value ___`, { key, value });

        const righto = mapPronouns ? checkPronoun(key) : true;

        if (righto) {
          //
          dicto[key] = value;
        }
      });
    }
  }

  return dicto;
}
