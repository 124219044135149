import { BearForm } from "@sillyscribe95/bear-ui-web";

export function WatchForm(aaaaa) {
  // 1const
  const {
    //
    match: {
      params: { slugURL, typeTab },
    },
    ...args
  } = aaaaa;

  const description = { inputType: "textvar" };

  const countries = {
    inputType: "select",
    optionsArray: ["France", "Scotland"],
  };

  const dictvar = {
    description,
    countries,
  };

  const list = [
    //
    "name",
    "description",
    "countries",
  ];

  const inputStyle = {
    // background: "red",
    // color: "white",
    margin: "20px 0",
  };

  const inputConfig = {
    style: { background: "" },
    // className: "shadowMain",
  };

  const okfdsd = BearForm({
    bearName: "cinides",
    // inputConfig,
    inputStyle,
    dictvar,
    list,
  });

  return okfdsd;
}
