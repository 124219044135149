import {
  BearDiv,
  BearFloat,
  bearlog,
  BearTextMedia,
} from "@sillyscribe95/bear-ui-web";
import React, { useContext } from "react";
import { GiDiamonds } from "react-icons/gi";
import { BaseTitle } from "../../components/BaseTitle";
import { BearObjectLength, BearPass } from "../../components/bear/BearGlobals";
import { RomanButton } from "../../components/RomanButton";
import { mobView } from "../../const/mainConst";
import { MainContext } from "../../context/MainContext";
import { PracticeContext } from "./PracticeContext";

export function PracticeItemTitle(args) {
  bearlog.lug(`___ PracticeItemTitle ___`, args);

  const { toName } = useContext(MainContext);
  const { adjMetaTo, userStreak = 0 } = useContext(PracticeContext);

  // 1const
  let { level, transid } = args;

  const ttBs =
    //
    BaseTitle({ toName, level, transid });
  // `${BearPass(toName)} ${level} (${transid})`;
  // `${BearPass(toName)} ${transid}: ${level}`;

  const titleos = BearDiv({
    obj: ttBs,
    bold: true,
  });

  const left =
    //
    // "3425wer";
    titleos;

  const baseStr =
    //
    // getLangBase("Streak");
    GiDiamonds();

  const listBase = BearObjectLength(adjMetaTo);

  const streakText = BearTextMedia({
    textvar: `${userStreak}`,
    iconvar: baseStr,
  });

  const right =
    //
    streakText;

  const sdkfgs = BearFloat({
    left,
    // center,
    right,
  });

  return (
    <>
      {sdkfgs}
      <hr />
    </>
  );
}
