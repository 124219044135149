import React, { useContext } from "react"; //
import {
  //
  bearlog,
  BearDiv,
  BearBackBorder,
} from "@sillyscribe95/bear-ui-web";
import { buildLink, linkAuth } from "../functions/linkFuncs";
import { MainContext } from "../context/MainContext";
import { BearUserNav } from "../components/BearUserNav";
import { AiFillPlusCircle } from "react-icons/ai";
import { FaUserAlt } from "react-icons/fa";
import { getLangBase } from "./translate/getLangFuncs";
import { getPlusConst } from "../const/plus-const";

export function UserSiteButtons({ showPlus = true, ...userConfig } = "") {
  const {
    userPaid,
    romTrue,
    setromTrue,
    setfromLang,
    settoLang,
    voiceCurr,
    setvoiceCurr,
    userObj,
    toLang,
    fromLang,
    userLoad,
    countGo,
    settransid,
    setcountGo,
  } = useContext(MainContext);

  const figjwws =
    //
    //
    // user
    ["sign in"];
  // ["sign up", "sign in"];
  // mobView ? ["sign in"] : ["sign up", "sign in"];

  const userButtonConfig = {
    fontSize: "40px",
    iconvar: FaUserAlt(),
    link: "/dashboard",
    // textvar: name[0],
    className: "buttonHover shadowMain",
    // style:sdfsw
  };

  const signedInConfig = {
    fontSize: "22px",
  };

  const plusist =
    //
    // "plus";
    showPlus && !userLoad && "plus";

  const akfrwe = {
    // link: linkAuth(),
    style: { ...BearBackBorder("black", "5px"), color: "white" },
  };

  const plus = getPlusConst();

  const navConfig = {
    dictvar: {
      "sign in": akfrwe,
      plus,
    },
    list: [plusist, "sign up"],
  };

  const sewew = BearUserNav({
    user: userObj,
    loading: userLoad,
    navConfig,
    userButtonConfig,
    signedInConfig,
    ...userConfig,
  });

  return sewew;
}
