import { BearList } from "@sillyscribe95/bear-ui-web";
import sortBy from "lodash.sortby";
import { getMetaFront } from "../../functions/getMetaFront";

export function DataListPage(aaaaa) {
  // 1const
  // 1const
  const {
    //
    match: { url, params },
    ...args
  } = aaaaa;

  const { type, level } = params;

  let list = getMetaFront(type, level);
  list = sortBy(list);

  const okfdsd = BearList({
    bearName: "aswe",
    list,
    typeList: "return",
  });

  const sdif = {
    url,
    ...params,
    children: okfdsd,
  };

  return okfdsd;
}
