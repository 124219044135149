import { bearlog } from "@sillyscribe95/bear-ui-web";
import { useContext, useEffect } from "react";
import { MainContext } from "../../context/MainContext";
import { PracticeContainer } from "./PracticeContainer";
import { PracticeContext } from "./PracticeContext";

export function PracticeContainerData(params) {
  const { transid } = params;

  // 1const
  const { romTrue } = useContext(MainContext);
  const {
    //
    setswapTrue,
    setmetaInfo,
    dataList,
    loadPracticeData,
  } = useContext(PracticeContext);

  // 1options

  // 1number 1list
  const totalNumber =
    //
    7;
  // 2;
  // listurl ? parseInt(listurl) : 7;

  // 1const
  bearlog.lug(`___ practData PARAM ___`, params);

  // const { data, isLoading } =
  //   //
  //   "";
  // // BearNetlifyFetch("getMetaDict", {
  // //   params,
  // //   onFetch,
  // // });

  const data = {
    ...params,
    romTrue,
    transid,
    totalNumber,
  };

  useEffect(() => {
    loadPracticeData(data);
  }, []);

  const endo = {
    ...params,
    dataList,
  };

  const reus =
    //
    // "anotehrbase";
    PracticeContainer(endo);

  return reus;
}
