import { IoMdList } from "react-icons/io";
import { IoSunnyOutline } from "react-icons/io5";
import { ImSortNumbericDesc } from "react-icons/im";

export const searchBase = {
  Nouns: {
    textvar: "Nouns",
  },
  Verbs: {
    verbTrue: true,
    textvar: "Verbs",
    iconvar: IoMdList(),
    // pracitceTrue: tre
  },
  // Prepositions: {
  //   textvar: "Prepositions",
  //   iconvar: ImArrowDownLeft(),
  // },
  Numbers: {
    textvar: "Numbers",
    iconvar: ImSortNumbericDesc(),
  },
  Conjugations: { textvar: "Conjugations" },
  // Alphabet: {
  //   textvar: "Alphabet",
  //   iconvar: TiSortAlphabetically(),
  //   noPractice: false,
  // },
  // Names: {
  //   textvar: "Names",
  //   iconvar: BsFillPeopleFill(),
  //   noPractice: false,
  // },

  // Countries: {
  //   textvar: "Countries",
  //   iconvar: MdOutlinePlace(),
  // },
  Adjectives: {
    textvar: "Adjectives",
    iconvar: IoSunnyOutline(),
  },
};

export const searchPlus = {
  // Phrases: {
  //   textvar: "Phrases",
  //   iconvar: ImSortNumbericDesc(),
  // },
};

export const searchAll = {
  ...searchPlus,
  ...searchBase,
};

export function getSearchConst(user) {
  const base = user?.hasPlus ? searchAll : searchBase;

  const dictvar =
    // ["Adjective"]
    base;

  const list = [
    //
    "Nouns",
    "Verbs",
    "Adjectives",
    "Numbers",
    "Conjugations",
  ];

  return { dictvar, list };
}
