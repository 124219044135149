import { BearBackBorder, BearDiv, BearFlex } from "@sillyscribe95/bear-ui-web";
import { useContext } from "react";
import { MdSettings } from "react-icons/md";
import { useHistory } from "react-router";
import { CountryShow } from "../components/CountryShow";
import { MainSiteButtons } from "../components/MainSiteButtons";
import { mobView } from "../const/mainConst";
import { MainContext } from "../context/MainContext";
import { SearchBar } from "../search/SearchBar";
import { PageDesktop } from "./PageDesktop";
import { PageMobile } from "./PageMobile";
import { getLangBase } from "./translate/getLangFuncs";

export function PageContainerMain(ccc) {
  let {
    //
    transid,
    url,
    metaTit,
    metaConfig,
    headerConfig,
    closeRight,
    noChoose,
    userConfig,
    waitUser,
    children,
    ...args
  } = ccc;

  const {
    userPaid,
    romTrue,
    setromTrue,
    setfromLang,
    settoLang,
    voiceCurr,
    setvoiceCurr,
    userObj,
    toLang,
    fromLang,
    userLoad,
    countGo,
    settransid,
    setcountGo,
  } = useContext(MainContext);

  // 1history
  let history = useHistory();

  const maewrwer = BearDiv({
    obj: MdSettings(),
    className: "buttonHover",
  });

  // 1center

  const logShow =
    //
    mobView;
  // false;
  // 1logotext
  const nsdfweas =
    //
    logShow
      ? BearDiv({
          obj: "🌐",
          fontSize: "1.3em",
        })
      : "🌐 Verbalize";

  const baseLink =
    //
    "/";
  // `/${buildLink({ toLang, fromLang })}`
  const irqwase = BearFlex({
    list: [
      //
      BearDiv({ obj: nsdfweas, link: baseLink }),
      // kerewq,
    ],
  });

  // 1left
  const left =
    //
    BearDiv({
      // style: { width: "200px" }
      obj: irqwase,
    });
  // "aokewqew";

  // 1close 1back
  const goi = { className: "expandHover pointer" };
  const jfewr = closeRight && {
    right: MainSiteButtons("Close"),
  };

  const width =
    //
    mobView ? "40vw" : "36vw";

  const center =
    //
    SearchBar({ width });

  // 1header
  const isdfer = {
    // logoText: "Verbalize",
    // navLeft: {
    //   list: ["about"],
    // },
    // ...idjase,
    //
    // logoText:
    //
    left,
    // navLeft: ijadqe,
    center,
    // navRight: idjase,
    ...jfewr,
    ...headerConfig,
  };

  const idswras = {
    //
    // background: "black",
    // color: "black",
  };

  children = waitUser ? (userLoad ? "" : children) : children;

  const nsdisdfew = {
    ...ccc,
    // setEmail,
    // setsetEmail,
    metaConfig: {
      siteName: "Verbalize",
      title: getLangBase(metaTit),
      ...metaConfig,
    },
    style: idswras,
    headerConfig: isdfer,
    // headerConfig,
    ...args,
    children,
  };

  const aslBa =
    //
    // PageMobile(nsdisdfew);
    // PageDesktop(nsdisdfew);
    mobView ? PageMobile(nsdisdfew) : PageDesktop(nsdisdfew);

  // 1children
  let oaske =
    //
    aslBa;
  // AllChoose();
  // countGo ? chosoe : aslBa;

  return oaske;
}
