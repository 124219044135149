import { BearDiv, BearFlex } from "@sillyscribe95/bear-ui-web";
import { useContext } from "react"; //
import { IoMdArrowDropdown } from "react-icons/io";
import { useHistory } from "react-router";
import { MainContext } from "../context/MainContext";
import { chooseLangLink } from "../functions/linkFuncs";

export function CountryShow({ type, url, ...sdwe }) {
  //   toLang = "gr";

  const { fromLang, toLang, fromName, toName } = useContext(MainContext);

  let lang;
  switch (type) {
    case "from":
      lang = fromName;
      break;
    case "to":
      lang = toName;
      break;
    default:
  }

  // lang = "en";

  let skoqwas =
    //
    toName;
  // getLangCode(fromLang, lang);
  // lang;
  // toLang;
  // getAlpha2Code(toLang);
  // mainLangCodes[lang];

  skoqwas =
    skoqwas &&
    //
    skoqwas.substring(0, 2).toUpperCase();
  // skoqwas.toUpperCase();

  // 1history
  let history = useHistory();

  //   i18n - iso - countries;

  const iqweqw =
    //
    "🌎";

  const qwawe = BearFlex({
    list: [
      //
      iqweqw,
      skoqwas,
      IoMdArrowDropdown(),
    ],
    padvar: "5px",
  });

  const fsdg = BearDiv({
    ...sdwe,
    obj: qwawe,
    onClick: akwer,
    className: "shadowMain buttonHover",
  });

  function akwer() {
    history.push({
      pathname: chooseLangLink({ fromLang, toLang }),
      state: {
        fromLang,
        toLang,
        // verb,
        url: window.location.pathname,
      },
    });
  }

  const wedfs =
    //
    fsdg;
  // iqweqw
  // qwawe

  return wedfs;
}
