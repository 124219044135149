// export function hasPlus(){

import { bearlog } from "@sillyscribe95/bear-ui-web";
import { BearBool } from "../components/bear/BearGlobals";
import { getStorage } from "./mainFuncs";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";

export async function changeUseLang(sue) {
  // sue
}

export async function getFirestore() {
  return await firebase.firestore();
}

export function getUserlang(useo, { fromLang, toLang }) {
  bearlog.lug(`___ getUserlng  aaa ___`, { useo, fromLang, toLang });

  function highGet(typeo, not) {
    const storBase =
      //
      // "el";
      getStorage(typeo);

    const res =
      //
      // get(user, typeo)
      // "he"
      BearBool(storBase, not);

    bearlog.lug(`___ highGet ___`, { storBase, res });

    return res;
  }

  // highGet("toLafng")
  // highGet("fromLang")

  // 1lang CHOOSE
  fromLang = fromLang ? fromLang : highGet("fromLang", "en");
  toLang = toLang
    ? toLang
    : highGet(
        "toLang",
        //
        "el"
        // "es"
      );

  bearlog.lug(`___ getUserlng  bbb ___`, { useo, fromLang, toLang });

  return { toLang, fromLang };
}

function getUserID(userObj) {
  const id =
    //
    // "asodkqwe";
    userObj.uid;
}
export async function getUserData(db, userObj, collection) {
  const id = getUserID(userObj);
  const adat = await db.collection(collection).doc(id);

  return adat;
}

export async function userAddData(db, userObj, data, { onSuccess, onFailure }) {
  const id = getUserID(userObj);

  const idBase = `${id}`;

  bearlog.lug(`___ userAddData aaa ___`, { db, userObj, id, data });

  db.collection("users").doc(idBase).set(data).then(onJoin).catch(onFooa);
  // firebase.database(idBase).ref().set(data).then(onJoin);

  function onJoin(data) {
    bearlog.lug(`___ userAddData SCCESS ___`, data);
    onSuccess(data);
  }

  function onFooa(data) {
    bearlog.lug(`___ userAddData FAILU ___`, data);
    // if ()
    // onFailure(data);
  }
}
