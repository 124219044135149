import { BearDiv, BearList, BearTextMedia } from "@sillyscribe95/bear-ui-web";
import { conjDict } from "../const/conjugateConst";
import { getLangBase } from "../containers/translate/getLangFuncs";
import { BearColors, getListDictArgs } from "./bear/BearGlobals";
import { BearListChunk } from "./bear/BearListChunk";

export function VerbConjugateList({ fontSize, onArrowClick, ...bbbc }) {
  // 1icons
  function IcButs({ shortName, iconvar }) {
    const adsfer = BearTextMedia({
      textvar: shortName,
      iconvar,
    });

    const fdfwe =
      //
      // "asdfasd";
      adsfer;
    // fkwerase

    return fdfwe;
  }

  function asdwwe(asdfew) {
    const { itemName, symbol, shortName, iconvar } = asdfew;
    // renderItem() =>
    let fkwerase =
      //
      // "";
      symbol;
    // shortName ? shortName : itemName;
    // asdfew
    // conjDict[asdfew].shortName;
    fkwerase = getLangBase(fkwerase);

    fkwerase =
      //
      // IcButs()
      BearDiv({ ...asdfew, obj: fkwerase });

    return fkwerase;
  }

  const jsqwaw = {
    textAlign: "center",
    ...BearColors("black", "lightblue"),
    // ...BearBackBorder("lightblue", "5px"),
  };

  function sdfewa() {
    //
  }

  // 1arrow
  const jsdfrew = {
    // onClick: sdfewa,
    className: "buttonHover",
    style: {
      width: "55px",
      ...jsqwaw,
    },
    // fontSize,
    // fontSize: "20px",
  };

  function fjiwerew(fderq) {
    const okwqes =
      //
      "";
    // sijqwe[fderq];

    onArrowClick(okwqes);
  }

  // 1full
  const nameList = {
    displayNumber: 5,
    //
  };

  // 1icons
  const iconList = {
    //
    displayNumber: 4,
  };

  // 1dict
  const dictvar =
    //
    conjDict;

  const baseList = [
    //
    // "PERFECT_PAST",
    "PROGRESSIVE_PAST",
    "Past",
    "Present",
    "Future",
    "Imperative",
  ];
  const list = baseList;

  const arrowStuff = {
    arrowConfig: jsdfrew,
  };

  const okfdsd = {
    dictvar,
    list,
    // ...getListDictArgs(dictvar),
    ...nameList,
    // ...iconList,
    onArrowClick: fjiwerew,
    horiz: true,
    bearName: "rqwer",
    chosenConfig: {
      style: {
        color: "white",
        background: "darkblue",
      },
    },
    itemConfig: {
      className: "buttonHover",
      style: {
        //
        ...jsqwaw,
        fontSize,
        padding: "0 20px",
      },
    },
    renderItem: asdwwe,
    ...bbbc,
  };

  const res =
    //
    BearList(okfdsd);
  // BearListChunk(okfdsd);

  return res;
}
