const lists = [
  //
  "colors",
  "swear words",
  "days",
  "months",
];

export const wordsMeta = {
  lists,
  noCommon: true,
};
