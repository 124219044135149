import { bearlog, BearURLParam } from "@sillyscribe95/bear-ui-web";
import { pickBy } from "lodash";
import { useContext, useEffect } from "react";
import { BaseTitle } from "../../components/BaseTitle";
import { MainContext } from "../../context/MainContext";
import { getTypesFront } from "../../functions/mainFuncs";
import { PageContainer } from "../PageContainer";
import { getLangBase } from "../translate/getLangFuncs";
import { PracticeContainerData } from "./practice-container-data";
import { PracticeContext } from "./PracticeContext";

export function PracticePage(aaaaa) {
  const {
    //
    match: { url, params },
    ...args
  } = aaaaa;

  const nameBase = BearURLParam("user");
  const onabase = getTypesFront(params);

  bearlog.lug(`___ onabase ___`, onabase);

  const { toName, toLang, fromLang } = useContext(MainContext);

  const {
    settingsTrue,
    setsettingsTrue,
    sessionCount,
    practiceLevel,
    setpracticeLevel,
  } = useContext(PracticeContext);

  // 1title
  const metaTit =
    //
    BaseTitle({
      toName,
      beforeText: "Practice",
      ...params,
    });

  bearlog.lug(`___ params ___`, params);

  useEffect(() => {
    // setpracticeLevel();
  }, []);

  const asdfew = {
    toLang,
    fromLang,
    ...onabase,
    ...pickBy(params),
    metaTit,
  };

  // 1right
  const sdsafeqwas =
    //
    "";

  // 1header
  const headerConfig =
    //
    "";
  // { right: "" };

  const ijewr = {
    headerConfig,
    footerConfig: "",
  };

  let contin =
    //
    PracticeContainerData(asdfew);
  // PracticeContainer(asdfew);

  const children =
    //
    contin;
  // okfdsd

  // const

  const background =
    //
    "";
  // `linear-gradient(149deg, rgba(45,195,34,1) 0%, rgba(255,255,255,1) 100%)`;

  const righto = settingsTrue
    ? {
        closeRight: true,
        topButtonConfig: { onClose: () => setsettingsTrue(false) },
      }
    : {
        topRightButtons: true,
      };

  const style = {
    background,
  };

  return PageContainer({
    style,
    url,
    align: "center",
    ...ijewr,
    ...params,
    metaTit,
    ...righto,
    //
    children,
  });
}
