import { bearlog } from "@sillyscribe95/bear-ui-web";
import { genderRepsDict } from "../const/genderConst";
import { addGender, getGenderBase } from "./genderFuncsFront";

export function prepVerbFunc(main, bbb) {
  const { fromLang, toLang, currConj } = bbb;

  const { transTitle, origTitle, verb } = main;

  const data = main[currConj];
  const basedat = data ? xafsdfa(main, bbb) : "";

  const res = { transTitle, origTitle, verb, ...basedat };

  return res;
}

export function xafsdfa(main, { fromLang, toLang, currConj, showGender }) {
  const genList = Object.values(genderRepsDict[fromLang]);

  const data = main[currConj];

  const valList =
    //
    Object.entries(data);
  // Object.values(data);

  bearlog.lug(`___ prepVerbFunc aaa ___`, { main, currConj, data, valList });

  const list = valList.map(doSbE);
  function doSbE([key, value], index) {
    const origText =
      //
      // key;
      genList[index];

    const pronounBase = key;

    const baseDone =
      //
      // item
      value;
    // showGender ? addGender(key, value, toLang) : value

    const transText =
      //
      baseDone;
    // getGenderBase(baseDone, toLang);

    bearlog.lug(`___ base Fns ___`, {
      key,
      value,
      origText,
      baseDone,
      transText,
    });

    return { pronounBase, origText, transText };
  }

  const baseTile = { list };

  bearlog.lug(`___ prepVerbFunc bb ___`, baseTile);

  return baseTile;
}
