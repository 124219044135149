import { BearDiv } from "@sillyscribe95/bear-ui-web";
import React, { useContext } from "react";
import { verbWeek } from "../const/mainConst";
import { PageContainer } from "../containers/PageContainer";
import { VerbPopularButtons } from "../containers/verbSearch/VerbPopularButtons";
import { MainContext } from "../context/MainContext";
import { verbLink } from "../functions/linkFuncs";
import { SearchPortal } from "../containers/verbSearch/SearchPortal";
import { BearPrivateRoute } from "../components/bear/BearPrivateRoute";
import { HomePage } from "../containers/homepage/HomePage";
import { DashboardPage } from "../containers/Dashboard/DashboardPage";

export function GroundPage(aaaaa) {
  // 1const
  let {
    match: { url, params },
  } = aaaaa;

  const { userLoad, userObj } = useContext(MainContext);

  const noComponent =
    //
    // HomePage;
    DashboardPage;

  const homeConfig = {
    path: "/",
    noComponent,
    yesRedirect: "/dashboard",
    userLoad,
    userObj,
  };

  let asdeae = BearPrivateRoute(homeConfig);

  return asdeae;
}
