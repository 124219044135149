export function checkRoman({ toLang, fromLang }) {
  const naerw = [
    //
    "el",
    "zh-CN",
    "zh",
    "ja",
    "ru",
    "hi",
    // "ar",
  ];
  const tureos = naerw.includes(toLang) || naerw.includes(fromLang);

  return tureos;
}
