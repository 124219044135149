import { BearBorder, BearList } from "@sillyscribe95/bear-ui-web";
import { LanguageName } from "./LanguageName";

export function CountryList(asndw) {
  let { itemStyle, itemConfig = {} } = asndw;

  itemConfig = {
    className: "buttonHover",
    ...itemConfig,
    align: "center",
    style: {
      padding: "10px",
      fontSize: "24px",
      ...BearBorder("lightgrey", "5px"),
      ...itemConfig.style,
    },
  };

  function renderItem(code) {
    return LanguageName(code, { showEmoji: true });
  }

  const okfdsd = BearList({
    renderItem,
    itemConfig,
    ...asndw,
  });

  return okfdsd;
}
