import { bearlog } from "@sillyscribe95/bear-ui-web";
import { IoMdColorPalette } from "react-icons/io";
import {
  MdFamilyRestroom,
  MdFormatListNumberedRtl,
  MdOutlineEmojiEmotions,
} from "react-icons/md";
import { BearMergeArrays } from "./bear/BearArrayFuncs";
import { getMetaFront } from "./getMetaFront";

export function getLevelIcon(level) {
  return {
    family: MdFamilyRestroom(),
    emotions: MdOutlineEmojiEmotions(),
    colors: IoMdColorPalette(),
    numbers: MdFormatListNumberedRtl(),
    // numbers: MdFormatListNumberedRtl(),
  }[level];
}

export function getAllLevels() {
  const dicto = {};
  let allList = ["nouns", "adjectives", "verbs", "numbers", "words"].map(
    onBase
  );
  allList = BearMergeArrays(allList);

  function onBase(transid) {
    let { lists } = getMetaFront(transid);

    switch (transid) {
      case "numbers":
        lists = lists.map((number) => `Numbers: ${number}`);
        break;
      default:
    }

    lists = lists.map((level) => ({ level, transid }));

    return [...lists];
  }

  allList.map(basFnc);
  function basFnc({ level, transid }) {
    dicto[level] = transid;
  }

  bearlog.lug(`___ allList ___`, allList);
  return allList;
}
