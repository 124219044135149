import { BearPage } from "@sillyscribe95/bear-ui-web";
import { useContext } from "react";
import { CountryShow } from "../components/CountryShow";
import { MainContext } from "../context/MainContext";
import { PageTopButtons } from "./PageTopButtons";

export function PageMobile({
  topRightButtons,
  headerConfig,
  children,
  topButtonConfig,
  ...bbbb
}) {
  const { toLang } = useContext(MainContext);

  const jsirww =
    //
    "";
  // true;

  //

  const dfnaae = {
    position: "fixed",
    background: "white",
    // borderBottom: "0.5px solid black",
    width: "100vw",
    padding: "10px 10px 0",
    left: 0,
    top: 0,
    fontSize: "28px",
    // height: "100px",
    ...headerConfig.style,
  };

  // 1buttons
  const saease = topButtonConfig;

  const right = topRightButtons
    ? PageTopButtons(saease)
    : CountryShow({
        lang: toLang,
        type: "to",
      });

  // const relaso = topRightButtons && {
  //   right:
  // };

  headerConfig = {
    right,
    ...headerConfig,
    style: dfnaae,
  };

  const jiwqw = {
    ...bbbb,
    children,
    // onCancel: () => setsearchTrue(),
    headerConfig,
  };

  // const ijasdjwe = SearchPage(jiwqw);

  return BearPage(jiwqw);
}
