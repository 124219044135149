import { BearDiv, BearTitle } from "@sillyscribe95/bear-ui-web";
import React, {
  //
  useState,
} from "react";
import { useHistory } from "react-router";
import { VerbSearch } from "../../components/VerbSearch";
import { verbLink } from "../../functions/linkFuncs";

export function SearchPortal(abbbb) {
  let history = useHistory();
  const { fromLang = "en", toLang = "es" } =
    //
    abbbb;
  // useContext(MainContext);

  function histGo({ verb }) {
    let askdwe =
      //
      verbLink({ fromLang, toLang, verb });

    const sdsfer = askdwe;

    // history.push(sdsfer);
  }

  const [adsfkm, setadsfkm] = useState();

  function asdfrewfds(dersdf) {
    histGo(dersdf);
    // setadsfkm(dersdf);
  }

  // 1title
  const sdfrewe = BearTitle({
    title: BearDiv({ obj: "🌐 Verbalize", fontSize: "38px" }),
    subtitle: BearDiv({
      obj: "Conjugate any verb, in any language.",
      fontSize: "22px",
    }),
    // fontSize:
    style: { marginBottom: "50px" },
  });

  const dsaew = {
    onChoose: asdfrewfds,
    // baseLanguage,
    // mainCountry: aswe,
    // langto,
    // langfrom,
    ...abbbb,
  };

  // setadsfkm
  // 1chunks

  //
  const zxcvasd = {
    //
    fromLang,
    toLang,
    noButton: true,
    style: {
      // marginBottom: "40px",
      // background: "red",
    },
  };

  // 1bar
  const asdfjewr =
    //
    VerbSearch(zxcvasd);
  // SearchBar(zxcvasd);

  const airwejre = BearDiv({
    obj: asdfjewr,
    style: {
      margin: "0 10vw",
      // background: "red",
    },
  });

  let oaksdwe = (
    <>
      {/*  */}
      {/* {tesbuttos} */}
      {sdfrewe}
      {/* {FormMain(dsaew)} */}
      {airwejre}
      {/*  */}
      {/* {adsfkm && VerbChunks(adsfkm)} */}
    </>
  );

  const nqwe =
    //
    // "";
    oaksdwe;

  let isddd =
    // //
    // "";
    BearDiv({
      obj: nqwe,
      align: "center",
      style: { padding: "50px" },
    });

  return isddd;
}
