import { BearDiv, BearFloat, BearList } from "@sillyscribe95/bear-ui-web";
import { useState } from "react"; //

export function BearListChunk({
  arrowOuterConfig,
  arrowCopyItemConfig,
  arrowConfig = {},
  onArrowClick,
  arrowLeftConfig = {},
  style,
  ...aaa
}) {
  const { list, itemConfig, displayNumber } = aaa;
  const [nmGo, setnmGo] = useState(0);
  const okadqw = list.length;
  // const

  const until = displayNumber + nmGo;
  const pastGone = until >= okadqw;
  //   1list
  const listo = list.slice(nmGo, until);

  function movBack(nummo) {
    const sdewr = nmGo + nummo;

    if (onArrowClick) {
      onArrowClick(sdewr);
    }

    setnmGo(sdewr);
  }

  function ButtGet({ nummo, nono, ...vvvv }) {
    const asoewe =
      arrowCopyItemConfig &&
      //
      itemConfig;
    // asdkw(itemConfig) : arrowConfig,

    const irqew = nono
      ? ""
      : BearDiv({
          onClick: () => movBack(nummo),
          ...asoewe,
          ...arrowConfig,
          ...vvvv,
        });

    return BearDiv({
      obj: irqew,
      ...arrowOuterConfig,
      //   style: {
      //     width: arrowWidth,
      //   },
    });
  }

  // 1left
  const aseok = nmGo == 0;
  const kkasew = ButtGet({
    nummo: -displayNumber,
    obj: "<",
    nono: aseok,
  });

  // 1right

  const sdfjirw = ButtGet({
    nummo: displayNumber,
    obj: ">",
    nono: pastGone,
  });

  const nijdeqw = BearList({ ...aaa, list: listo });
  const okfdsd = BearFloat({
    left: kkasew,
    center: nijdeqw,
    right: sdfjirw,
    style,
  });

  const seqwaw3 =
    //
    okfdsd;
  // nijdeqw;

  return seqwaw3;
}
