import SpeechRecognition, {
  useSpeechRecognition,
} from "react-speech-recognition";

import React, { useEffect, useState } from "react"; //
import {
  //
  bearlog,
  BearDiv,
} from "@sillyscribe95/bear-ui-web";

export function BearSpeechRecognition({ onStop, renderItem }) {
  const all = useSpeechRecognition();
  const {
    transcript,
    interimTranscript,
    finalTranscript,
    listening,
    resetTranscript,
    browserSupportsSpeechRecognition,
  } = all;

  bearlog.lug(`___ SpeechRecognition ___`, SpeechRecognition);

  const { startListening, stopListening } = SpeechRecognition;

  function doneTrans() {
    stopListening();

    if (onStop) {
      onStop(finalTranscript);
    }
  }

  useEffect(() => {
    if (finalTranscript) {
      doneTrans();
    }
  }, [finalTranscript]);

  const items = {
    start: startListening,
    stop: doneTrans,
    ...all,
  };

  const okfdsd = renderItem(items);

  return okfdsd;
}
