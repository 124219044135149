import {
  BearDiv,
  BearFloat,
  bearlog,
  BearLower,
  BearSocialLinks,
  BearTitle,
  BearURLParam,
} from "@sillyscribe95/bear-ui-web";
import React, { useContext } from "react"; //
import { FaDiscord, FaFacebook, FaGithub, FaSlack } from "react-icons/fa";
import { IoArrowBackCircleOutline } from "react-icons/io5";
import { useHistory } from "react-router-dom";
import { FixDash } from "../components/GlobalComps";
import { mobView } from "../const/mainConst";
import { MainContext } from "../context/MainContext";
import { DashboardButton } from "./DashboardButtons";
import { PageContainer } from "./PageContainer";
import { TitleComp } from "./TitleComp";
import { StringLango } from "./translate/getLangFuncs";

export function PageTranslate({
  hasData,
  playAll,
  gitLink,
  titRight,
  transid,
  titConfig,
  infoBase,
  children,
  ...vvvvv
}) {
  // 1style

  const showDat =
    //
    //
    // "";
    // true
    hasData;

  const { infoView, setinfoView, toName, fromName } = useContext(MainContext);

  const baseargs = {
    transid,
    playAll,
    // ...vvvvv,
  };

  const { origText, transText } = !transid ? "" : StringLango(transid);

  // 1none
  function jgrweqw() {
    // toName, fromName
    const noMess =
      //
      `Looks like we don't have any ${toName} ${BearLower(transid)} yet.`;

    const ijserewr = (
      <>
        Verbalise is open-source and growing all the time. We're looking for
        people to add their knowledge and help learn languages faster.
      </>
    );

    // 1gitlink
    const gitto =
      //
      `https://github.com/happy-devs/verbalize/tree/main/src/main/const/${transid}`;

    const linkBase = BearDiv({
      outsideLink: gitto,
      obj: "Why don't you contribute to our Github?",
    });

    const ijwrwr = BearDiv({
      align: "center",
      fontSize: "24px",
      obj: (
        <>
          {BearDiv({ obj: noMess, style: { margin: "20px" } })}
          {ijserewr}
          {BearDiv({ obj: linkBase, style: { margin: "20px" } })}
        </>
      ),
    });

    return ijwrwr;
  }

  // 1none 1right
  function noDataRight() {
    const ajsdfrew = [
      //
      "github",
      // "facebook",
      "slack",
      "discord",
    ];

    let ijwae = BearSocialLinks({
      bearName: "ASea",
      list: ajsdfrew,
      itemConfig: {
        style: {
          margin: "20px 0",
          // textAlign: "center",
        },
        fontSize: "44px",
        className: "buttonHover",
      },
      dictvar: {
        github: {
          icon: <FaGithub />,
          // link: "http://mubs.com/",
        },
        discord: {
          // text: "asdqwe",
          icon: <FaDiscord />,
          // link: "http://mubs.com/",
        },
        facebook: {
          icon: <FaFacebook />,
          // link: "http://mubs.com/",
        },
        slack: {
          icon: <FaSlack />,
          // link: "http://mubs.com/",
        },
      },
    });

    ijwae = BearTitle({
      title: "Join Our Communities",
      subtitle: ijwae,
    });

    return ijwae;
  }

  const titBase = {
    origText,
    transText,
    showButtons: true,
    transid,
    ...titConfig,
  };

  bearlog.lug(`___ titBase ___`, titBase);

  const ansdfaer = TitleComp(titBase);

  const adfewr = {
    padding: mobView ? "0" : "0 2%",
  };

  const sdfsad = {
    borderBottom: "black 1px solid",
  };

  let titBaseo =
    //
    ansdfaer;

  let history = useHistory();
  const backTrue = BearURLParam("back");

  bearlog.lug(`___ backTrue ___`, backTrue);

  const baseOb =
    //
    // "BACK"
    IoArrowBackCircleOutline();

  const Butto =
    backTrue &&
    BearDiv({
      obj: baseOb,
      onClick: () => history.goBack(),
      className: "buttonHover",
      fontSize: "1.3em",
    });

  titBaseo = BearFloat({
    left: Butto,
    center: titBaseo,
  });

  const ssewqe = true && {
    ...sdfsad,
    marginBottom: "30px",
  };

  // 1left
  const dfewsae =
    //
    BearDiv({
      style: {
        // ...BearBorder("red"),
        padding: "0 5%",
        ...ssewqe,
      },
      obj: titBaseo,
    });

  const nffrew =
    //
    // !titRight
    true;

  const ijadqwe = BearDiv({
    bearName: "asokqwe",
    obj: (
      <>
        {dfewsae}
        {titRight}
      </>
    ),
  });

  // 1title
  function renTit(sadfas) {
    return (
      <>
        {ijadqwe}
        {showDat ? sadfas : jgrweqw()}
      </>
    );
  }

  // 1left
  const childPush =
    //
    // children
    infoView ? infoBase : children;

  function asdqwe() {
    // 1fix
    let xzcsre =
      //
      DashboardButton(baseargs);
    // showDat ? DashboardButton(baseargs) : noDataRight();

    const sdjase =
      //
      // renTit(childPush);
      showDat ? renTit(childPush) : jgrweqw();

    return FixDash({
      left: sdjase,
      right: xzcsre,
      // noRight: !showDat,
      mainWidth: "50vw",
      ...baseargs,
      // fixConfig,
    });
  }

  const baseo = asdqwe();
  children =
    //
    baseo;
  // showDat ? asdqwe() : jgrweqw();

  const okfdsd = BearDiv({
    children,
    // obj: renTit(children),
    style: adfewr,
  });

  return PageContainer({
    ...vvvvv,
    children: okfdsd,
    metaConfig: {
      title: origText,
    },
    okfdsd,
  });
}
