import {
  BearInputNumber,
  BearList,
  BearTextMedia,
} from "@sillyscribe95/bear-ui-web";
import { isInteger } from "lodash";
import {
  //
  useState,
} from "react";
import { BsArrowDown, BsArrowUp, BsPlusSquareDotted } from "react-icons/bs";
import { PageTranslate } from "../PageTranslate";
import { NumberFetch } from "./NumberFetch";
import { getNumbAll, numToWords } from "./numberFuncs";

export function NumberPage(aaaaa) {
  // 1const
  const {
    //
    match: { params },
    ...args
  } = aaaaa;

  const [calcStart, setcalcStart] = useState(0);
  const [calcEnd, setcalcEnd] = useState(11);
  const [incrBase, setincrBase] = useState(1);

  // 1list
  const sdfjre =
    //
    getNumbAll(calcStart, calcEnd, incrBase);
  const mapNumSound = sdfjre.map((fsdafd) => numToWords(fsdafd, params.toLang));

  // 1console

  //

  const titConfig = {
    actionConfig: {
      // playText: mapNumSound,
      practiceLink: "numbers",
    },
  };

  const okfdsd = NumberFetch({
    // from: calcStart,
    // to: calcEnd,
    // icr: incrBase,
    lsto: sdfjre,
  });

  // 1right
  function Rightseo() {
    function InpGo({ changeNum, base, label, icon, ...asdfwe }) {
      //
      function okdfwe(xczv) {
        const fasidjw =
          //
          // true;
          isInteger(xczv);

        if (fasidjw) {
          changeNum(xczv);
        }
      }

      const sdfwerw = BearTextMedia({
        textvar: label,
        iconvar: icon,
      });

      const sdfwe = {
        //
        style: { fontSize: "20px" },
      };

      return BearInputNumber({
        // sameLine: true,
        value: base,
        label: sdfwerw,
        labelConfig: sdfwe,
        onChangeValue: okdfwe,
        ...asdfwe,
        // style: { width: "50px" },
      });
    }

    // 1from
    const jfwsa = {
      label: "From",
      changeNum: setcalcStart,
      base: calcStart,
      icon: BsArrowDown(),
      max: calcEnd,
    };

    // 1to
    const sfdgeras = {
      label: "To",
      min: calcStart - 1,
      changeNum: setcalcEnd,
      base: calcEnd,
      icon: BsArrowUp(),
    };

    // 1inc
    const xcvbdfd = {
      label: "In",
      changeNum: setincrBase,
      base: incrBase,
      max: calcEnd,
      icon: BsPlusSquareDotted(),
    };

    // 1dictvar
    const iesawqwe = {
      from: jfwsa,
      to: sfdgeras,
      inc: xcvbdfd,
    };

    const sdiasjwe =
      //
      BearList({
        renderItem: InpGo,
        bearName: "asdqw",
        //   BearList({
        dictvar: iesawqwe,
        list: ["from", "to", "inc"],
        horiz: true,
      });

    return sdiasjwe;
  }

  const ijdqewa = {
    playAll: mapNumSound,
    hasData: mapNumSound,
    titConfig,
    children: okfdsd,
    transid: "Numbers",
    titRight: Rightseo(),
    ...params,
  };

  return PageTranslate(ijdqewa);
}
