import React from "react"; //
import {
  //
  bearlog,
  BearDiv,
  BearTitle,
  BearTextMedia,
  BearBackBorder,
} from "@sillyscribe95/bear-ui-web";
import { mobView } from "../const/mainConst";
import { BsGithub } from "react-icons/bs";

export function EmptyResult({
  //
  searchType,
  ...zxca
}) {
  const sieasd = BearTextMedia({
    iconvar: BsGithub(),
    textvar: "Join us on Github",
    outsideLink: "adsfwer",
    // center: true,
    align: "center",
    style: {
      //   width: "100px",
      margin: "20px 20%",
      //   padding: "0 200px",
      ...BearBackBorder("black", "5px"),
      color: "white",
    },
  });

  const nsdf = (
    <>
      Verbalize is open source and growing all the time.
      <br />
      {sieasd}
      {/* Be Contribute to our Github. */}
    </>
  );

  const sdjfnew = BearTitle({
    align: "center",
    style: { margin: "10%" },
    fontSize: mobView ? "24px" : "20px",
    subtitle: nsdf,
    ...zxca,
  });

  const okfdsd = (
    <>
      {/*  */}
      {sdjfnew}
    </>
  );

  return okfdsd;
}
