import {
  BearDiv,
  BearFloat,
  BearList,
  bearlog,
  BearTextMedia,
  BearTitle,
  BearUpper,
} from "@sillyscribe95/bear-ui-web";
import { get } from "lodash";
import { useContext } from "react";
import { BsShuffle } from "react-icons/bs";
import { GrDisabledOutline } from "react-icons/gr";
import { BearColors } from "../../components/bear/BearGlobals";
import { DashMobButtons } from "../../components/dash-mob-buttons";
import { BaseTitle } from "../../components/GlobalComps";
import { PlusMessage } from "../../components/PlusMessage";
import { PracticeLevelButtons } from "../../components/practice-level-buttons";
import { MainContext } from "../../context/MainContext";
import { getMetaFront } from "../../functions/getMetaFront";
import { getAllLevels } from "../../functions/levelFuncs";
import { linkPractice } from "../../functions/linkFuncs";
import { butActive, getTypesFront } from "../../functions/mainFuncs";
import { PageContainer } from "../PageContainer";
import { getLangBase } from "../translate/getLangFuncs";

export function DashboardPracticeLevels({
  plusCheck = true,
  titleConfig,
  ...params
}) {
  let { list } = params;
  let { transid, level } = getTypesFront(params);

  const { userObj, userLoad, fromLang, toLang, fromName, toName } =
    useContext(MainContext);

  const numbTrue = ["Numbers"].includes(transid);

  bearlog.lug(`___ numbTrue ___`, numbTrue);

  // 1plus
  const plusShow =
    //
    (plusCheck && userObj?.hasPlus) || numbTrue;

  // 1common
  const mostCommo = "100 Most Common";

  list =
    //
    // getlist();
    list ? list : getlist();

  bearlog.lug("___ list ___", list);

  function getlist() {
    const metaDict = getMetaFront(transid);
    const { lists, noCommon } = metaDict;
    let listBase = get(metaDict, "lists");
    // listBase = listBase ? listBase : ""

    bearlog.lug("___ listBase ___", { metaDict, listBase });

    const showComm =
      //
      //   "";
      !noCommon;

    const baseList =
      //
      // listBase;
      !listBase ? "" : [showComm && mostCommo, ...listBase];

    bearlog.lug(`___ getListll bbbb ___`, baseList);

    return baseList;
  }

  function getShow(level) {
    const base = {
      ...params,
      level,
      fromLang,
      toLang,
    };

    const commit = ["100 Most Common"].includes(level);
    const listbaSE =
      //
      plusShow ? true : commit;
    const show100 = !plusShow && commit;

    const link = linkPractice(base);
    return listbaSE
      ? {
          link,
          // fontSize: "1.4em",
          style: {
            padding: "10px",
            ...(show100 && BearColors("white", "darkblue")),
          },
        }
      : {
          // className:
          link: "",
          // iconvar: GrDisabledOutline(),
        };
  }

  // 1full
  const linkPar = { fromLang, toLang, ...params, level: "full" };
  const link = linkPractice(linkPar);

  bearlog.lug("___ dsbbhPage  ___", { linkPar, link });

  // 1title

  const right = BearDiv({
    link,
    ...butActive(true),
    obj: BsShuffle(),
  });

  titleConfig = {
    right,
    titleConfig: { bold: true },
    ...titleConfig,
  };

  // 1list
  function BaseGo() {
    const args =
      //
      { list };

    // allLevels();

    let res = PracticeLevelButtons({
      ...args,
      titleConfig,
      renderConfig: getShow,
      fontSize: "28px",
      transid,
    });

    return plusShow
      ? res
      : BearDiv({
          obj: res,
          style: {
            boxShadow: "0 4px 2px -2px gray",
            height: "50vh",
            marginBottom: "5vh",
            overflow: "hidden",
          },
        });
  }

  // 1plus
  function PlusAddo() {
    const title =
      //
      getLangBase("dashPlusUpgrade");

    let res = PlusMessage({
      title,
      style: { padding: "0 10vw" },
      // className: "shadowMain",
      fontSize: "24px",
      spaceBetween: "10px",
      // plusConfig: { style: { width: "100px" } },
      subtitleConfig: {
        style: {
          //
          // textAlign: "center",
          // width: "200px",
          padding: "0 20px",
          fontSize: "1.2em",
        },
      },
    });

    // res = BearFloat({
    //   bottom: res,
    //   // style: { position: "absolute", bottom: 0 },
    // });

    return res;
  }

  let sidrBase = list && BaseGo();

  // 1plus
  const pluson =
    //
    // "";
    !plusShow && PlusAddo();

  function renderItem(obj) {
    const link = linkPractice;
    return BearDiv({ obj, link });
  }

  const children = BearDiv({
    // style: { position: "relative" },
    obj: (
      <>
        {/* {baseTier} */}
        {sidrBase}
        {pluson}
      </>
    ),
  });

  return children;
}
