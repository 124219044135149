import { BearTextMedia } from "@sillyscribe95/bear-ui-web";
import { useContext } from "react"; //
import { getLangBase } from "../containers/translate/getLangFuncs";
import { MainContext } from "../context/MainContext";
import { getSearchConst } from "./searchConst";

export function SearchOptions(dsfewr) {
  const { userObj } = useContext(MainContext);

  let sdijfra =
    //
    // Object.keys(searchBase)
    Object.entries(getSearchConst(userObj));

  sdijfra = sdijfra.map(cxzD);

  function cxzD([key, value]) {
    let { textvar } = value;

    textvar =
      //
      // textvar
      getLangBase(textvar);

    const asdfew = {
      ...value,
      ...dsfewr,
      textvar,
      //
      // textvar: BearUpper(key),
    };

    const vallos =
      //
      // key;
      textvar;

    return { value: vallos, label: BearTextMedia(asdfew) };
  }

  return sdijfra;
}
