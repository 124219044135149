import {
  BearDiv,
  BearHeight,
  bearlog,
  BearTextMedia,
  BearTitle,
} from "@sillyscribe95/bear-ui-web";
import React, { useContext } from "react"; //
import { useHistory, useLocation } from "react-router";
import { BearColors } from "../../../components/bear/BearGlobals";
import { BearGoogleTranslateLanguageCodesSelect } from "../../../components/bear/BearGoogleTranslateLanguageCodesSelect";
import { CountryList } from "../../../components/CountryList";
import { LanguageName } from "../../../components/LanguageName";
import { mobView } from "../../../const/mainConst";
import { MainContext } from "../../../context/MainContext";
import { getLangChoose } from "../../../functions/languageFuncs";
import { setStorage } from "../../../functions/mainFuncs";
import { changeUseLang } from "../../../functions/userFuncs";
import { getLangBase } from "../../translate/getLangFuncs";

export function ChooseLangContainer(params) {
  const { userObj, fromName, toLang, fromLang, setfromLang, settoLang } =
    useContext(MainContext);

  let history = useHistory();
  const location = useLocation();
  const { url } = params;

  // 1choose 1onclick
  function chosoeso(Sdfgr) {
    erwzas(Sdfgr, "toLang", settoLang);
  }
  function dwqecvw(xvrew) {
    erwzas(xvrew, "fromLang", setfromLang);
  }

  function erwzas(...zasdsa) {
    changeBack(...zasdsa);
    // changeLink(...zasdsa)
  }

  // 1change 1choose
  function changeBack(basic, item, setFonco) {
    bearlog.lug(`___ changeBack ___`, { basic, item });

    if (userObj) {
      changeUseLang(userObj);
    } else {
      setStorage(item, basic, setFonco);
    }
    setFonco(basic);

    history.push("/");
    // history.goBack();
  }

  function changeLink(aadse) {
    const origio =
      //
      // "/l/sleep/en/el";
      url;

    let isdsas =
      //
      origio.replace(/\/(\w+)?$/, ``);

    const endofin = `${isdsas}/${aadse}`;

    history.push(endofin);
  }

  // 1list

  const asderw = {
    value: { value: fromName, label: fromName },
  };

  // 1fontsize
  const fontSize =
    //
    "24px";

  let countryConfig = {
    className: "buttonHover expandHover shadowMain",
  };

  const ndfse = {
    menuIsOpen: true,
    // itemConfig: nksdfaad,
    // renderItem,
    bearName: "adfwe",
    click: bvege,
    // searchAttrs: ["code", "language"]
  };

  // 1search
  const slecto = BearGoogleTranslateLanguageCodesSelect({
    ...asderw,
    onChangeValue: dwqecvw,
    // onSelect: chosoeso,
    menuHeight: "48vh",
    // ...ndfse,
    width: "200px",
    // menuIsOpen: true,
    fontSize,
    customComponents: {
      ClearIndicator: () => null,
    },
  });

  const kdrewr = getLangChoose({ toLang, fromLang });

  // 1list
  const list =
    //
    // "";
    kdrewr;

  const displayNumber =
    //
    mobView ? 1 : 3;

  const sjadfer = {
    bearName: "sdfw",
    list,
    horiz: true,
    displayNumber,
    click: baseFin,
    itemConfig: {
      ...countryConfig,
      style: {
        //
        margin: "10px",
        padding: "10px",
        width: mobView ? "90vw" : "300px",
        fontSize: "30px",
      },
    },
  };

  function baseFin(runBase) {
    bearlog.lug(`___ runBase ___`, runBase);

    const { itemName } = runBase;
    bvege(itemName);
  }

  // 1buttons
  const ijsdfe = CountryList({
    ...ndfse,
    ...sjadfer,
  });

  // 1click
  function bvege(code) {
    chosoeso(code);
  }

  // 1find
  const rewasw = BearDiv({
    align: "center",
    obj: getLangBase("joinLanguage"),
    outsideLink:
      "https://quarterly.insigniam.com/leadership/bob-lutz-examines-how-gm-was-crippled-by-process/",
  });

  const curro =
    //
    // slecto;
    fromName;
  // "English";

  const asjdfer = BearTextMedia({
    iconvar: getLangBase("chooseLangFromTit"),
    textvar: curro,
    padvar: "10px",
  });

  const asde = {
    ...countryConfig.style,
    ...BearColors("white", "darkblue"),
    // width: "200px",
  };

  const dnew = BearTextMedia({
    iconvar: getLangBase("wantLearn"),
    textvar: BearDiv({
      obj: LanguageName(toLang, { showEmoji: true }),
      onClick: () => bvege(toLang),
      ...countryConfig,
      style: asde,
    }),
  });

  const title = BearTitle({
    fontSize: "30px",
    bold: true,
    title: getLangBase("Languages"),
    spaceBetween: "20px",
  });

  const asdir = (
    <>
      {title}
      {asjdfer}
      {BearHeight(50)}
      {dnew}
      {BearHeight(20)}
      {ijsdfe}
      {/* {rewasw} */}
    </>
  );

  const ijerwe = BearDiv({
    obj: asdir,
    fontSize,
    align: "center",
    style: { height: "60vh" },
  });

  return ijerwe;
}
